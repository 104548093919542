import React, { useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import User from "models/user.model";
import { ModelError } from "../../../../models/model";
import yup from "../../../../core/yupPatern";
import { EStep } from "../const";

interface IProps {
   setStep: ({ step, dataStep }: { step: EStep; dataStep?: any }) => void;
}

const ForgotPasswordForm: React.FC<IProps> = ({ setStep }) => {
   const { t } = useTranslation();
   const [error, setError] = useState<string | undefined>();

   const schema = yup.object().shape({
      account: yup.string().required("Vui lòng nhập Email hoặc số điện thoại"),
   });

   const {
      register,
      handleSubmit,
      formState: { errors },
   } = useForm<{ account: string }>({
      resolver: yupResolver(schema),
   });
   const onSubmit = async (data: { account: string }) => {
      if (!data.account) {
         return;
      }
      try {
         const getMethod = await User.resetPasswordGetMethod(data.account);
         if (getMethod instanceof ModelError) {
            for (const [key, value] of Object.entries(
               getMethod?.errors || {}
            )) {
               if (value.length > 0) {
                  setError(value[0]?.message ?? "");
               }
            }
            return;
         }
         localStorage.setItem(
            "historyState",
            JSON.stringify({
               account: data.account,
               email: getMethod?.hiddenEmail,
               phone: getMethod?.hiddenPhone,
            })
         );

         setStep({
            step: EStep.forgotPasswordStep2,
            dataStep: {
               account: data.account,
               email: getMethod?.hiddenEmail,
               phone: getMethod?.hiddenPhone,
            },
         });
      } catch (error) {}
   };

   return (
      <div className="signup-phone">
         <h1 className="signup-phone-title">Quên mật khẩu</h1>
         <p className="signup-phone-des">
            Nhập địa chỉ email của bạn và chúng tôi sẽ gửi cho bạn mã xác nhận
            để đặt lại mật khẩu của bạn
         </p>
         <form className="form-register" onSubmit={handleSubmit(onSubmit)}>
            <div className="item-form">
               <label className="label">Email/Số điện thoại</label>
               <div className="input-wrap">
                  <input
                     type="text"
                     className={`input input-account ${
                        errors?.account ? "error" : ""
                     }`}
                     {...register("account", {
                        pattern:
                           /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/i,
                     })}
                     placeholder="Email/Số điện thoại"
                  />
               </div>
               {errors?.account ? (
                  <span className="error-text">
                     {errors?.account?.message}{" "}
                  </span>
               ) : error ? (
                  <span className="error-text">{t(error ?? "")} </span>
               ) : undefined}
            </div>

            <button
               className="btn btn-auth"
               type="submit"
               style={{ backgroundColor: "#FF6347" }}
            >
               Xác nhận
            </button>
         </form>

         {/* <div className="login-helper" style={{ marginTop: 30, textAlign: "center" }}>
        Đã có tài khoản? <span onClick={() => setStep({ value: EStep.singinForm })}>Đăng nhập ngay</span>
      </div> */}

         <div
            className="back-step"
            onClick={() => setStep({ step: EStep.signinStep2 })}
         >
            <img
               width="14px"
               height="7px"
               src="/assets/img/icon/arrow-left-icon.svg"
               alt=""
            />
         </div>
      </div>
   );
};

export default ForgotPasswordForm;
