import { useEffect } from "react";
import { G_SITE_KEY } from "models/__variables";
import store from "state";
import { captchaConfigSuccess } from "state/global/actions";

export function executeRecaptcha(): Promise<string> {
   return new Promise((resolve, reject) => {
      try {
         window.grecaptcha.ready(() => {
            window.grecaptcha
               .execute(G_SITE_KEY, { action: "submit" })
               .then((tokenCaptcha) => {
                  resolve(tokenCaptcha);
               })
               .catch((error) => {
                  reject(error);
               });
         });
      } catch (error) {
         reject(error);
      }
   });
}

export function useInitialRecaptcha() {
   /**
    * @Googlerecapcha
    * @param
    * @G_SITE_KEY string
    */
   useEffect(() => {
      const loadScriptByURL = (id, url, callback) => {
         const isScriptExist = document.getElementById(id);

         if (!isScriptExist) {
            const script = document.createElement("script");
            script.type = "text/javascript";
            script.src = url;
            script.id = id;
            script.defer = true;
            script.async = true;
            script.onload = (e) => {
               if (callback) callback(e);
            };
            document.body.appendChild(script);
         }

         if (isScriptExist && callback) callback();
      };

      // load the script by passing the URL
      loadScriptByURL(
         "recaptcha-key",
         `https://www.google.com/recaptcha/api.js?render=${G_SITE_KEY}`,
         (e) => {
            store.dispatch(captchaConfigSuccess(true));
         }
      );
   }, []);
}
