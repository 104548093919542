/* eslint-plugin-disable react */
/*eslint-disable */
/**
 * Variable(s)
 */
import { apiDomain, tokenKey } from "./__variables";

/**
 * Core(s)
 */
import ActualWork from "./actualWork.model";
import ActualWorkComment from "./actualWorkComment.model";
import ActualWorkReaction from "./actualWorkReaction.model";
import Article from "./article.model";
import ArticleComment from "./articleComment.model";
import ArticleReaction from "./articleReaction.model";
import Community from "./community.model";
import CommunityComment from "./communityComment.model";
import CommunityReaction from "./communityReaction.model";
import Model, {
   IModel,
   ModelError,
   Paginate,
   TModelError,
   TPaginate,
   TPayload,
} from "./model";
import Order from "./order.model";
import QnA from "./qna.model";
import QnAComment from "./qnaComment.model";
import QnAReaction from "./qnaReaction.model";
import Report from "./report.model";
import SocialFeed from "./socialFeed.model";
import SocialFeedComment from "./socialFeedComment.model";
import SocialFeedReaction from "./socialFeedReaction.model";
import { User } from "./user.model";
import PartnerReview from "./userPartnerReview.model";
import UserPoint from "./userPoint.model";
import ProductReview from "./userProductReview.model";
import UserSubscribe from "./userSubscribe.model";

export enum EUserNotificationStatus {
   enable = "enable",
   disable = "disable",
}

export enum EUserNotificationActions {
   unseen = "unseen",
   seen = "seen",
}

export enum EUserNotificationOpenTypes {
   orderCreated = "orderCreated",
   orderConfirmed = "orderConfirmed",
   orderRefunded = "orderRefunded",
   orderCancelled = "orderCancelled",
   orderSucceed = "orderSucceed",

   approveProductReview = "approveProductReview",
   rejectProductReview = "rejectProductReview",

   approvePartnerReview = "approvePartnerReview",
   rejectPartnerReview = "rejectPartnerReview",

   approveQnA = "approveQnA",
   rejectQnA = "rejectQnA",
   commentOnQnA = "commentOnQnA",

   replyOnQnAComment = "replyOnQnAComment",
   reactionOnQnA = "reactionOnQnA",
   reactionOnQnAComment = "reactionOnQnAComment",

   approveCommunityPost = "approveCommunityPost",
   rejectCommunityPost = "rejectCommunityPost",
   commentOnCommunityPost = "commentOnCommunityPost",
   reactionOnCommunityPost = "reactionOnCommunityPost",
   commentOnArticle = "commentOnArticle",
   reactionOnArticle = "reactionOnArticle",
   commentOnActualWork = "commentOnActualWork",
   reactionOnActualWork = "reactionOnActualWork",

   replyOnCommunityPostComment = "replyOnCommunityPostComment",
   reactionOnCommunityPostComment = "reactionOnCommunityPostComment",

   replyOnArticleComment = "replyOnArticleComment",
   reactionOnArticleComment = "reactionOnArticleComment",

   replyOnActualWorkComment = "replyOnActualWorkComment",
   reactionOnActualWorkComment = "reactionOnActualWorkComment",

   pointOfRegistration = "pointOfRegistration",
   pointOfReviewProduct = "pointOfReviewProduct",
   pointOfReviewPartnerViaSpaceT = "pointOfReviewPartnerViaSpaceT",
   pointOfReviewPartner = "pointOfReviewPartner",
   pointOfQnAComment = "pointOfQnAComment",
   pointOfOrderCancelled = "pointOfOrderCancelled",
   pointOfOrderSucceed = "pointOfOrderSucceed",
   pointOfCommunityPost = "pointOfCommunityPost",

   // SocialFeed old
   adminEditSocialFeed = "adminEditSocialFeed",
   commentOnSocialFeed = "commentOnSocialFeed", // old
   replyOnSocialFeedComment = "replyOnSocialFeedComment", // old
   reactionOnSocialFeed = "reactionOnSocialFeed", // old
   reactionOnSocialFeedComment = "reactionOnSocialFeedComment", // old
   pointOfSocialFeed = "pointOfSocialFeed",

   // SocialFeed new
   newReactionOnSocialFeedByUser = "newReactionOnSocialFeedByUser",
   newReactionOnSocialFeedCommentByUser = "newReactionOnSocialFeedCommentByUser",
   newCommentOnSocialFeedByUser = "newCommentOnSocialFeedByUser",
   newReplyOnSocialFeedCommentByUser = "newReplyOnSocialFeedCommentByUser",
   newSocialFeedFromSubscribingUser = "newSocialFeedFromSubscribingUser",

   // UserSubscribe
   receiptSubscriptionFromOneUser = "receiptSubscriptionFromOneUser",

   //report
   approveReportSocialFeed = "approveReportSocialFeed",
   approveReportSocialFeedComment = "approveReportSocialFeedComment",
   approveReportQnA = "approveReportQnA",
   approveReportQnAComment = "approveReportQnAComment",

   // Badge
   userBadgeUpdated = "userBadgeUpdated",

   homepage = "homepageAds",
   shopHome = "shopHomeAds",
   shopCategory = "shopCategoryAds", // with id and slug
   flashSales = "flashSalesAds",
   bestSeller = "bestSellerAds",
   newArrival = "newArrivalAds",
   product = "productAds", // with id and slug
   brandHome = "brandHomeAds", // with id and slug
   feedHome = "feedHomeAds",
   blogHome = "blogHomeAds",
   libraryHome = "libraryHomeAds",
   designLibrary = "designLibraryAds",
   actualWork = "actualWorkAds",
   calculator = "calculatorAds",
   interiorFirm = "interiorFirmAds",
   feedPost = "feedPostAds",
}

export interface IUserNotifications extends Model {
   ["info"]: {
      ["openId"]: string;
      ["openType"]: EUserNotificationOpenTypes;
   };
   ["options"]: {
      ["status"]: EUserNotificationStatus;
      ["action"]: EUserNotificationActions;
   };
   ["userId"]: string;

   ["__user"]?: User | null;
   ["__userPoint"]?: UserPoint | null;
   ["__order"]?: Order | null;
   ["__qna"]?: QnA | null;
   ["__qnaComment"]?: QnAComment | null;
   ["__qnaReaction"]?: QnAReaction | null;
   ["__partnerReview"]?: PartnerReview | null;
   ["__productReview"]?: ProductReview | null;
   ["__communityPost"]?: Community | null;
   ["__communityPostComment"]?: CommunityComment | null;
   ["__communityReaction"]?: CommunityReaction | null;
   ["__socialFeed"]?: SocialFeed | null;
   ["__socialFeedComment"]?: SocialFeedComment | null;
   ["__socialFeedReaction"]?: SocialFeedReaction | null;
   ["__article"]?: Article | null;
   ["__articleComment"]?: ArticleComment | null;
   ["__articleReaction"]?: ArticleReaction | null;
   ["__actualWork"]?: ActualWork | null;
   ["__actualWorkComment"]?: ActualWorkComment | null;
   ["__actualWorkReaction"]?: ActualWorkReaction | null;
   ["__userSubscribe"]?: UserSubscribe | null;
   ["__report"]?: Report | null;
}

export interface TUserNotificationCases extends IModel {
   ["info"]: {
      ["title"]: string;
      ["message"]: string;
      ["openId"]: string;
      ["openType"]: EUserNotificationOpenTypes;
      ["openSlug"]: string;
   };
   ["notification"]: {
      body: string;
      title: string;
   };
   ["options"]: {
      ["status"]: EUserNotificationStatus;
      ["action"]: EUserNotificationActions;
   };
   ["userId"]: string;

   ["__user"]?: User;
   ["__item"]?: Order;
}

export default class UserNotifications
   extends Model<IUserNotifications>
   implements IUserNotifications
{
   public ["info"]!: {
      ["title"]: string;
      ["message"]: string;
      ["openId"]: string;
      ["openType"]: EUserNotificationOpenTypes;
      ["openSlug"]: string;
   };
   public ["options"]!: {
      ["status"]: EUserNotificationStatus;
      ["action"]: EUserNotificationActions;
   };
   public ["userId"]!: string;

   public ["__user"]?: User | null;
   public ["__userPoint"]?: UserPoint | null;
   public ["__order"]?: Order | null;
   public ["__qna"]?: QnA | null;
   public ["__partnerReview"]?: PartnerReview | null;
   public ["__qnaComment"]?: QnAComment | null;
   public ["__qnaReaction"]?: QnAReaction | null;
   public ["__productReview"]?: ProductReview | null;
   public ["__communityPost"]?: Community | null;
   public ["__communityPostComment"]?: CommunityComment | null;
   public ["__communityReaction"]?: CommunityReaction | null;
   public ["__socialFeed"]?: SocialFeed | null;
   public ["__socialFeedComment"]?: SocialFeedComment | null;
   public ["__socialFeedReaction"]?: SocialFeedReaction | null;
   public ["__article"]?: Article | null;
   public ["__articleComment"]?: ArticleComment | null;
   public ["__articleReaction"]?: ArticleReaction | null;
   public ["__actualWork"]?: ActualWork | null;
   public ["__actualWorkComment"]?: ActualWorkComment | null;
   public ["__actualWorkReaction"]?: ActualWorkReaction | null;
   public ["__userSubscribe"]?: UserSubscribe | null;
   public ["__report"]?: Report | null;

   constructor(data?: IUserNotifications) {
      super(data);

      Object.assign(this, data);
      this.__user && (this.__user = new User(this.__user));
      this.__order && (this.__order = new Order(this.__order));
      this.__productReview &&
         (this.__productReview = new ProductReview(this.__productReview));
      this.__partnerReview &&
         (this.__partnerReview = new PartnerReview(this.__partnerReview));
      this.__qna && (this.__qna = new QnA(this.__qna));
      this.__qnaComment &&
         (this.__qnaComment = new QnAComment(this.__qnaComment));
      this.__qnaReaction &&
         (this.__qnaReaction = new QnAReaction(this.__qnaReaction));
      this.__communityPost &&
         (this.__communityPost = new Community(this.__communityPost));
      this.__communityPostComment &&
         (this.__communityPostComment = new CommunityComment(
            this.__communityPostComment
         ));
      this.__communityReaction &&
         (this.__communityReaction = new CommunityReaction(
            this.__communityReaction
         ));
      this.__userPoint && (this.__userPoint = new UserPoint(this.__userPoint));
      this.__socialFeed &&
         (this.__socialFeed = new SocialFeed(this.__socialFeed));
      this.__socialFeedComment &&
         (this.__socialFeedComment = new SocialFeedComment(
            this.__socialFeedComment
         ));
      this.__articleReaction &&
         (this.__articleReaction = new ArticleReaction(this.__articleReaction));
      this.__article && (this.__article = new Article(this.__article));
      this.__articleComment &&
         (this.__articleComment = new ArticleComment(this.__articleComment));
      this.__actualWork &&
         (this.__actualWork = new ActualWork(this.__actualWork));
      this.__actualWorkComment &&
         (this.__actualWorkComment = new ActualWorkComment(
            this.__actualWorkComment
         ));
      this.__actualWorkReaction &&
         (this.__actualWorkReaction = new ActualWorkReaction(
            this.__actualWorkReaction
         ));
      this.__userSubscribe &&
         (this.__userSubscribe = new UserSubscribe(this.__userSubscribe));
      this.__socialFeedReaction &&
         (this.__socialFeedReaction = new SocialFeedReaction(
            this.__socialFeedReaction
         ));
      this.__report && (this.__report = new Report(this.__report));
   }
   public static async paginate({
      page,
      limit,
      sort,
      sortBy,
      offset,
   }: {
      page?: number;
      limit?: number;
      sort: "desc";
      sortBy: "createdAt";
      offset?: number;
   }) {
      try {
         const token = localStorage.getItem(tokenKey);
         if (!token) {
            return new ModelError({
               ["httpCode"]: 401,
               ["message"]: "Unauthorization",
               ["errors"]: {
                  ["process"]: [
                     {
                        ["code"]: "process.error.401",
                        ["message"]: "Đăng nhập để tiếp tục.",
                     },
                  ],
               },
            });
         }
         const response = await fetch(
            `${apiDomain}/www/user/notification/paginate`,
            {
               ["method"]: "GET",
               ["headers"]: {
                  ["X-Requested-With"]: "XMLHttpRequest",
                  ["Authorization"]: `Bearer ${token}`,
                  ["limit"]: !limit ? String(20) : String(limit),
                  ...(!page ? undefined : { page: String(page) }),
                  ...(!offset ? undefined : { offset: String(offset) }),
                  ["sort"]: sort,
                  ["sort-by"]: sortBy,
               },
            }
         );

         if (!response.ok) {
            return new ModelError((await response.json()) as TModelError);
         }

         const payload = (await response.json()) as TPayload<{
            ["paginator"]: TPaginate<IUserNotifications>;
         }>;
         return new Paginate(payload?.data?.paginator, UserNotifications);
      } catch (error) {
         return new ModelError({
            ["httpCode"]: 500,
            ["message"]: error.message as string,
            ["errors"]: {
               ["process"]: [
                  {
                     ["code"]: "process.error.5000",
                     ["message"]: "Process error on handling.",
                  },
               ],
            },
         });
      }
   }

   public static async getById(id: string) {
      try {
         const token = localStorage.getItem(tokenKey);
         if (!token) {
            return new ModelError({
               ["httpCode"]: 401,
               ["message"]: "Unauthorization",
               ["errors"]: {
                  ["process"]: [
                     {
                        ["code"]: "process.error.401",
                        ["message"]: "Đăng nhập để tiếp tục.",
                     },
                  ],
               },
            });
         }
         const response = await fetch(`${apiDomain}/www/user/notification`, {
            ["method"]: "GET",
            ["headers"]: {
               ["X-Requested-With"]: "XMLHttpRequest",
               ["Authorization"]: `Bearer ${token}`,
               ["id"]: encodeURI(id),
            },
         });

         if (!response.ok) {
            return new ModelError((await response.json()) as TModelError);
         }

         const payload = (await response.json()) as TPayload<{
            ["notification"]: IUserNotifications;
         }>;

         return new UserNotifications(payload.data.notification);
      } catch (error) {
         return new ModelError({
            ["httpCode"]: 500,
            ["message"]: error.message as string,
            ["errors"]: {
               ["process"]: [
                  {
                     ["code"]: "process.error.5000",
                     ["message"]: "Process error on handling.",
                  },
               ],
            },
         });
      }
   }

   public static async update({
      id,
      action,
   }: {
      id?: string;
      action: EUserNotificationActions;
   }) {
      try {
         const token = localStorage.getItem(tokenKey);
         if (!token) {
            return new ModelError({
               ["httpCode"]: 401,
               ["message"]: "Unauthorization",
               ["errors"]: {
                  ["process"]: [
                     {
                        ["code"]: "process.error.401",
                        ["message"]: "Đăng nhập để tiếp tục.",
                     },
                  ],
               },
            });
         }
         const response = await fetch(`${apiDomain}/www/user/notification`, {
            ["method"]: "PATCH",
            ["cache"]: "no-store",
            ["headers"]: {
               ["X-Requested-With"]: "XMLHttpRequest",
               ["Content-Type"]: "application/json",
               ["Authorization"]: `Bearer ${token}`,
               ...(!id
                  ? undefined
                  : {
                       ["id"]: encodeURI(id),
                    }),
            },
            ["body"]: JSON.stringify({
               ["options.action"]: action,
            }),
         });

         if (!response.ok) {
            return new ModelError((await response.json()) as TModelError);
         }

         // const payload = await response.json() as TPayload<{
         //     ["updatedUserNotification"]: IUserNotifications;
         // }>;

         return;
         // new UserNotifications(payload.data.updatedUserNotification);
      } catch (error) {
         return new ModelError({
            ["httpCode"]: 500,
            ["message"]: error.message as string,
            ["errors"]: {
               ["process"]: [
                  {
                     ["code"]: "process.error.5000",
                     ["message"]: "Process error on handling.",
                  },
               ],
            },
         });
      }
   }
}
