/**
 * Model(s)
 */
import { CommunityMedia } from "./communityMedia.model";
import CommunityReaction from "./communityReaction.model";
import InteriorStyle from "./interiorStyle.model";
import Keyword from "./keyword.model";
import {
   Model,
   ModelError,
   Paginate,
   TModelError,
   TPaginate,
   TPayload,
} from "./model";
import Reaction, { EReactionTypes } from "./reaction.model";
import User from "./user.model";
import UserBookmark, { IUserBookmark } from "./userBookmark.model";
import { apiDomain, tokenKey } from "./__variables";
import Location from "./location.model";
import { Space } from "./space.model";

export enum ECommunityPostSortBy {
   id = "_id",
   title = "info.title",
   createdAt = "createdAt",
   updatedAt = "updatedAt",
}

export enum ECommunityPostStatus {
   pending = "pending",
   approved = "approved",
   rejected = "rejected",
}

export enum ECommunityPostConstructionTypes {
   apartment = "apartment",
   townhouse = "townhouse",
   villa = "villa",
   duplex = "duplex",
   other = "other",
}

export interface ICommunity extends Model {
   info?: {
      title: string | null;
      description: string | null;
      content: string | null;
      slug: string | null;
      acreage: string | null;
      price: number | null;
      imageFeatured: string | null;
      typeOfConstruction: ECommunityPostConstructionTypes;
      viewCount: number;
      fakeViewCount: number;
      endDescription?: string;
      introDescription?: string;
   };
   original?: {
      email?: string | null;
      phone?: string | null;
      expandedUrl?: string | null;
      content?: string | null;
      acreage?: number | null;
      price?: number | null;
   };

   options?: {
      status: ECommunityPostStatus | null;
   };
   userId?: string | null;
   mediaId?: string | null;
   styleIds?: string[];
   keywordIds?: string[];

   __media?: CommunityMedia | null;
   __user?: User | null;
   __styles?: InteriorStyle[];
   __keywords?: Keyword[];
   __bookmarkCount?: number;
   __reactionCount?: number;
   __location?: Location;
}

export class Community extends Model<ICommunity> implements ICommunity {
   public "info"!: {
      title: string | null;
      description: string | null;
      content: string | null;
      slug: string | null;
      acreage: string | null;
      price: number | null;
      imageFeatured: string | null;
      typeOfConstruction: ECommunityPostConstructionTypes;
      viewCount: number;
      fakeViewCount: number;
      endDescription?: string;
      introDescription?: string;
   };
   public "original"!: {
      email: string | null;
      phone: string | null;
      expandedUrl: string | null;
      content: string | null;
      acreage: number | null;
      price: number | null;
   };
   public "options"!: {
      status: ECommunityPostStatus | null;
   };
   public "userId"!: string | null;
   public "mediaId"!: string | null;
   public "styleIds"!: string[];
   public "keywordIds"!: string[];

   public "__media"!: CommunityMedia | null;
   public "__user"!: User | null;
   public "__styles"!: InteriorStyle[];
   public "__keywords"!: Keyword[];
   public "__bookmarkCount"!: number;
   public "__reactionCount"?: number;
   public "__location"?: Location;

   constructor(data?: ICommunity) {
      super(data);

      Object.assign(this, data);

      if (this?.__user) {
         this.__user = new User(this.__user);
      }

      if (this?.__media) {
         this.__media = new CommunityMedia(this.__media);
      }

      if (this?.__styles) {
         this.__styles = this.__styles.map((style) => new InteriorStyle(style));
      }
      if (this?.__keywords) {
         this.__keywords = this.__keywords.map(
            (keyword) => new Keyword(keyword)
         );
      }

      if (this.__location) {
         this.__location = new Location(this.__location);
      }
   }

   public static async userCommunityPost({
      nickname,
      email,
      phone,
      title,
      expandedUrl,
      price,
      acreage,
      mediaIds,
      location,
      typeOfConstruction,
      styleIds,
      introDescription,
      endDescription,
   }: {
      nickname?: string;
      email?: string;
      phone?: number;
      title?: string;
      expandedUrl?: string;
      price?: number;
      acreage?: number;
      mediaIds?: string[];
      location?: string;
      typeOfConstruction?: string;
      styleIds?: string[];
      introDescription?: string;
      endDescription?: string;
   }) {
      try {
         const res = await Model.fetchData({
            method: "POST",
            endPoint: "user/community/post",
            isRequiredAuthorization: true,
            body: {
               ...(!nickname ? undefined : { nickname: nickname }),
               ...(!phone ? undefined : { phone: phone }),
               ...(!email ? undefined : { email: email }),
               ...(!title ? undefined : { title: title }),
               ...(!location ? undefined : { locationId: location }),
               ...(!expandedUrl ? undefined : { expandedUrl: expandedUrl }),
               ...(!typeOfConstruction ? undefined : { typeOfConstruction }),
               ...(!styleIds ? undefined : { styleIds }),
               ...(!introDescription ? undefined : { introDescription }),
               ...(!endDescription ? undefined : { endDescription }),
               ...(!price ? undefined : { price: +price * 1000000 }),
               ...(!acreage ? undefined : { acreage: +acreage }),
               mediaIds: mediaIds ? mediaIds : [],
            },
         });

         if (res instanceof ModelError) {
            return res;
         }

         const payload = (await res.json()) as TPayload<{
            newCommunityPost: Community;
         }>;

         return new Community(payload.data.newCommunityPost);
      } catch (error: any) {
         return new ModelError(error);
      }
   }

   public static async paginate({
      keywords,
      page,
      limit,
      sort,
      sortBy,
      fromPrice,
      toPrice,
      typeOfConstruction,
      interiorStyleIds,
      locationIds,
      cookiesToken,
      constructionTypeSlug,
      interiorStyleSlug,
      url,
   }: {
      keywords?: string;
      page?: number;
      limit?: number;
      sort?: "asc" | "desc";
      sortBy?: ECommunityPostSortBy;
      fromPrice?: string;
      toPrice?: string;
      typeOfConstruction?: ECommunityPostConstructionTypes;
      interiorStyleIds?: string; // Interior style ID
      locationIds?: string;
      cookiesToken?: string;
      constructionTypeSlug?: string;
      interiorStyleSlug?: string;
      url?: string;
   }) {
      try {
         const token =
            typeof window !== "undefined" && localStorage.getItem(tokenKey);
         const lastToken = cookiesToken || token;
         const res = await fetch(
            `${apiDomain}/www/community/post/paginate?offcontent=true`,
            {
               method: "GET",
               headers: {
                  ["Origin"]: "https://spacet.vn",
                  "X-Requested-With": "XMLHttpRequest",
                  page: !page ? String(1) : String(page),
                  limit: !limit ? String(20) : String(limit),
                  sort: !sort ? "desc" : encodeURI(sort),
                  "sort-by": !sortBy
                     ? ECommunityPostSortBy.createdAt
                     : encodeURI(sortBy),
                  ...(!lastToken
                     ? undefined
                     : { ["Authorization"]: `Bearer ${lastToken}` }),
                  ...(!keywords
                     ? undefined
                     : { keywords: encodeURIComponent(keywords) }),
                  ...(!fromPrice
                     ? undefined
                     : { "from-price": String(+fromPrice * 1000000) }),
                  ...(!toPrice
                     ? undefined
                     : { "to-price": String(+toPrice * 1000000) }),
                  ...(!typeOfConstruction
                     ? undefined
                     : { "construction-types": encodeURI(typeOfConstruction) }),
                  ...(!interiorStyleIds
                     ? undefined
                     : { "interior-style-ids": encodeURI(interiorStyleIds) }),
                  ...(!locationIds
                     ? undefined
                     : { "location-slugs": encodeURI(locationIds) }),
                  ...(!constructionTypeSlug
                     ? undefined
                     : {
                          ["construction-type-slugs"]:
                             encodeURI(constructionTypeSlug),
                       }),
                  ...(!interiorStyleSlug
                     ? undefined
                     : {
                          ["interior-style-slugs"]:
                             encodeURI(interiorStyleSlug),
                       }),
                  ...(!url ? undefined : { ["url"]: encodeURI(url) }),
               },
            }
         );

         if (!res.ok) {
            return new ModelError((await res.json()) as TModelError);
         }

         const payload = (await res.json()) as TPayload<{
            paginator: TPaginate<ICommunity>;
            userBookmarks?: IUserBookmark[];
         }>;

         const paginate = new Paginate(payload?.data?.paginator, Community);

         return { paginate, userBookmarks: payload.data?.userBookmarks ?? [] };
      } catch (error: any) {}
   }

   public static async getCommunityPostBySlug(
      slug: string,
      cookiesToken?: string
   ) {
      try {
         const token =
            typeof window !== "undefined" && localStorage.getItem(tokenKey);
         const lastToken = cookiesToken || token;
         // if (!token) {
         //     return new ModelError({
         //         "httpCode": 401,
         //         "message": "Unauthorization",
         //         "errors": {
         //             "process": [
         //                 {
         //                     "code": "process.error.401",
         //                     "message": "Đăng nhập để tiếp tục."
         //                 }
         //             ]
         //         }
         //     });
         // }

         const res = await fetch(`${apiDomain}/www/community/post`, {
            method: "GET",
            headers: {
               ["Origin"]: "https://spacet.vn",
               "X-Requested-With": "XMLHttpRequest",
               ...(!lastToken
                  ? undefined
                  : { Authorization: `Bearer ${lastToken}` }),
               slug: encodeURI(slug),
            },
         });

         if (!res.ok) {
            return new ModelError((await res.json()) as TModelError);
         }

         const payload = (await res.json()) as TPayload<{
            communityPost: ICommunity;
            userBookmark: IUserBookmark;
            currentUserReactions: {
               [EReactionTypes.like]: Reaction;
            };
         }>;

         return {
            community: new Community(payload.data.communityPost),
            bookmark: !payload.data.userBookmark
               ? null
               : new UserBookmark(payload.data.userBookmark),
            currentUserReactions: {
               [EReactionTypes.like]: payload.data?.currentUserReactions?.like
                  ? new CommunityReaction(
                       payload.data.currentUserReactions.like
                    )
                  : null,
            },
         };
      } catch (error: any) {}
   }

   public static async getCommunityPostBySlugV2(
      slug: string,
      cookiesToken?: string
   ) {
      try {
         const token =
            typeof window !== "undefined" && localStorage.getItem(tokenKey);
         const lastToken = cookiesToken || token;

         const res = await fetch(`${apiDomain}/www/community/post/v2`, {
            method: "GET",
            headers: {
               ["Origin"]: "https://spacet.vn",
               "X-Requested-With": "XMLHttpRequest",
               ...(!lastToken
                  ? undefined
                  : { Authorization: `Bearer ${lastToken}` }),
               slug: encodeURI(slug),
            },
         });

         if (!res.ok) {
            return new ModelError((await res.json()) as TModelError);
         }

         const rawText = await res.text();
         let payload;
         try {
            if (rawText.trim().startsWith("<")) {
            }
            payload = JSON.parse(rawText) as TPayload<{
               communityPost: ICommunity;
               medias: {
                  space: Space;
                  medias: CommunityMedia[];
               }[];
               userBookmark: IUserBookmark;
               currentUserReactions: {
                  [EReactionTypes.like]: Reaction;
               };
            }>;
         } catch (parseError) {}

         return {
            community: new Community(payload.data.communityPost),
            medias: payload.data.medias.map((mediaGroupBySpace) => ({
               space: mediaGroupBySpace.space,
               medias: mediaGroupBySpace.medias.map(
                  (media) => new CommunityMedia(media)
               ),
            })),
            bookmark: !payload.data.userBookmark
               ? null
               : new UserBookmark(payload.data.userBookmark),
            currentUserReactions: {
               [EReactionTypes.like]: payload.data?.currentUserReactions?.like
                  ? new CommunityReaction(
                       payload.data.currentUserReactions.like
                    )
                  : null,
            },
         };
      } catch (error: any) {}
   }

   public static async getAllSlugMyHome() {
      try {
         const response = await fetch(
            `${apiDomain}/www/general/community-post-slugs`,
            {
               ["method"]: "GET",
               ["cache"]: "no-store",
               ["headers"]: {
                  ["Origin"]: "https://spacet.vn",
                  ["X-Requested-With"]: "XMLHttpRequest",
               },
            }
         );
         if (!response.ok) {
            return new ModelError((await response.json()) as TModelError);
         }

         const payload = await response.json();

         const paginate = new Paginate(payload?.data, Community);

         return paginate;
      } catch (error: any) {}
   }

   public static async commentPaginate({
      id,
      commentId,
      page,
      limit,
      sort,
      sortBy,
   }: {
      id: string;
      commentId?: string;
      page?: number;
      limit?: number;
      sort?: "asc" | "desc";
      sortBy?: ECommunityPostSortBy;
   }) {
      try {
         const res = await fetch(
            `${apiDomain}/www/community/post/comment/paginate`,
            {
               method: "GET",
               headers: {
                  "X-Requested-With": "XMLHttpRequest",
                  id: id,
                  page: !page ? String(1) : String(page),
                  limit: !limit ? String(20) : String(limit),
                  sort: !sort ? "desc" : encodeURI(sort),
                  "sort-by": !sortBy
                     ? ECommunityPostSortBy.createdAt
                     : encodeURI(sortBy),
                  ...(!commentId ? undefined : { "comment-id": commentId }),
               },
            }
         );

         if (!res.ok) {
            return new ModelError((await res.json()) as TModelError);
         }

         const payload = (await res.json()) as TPayload<{
            paginator: TPaginate<ICommunity>;
         }>;

         const paginate = new Paginate(payload?.data?.paginator, Community);

         return paginate;
      } catch (error: any) {}
   }

   public static async suggestions({
      id,
      offset,
      limit,
      cookiesToken,
   }: {
      id: string;
      offset?: number;
      limit?: number;
      cookiesToken?: string;
   }) {
      try {
         const token =
            typeof window !== "undefined" && localStorage.getItem(tokenKey);
         const lastToken = cookiesToken || token;
         const res = await Model.fetchData({
            method: "GET",
            endPoint: "community/post/suggestions",
            headers: {
               id,
               ...(!lastToken
                  ? undefined
                  : { ["Authorization"]: `Bearer ${lastToken}` }),
               ...(offset ? { offset: String(offset) } : { offset: "0" }),
               ...(limit ? { limit: String(limit) } : undefined),
            },
         });
         if (res instanceof ModelError) {
            return res;
         }
         const payload = (await res.json()) as TPayload<{
            ["suggestion"]: TPaginate<Community>;
            userBookmarks?: IUserBookmark[];
         }>;

         const paginate = new Paginate(payload?.data?.suggestion, Community);

         return { paginate, userBookmarks: payload.data?.userBookmarks ?? [] };
      } catch (error) {
         return new ModelError(error);
      }
   }

   public static async deleteCommunityPostBySlug(slug: string) {
      try {
         const del = Model.fetchData({
            method: "DELETE",
            endPoint: "user/community/post",
            isRequiredAuthorization: true,
            headers: {
               slug,
            },
         });
         if (del instanceof ModelError) return del;
         return del;
      } catch (error) {
         return new ModelError(error);
      }
   }
}

export default Community;
