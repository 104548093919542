export function getDevices(req) {
   const isServer = !!req;
   const userAgent = (
      isServer ? req.headers["user-agent"] : navigator.userAgent
   ) as string;
   if (userAgent) {
      const isLine = /\bLine\//i.test(userAgent) || false;
      const isMobile = /(iPad|iPhone|Android|Mobile)/i.test(userAgent) || false;
      const rules = [
         "WebView",
         "(iPhone|iPod|iPad)(?!.*Safari/)",
         "Android.*(wv|.0.0.0)",
      ];
      const regex = new RegExp(`(${rules.join("|")})`, "ig");
      const isInApp = Boolean(userAgent.match(regex));
      return {
         isLine,
         isMobile,
         isInApp,
      };
   }
   return {};
}

export function getUserSecretIp(req) {
   if (!req) return;
   try {
      const forwarded = req.headers["x-forwarded-for"] as any;
      const ip = forwarded
         ? forwarded.split(/, /)[0]
         : req.connection.remoteAddress;
      return ip?.split("::ffff:")?.[1] || ip;
   } catch (error) {}
}
