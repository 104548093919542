/* eslint-plugin-disable react */
/*eslint-disable */
/**
 * Model(s)
 */
import { Product } from "./product.model";

/**
 * Config(s)
 */
// import { apiDomain } from "./__variables";

/**
 * Core(s)
 */
import { Model, IModel, ModelError, TModelError, TPayload } from "./model";
import { apiDomain } from "./__variables";

export enum EStatus {
   enable = "enable",
   disable = "disable",
   delete = "delete",
}

export enum EFilter {
   location = "location",
   common = "common",
   color = "color",
   space = "space",
   designType = "designType",
   articleType = "articleType",
   interiorStyle = "interiorStyle",
   typeOfConstruction = "typeOfConstruction",
   price = "price",
   acreage = "acreage",
   fromPrice = "fromPrice",
   toPrice = "toPrice",
   fromAcreage = "fromAcreage",
   toAcreage = "toAcreage",
}

export enum EType {
   common = "common",
   color = "color",
   space = "space",
   designType = "designType",
   articleType = "articleType",
   interiorStyle = "interiorStyle",
   typeOfConstruction = "typeOfConstruction",
}

export const enum EActualWorkConstructionTypes {
   apartment = "apartment",
   townhouse = "townhouse",
   villa = "villa",
   duplex = "duplex",
   other = "other",
}

export const projectType = [
   {
      title: "Căn hộ chung cư",
      value: EActualWorkConstructionTypes?.apartment,
      slug: "can-ho-chung-cu",
   },
   {
      title: "Nhà phố",
      value: EActualWorkConstructionTypes?.townhouse,
      slug: "nha-pho",
   },
   {
      title: "Biệt thự",
      value: EActualWorkConstructionTypes?.villa,
      slug: "biet-thu",
   },
   {
      title: "Duplex",
      value: EActualWorkConstructionTypes?.duplex,
      slug: "duplex",
   },
   {
      title: "Khác",
      value: EActualWorkConstructionTypes?.other,
      slug: "khac",
   },
];

export const projectType_v2 = [
   {
      title: "Căn hộ",
      value: EActualWorkConstructionTypes?.apartment,
      slug: "can-ho-chung-cu",
   },
   {
      title: "Nhà ở",
      value: EActualWorkConstructionTypes?.townhouse,
      slug: "nha-pho",
   },
   {
      title: "Biệt thự",
      value: EActualWorkConstructionTypes?.villa,
      slug: "biet-thu",
   },
   {
      title: "Duplex",
      value: EActualWorkConstructionTypes?.duplex,
      slug: "duplex",
   },
];

export const typeTextVN = {
   [EType.common]: "",
   [EType.color]: "mau",
   [EType.space]: "loai-phong",
   [EType.designType]: "phong-cach-thiet-ke",
   [EType.articleType]: "loai-bai-viet",
   [EType.interiorStyle]: "phong-cach",
   [EType.typeOfConstruction]: "loai-cong-trinh",
   [EFilter.price]: "gia",
   [EFilter.fromPrice]: "tu-gia",
   [EFilter.toPrice]: "den-gia",
   [EFilter.acreage]: "dien-tich",
   [EFilter.fromAcreage]: "tu-dien-tich",
   [EFilter.toAcreage]: "den-dien-tich",
   [EFilter.location]: "dia-diem",
};

export type TListRoom = {
   [type in EType]: Keyword[];
};
export interface IKeyword extends IModel {
   ["info"]?: {
      ["content"]?: string | null;
      ["alphabetOnly"]?: string | null;
      ["slug"]: string;
      ["description"]?: string;
   };
   ["options"]?: {
      ["status"]?: EStatus;
      ["type"]?: EType;
   };
   ["relatedInfo"]?: {
      ["hexCode"]?: string;
      ["borderHexCode"]?: string;
      ["imageUrl"]?: string;
   };
}

export class Keyword extends Model<IKeyword> implements IKeyword {
   public ["info"]!: {
      ["content"]?: string | null;
      ["alphabetOnly"]?: string | null;
      ["slug"]: string;
      ["description"]?: string;
   };
   public ["options"]!: {
      ["status"]?: EStatus;
      ["type"]?: EType;
   };

   public ["relatedInfo"]?: {
      ["hexCode"]?: string;
      ["borderHexCode"]?: string;
      ["imageUrl"]?: string;
   };

   public ["__products"]!: Product[];

   constructor(data?: object) {
      super();

      Object.assign(this, data);
   }

   /**
    *
    * @param keywords
    * @param page
    * @param limit
    * @param sort
    * @param sortBy
    * @returns
    */
   public static async paginate(
      keywords?: string,
      page: number = 1,
      limit: number = 50,
      sort: "desc" | "asc" = "desc",
      sortBy: "createdAt" | "info.name" = "createdAt"
   ) {}

   /**
    *
    * @param id
    * @returns
    */
   public static async getProductKeywordsById(id: string) {}

   /**
    *
    * @param name
    * @returns
    */
   public static async createKeyword(content: string) {}

   /**
    *
    * @param types
    * @returns
    */
   public static async getKeywordsByType(types: string[], sortBy: "createdAt" = "createdAt", sort?: "desc" | "asc") {
      const controller = new AbortController(); // Create an AbortController
      const timeoutId = setTimeout(() => controller.abort(), 30000); // Set timeout to abort after 1 second

      try {
         // Send fetch request to the API
         const response = await fetch(`${apiDomain}/www/keyword/by-types`, {
            method: "GET",
            headers: {
               Origin: "https://spacet.vn",
               "X-Requested-With": "XMLHttpRequest",
               types: encodeURI(types.toString()),
               "sort-by": sortBy,
               sort: sort || "desc",
            },
            signal: controller.signal, // Attach signal for aborting
         });

         clearTimeout(timeoutId); // Clear the timeout if request completes

         // Parse response payload as text
         const payloadText = await response.text();

         // Parse the JSON string
         const payload = JSON.parse(payloadText) as TPayload<{
            keywordGroupsByType: TListRoom;
         }>;

         // Return the result
         return payload.data.keywordGroupsByType;
      } catch (error) {
         return new ModelError(error);
      }
   }

   public static async getDescriptionByRoom(roomSlug: string) {
      try {
         const response = await fetch(`${apiDomain}/www/keyword/by-url`, {
            ["method"]: "GET",
            ["cache"]: "no-store",
            ["headers"]: {
               ["Origin"]: "https://spacet.vn",
               ["X-Requested-With"]: "XMLHttpRequest",
               ["url"]: roomSlug.toString(),
            },
         });

         if (!response.ok) {
            return new ModelError((await response.json()) as TModelError);
         }

         const payload = await response.text();
         const data = JSON.parse(payload);

         return data.data.description;
      } catch (error) {}
   }
}

export default Keyword;
