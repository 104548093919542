import { appDomain } from "models/__variables";
import { EActualWorkTypes } from "models/actualWork.model";
import { getTypeMagazine } from "models/article.model";
import { ModelError } from "models/model";
import UserNotifications, {
   EUserNotificationActions,
   EUserNotificationOpenTypes,
} from "models/userNotifications.model";
import store from "state";
import { verify } from "state/action/auth";

export type MessageNotify = {
   collapseKey: string;
   data: {
      "info.openId": string;
      "info.openType": string;
      "info.docId": string;
   };
   from: string;
   messageId: string;
   notification: {
      android: object;
      body: string;
      title: string;
   };
   sentTime: number;
   ttl: number;
};

export enum ESupportConversationExtraType {
   supportMessage = "support:message",
   supportLeave = "support:leave",
}

const handleSeen = async (notiId) => {
   try {
      // if (noti?.options?.action === EUserNotificationActions.seen) return;
      const seen = await UserNotifications.update({
         id: notiId,
         action: EUserNotificationActions.seen,
      });
      if (seen instanceof ModelError) {
         throw Error(seen?.message);
      } else {
         store.dispatch(verify());
      }
   } catch (error) {}
};

export const handleRedirectToSite = async (data: any) => {
   // docId same is parent id (root id) of post
   // openId same is child id of post as id of like, comment...
   const {
      id,
      ["info.docId"]: docId,
      ["info.docSlug"]: docSlug,
      ["info.openId"]: openId,
      ["info.openType"]: openType,
   } = data.data;

   let redirectUrl = appDomain;
   switch (openType) {
      /* Reply, like, comment */
      case EUserNotificationOpenTypes.replyOnActualWorkComment:
         const replyOnActualWorkComment = await UserNotifications.getById(id);
         if (replyOnActualWorkComment instanceof ModelError) {
            return;
         }

         redirectUrl = `${appDomain}/${
            replyOnActualWorkComment.__actualWorkComment?.__actualWork?.options
               .articleType === EActualWorkTypes.actualWork
               ? "cong-trinh-thuc-te"
               : "thu-vien-thiet-ke"
         }/${
            replyOnActualWorkComment.__actualWorkComment?.__actualWork?.info
               .slug ?? ""
         }`;
         break;
      case EUserNotificationOpenTypes.reactionOnActualWorkComment:
         const reactionOnActualWorkComment = await UserNotifications.getById(
            id
         );
         if (reactionOnActualWorkComment instanceof ModelError) {
            return;
         }
         redirectUrl = `${appDomain}/${
            reactionOnActualWorkComment.__actualWorkReaction?.__actualWork
               ?.options.articleType === EActualWorkTypes.actualWork
               ? "cong-trinh-thuc-te"
               : "thu-vien-thiet-ke"
         }/${
            reactionOnActualWorkComment.__actualWorkReaction?.__actualWork?.info
               .slug ?? ""
         }`;
         break;
      case EUserNotificationOpenTypes.replyOnArticleComment:
         const replyOnArticleComment = await UserNotifications.getById(id);
         if (replyOnArticleComment instanceof ModelError) {
            return;
         }
         const category = getTypeMagazine(
            replyOnArticleComment.__articleComment?.__article?.categoryId ?? ""
         );
         redirectUrl = `${appDomain}/blog/${category}/${replyOnArticleComment.__articleComment?.__article?.info.slug}`;
         break;
      case EUserNotificationOpenTypes.reactionOnArticleComment:
         const reactionOnArticleComment = await UserNotifications.getById(id);
         if (reactionOnArticleComment instanceof ModelError) {
            return;
         }
         const categoryMagazine = getTypeMagazine(
            reactionOnArticleComment.__articleReaction?.__article?.categoryId ??
               ""
         );
         redirectUrl = `${appDomain}/blog/${categoryMagazine}/${
            reactionOnArticleComment.__articleReaction?.__article?.info.slug ??
            ""
         }`;
         break;
      case EUserNotificationOpenTypes.replyOnCommunityPostComment:
         const replyOnCommunityPostComment = await UserNotifications.getById(
            id
         );
         if (replyOnCommunityPostComment instanceof ModelError) {
            return;
         }
         redirectUrl = `${appDomain}/to-am-cua-toi/${replyOnCommunityPostComment?.__communityPostComment?.__communityPost?.info.slug}`;
         break;
      case EUserNotificationOpenTypes.reactionOnCommunityPostComment:
         const reactionOnCommunityPostComment = await UserNotifications.getById(
            id
         );
         if (reactionOnCommunityPostComment instanceof ModelError) {
            return;
         }
         redirectUrl = `${appDomain}/to-am-cua-toi/${reactionOnCommunityPostComment.__communityReaction?.__communityPost?.info.slug}`;
         break;
      case EUserNotificationOpenTypes.replyOnSocialFeedComment:
         const replyOnSocialFeedComment = await UserNotifications.getById(id);
         if (replyOnSocialFeedComment instanceof ModelError) {
            return;
         }
         redirectUrl = `${appDomain}/post/${replyOnSocialFeedComment?.__socialFeedComment?.__socialFeed?._id}`;
         // goResetFromInitialize("DetailSocialFeedScreen", {
         //    id: replyOnSocialFeedComment?.__socialFeedComment?.__socialFeed?._id
         // });
         break;

      /* social feed */
      case EUserNotificationOpenTypes.reactionOnSocialFeedComment:
         const reactionOnSocialFeedComment = await UserNotifications.getById(
            id
         );
         if (reactionOnSocialFeedComment instanceof ModelError) {
            return;
         }
         redirectUrl = `${appDomain}/post/${reactionOnSocialFeedComment?.__socialFeedComment?.__socialFeed?._id}`;
         // goResetFromInitialize("DetailSocialFeedScreen", {
         //    id: reactionOnSocialFeedComment?.__socialFeedComment?.__socialFeed?._id
         // });
         break;
      case EUserNotificationOpenTypes.commentOnSocialFeed:
         redirectUrl = `${appDomain}/post/${docId}`;
         // goResetFromInitialize("DetailSocialFeedScreen", {
         //    id: remoteMessage.data["info.docId"]
         // });
         break;
      case EUserNotificationOpenTypes.reactionOnSocialFeed:
         redirectUrl = `${appDomain}/post/${docId}`;
         // goResetFromInitialize("DetailSocialFeedScreen", {
         //    id: remoteMessage.data["info.docId"]
         // });
         break;

      case EUserNotificationOpenTypes.reactionOnSocialFeed:
         redirectUrl = `${appDomain}/post/${docId}`;
         // goResetFromInitialize("DetailSocialFeedScreen", {
         //    id: remoteMessage.data["info.docId"]
         // });
         break;

      /* new social feed */
      case EUserNotificationOpenTypes.newReactionOnSocialFeedByUser:
         redirectUrl = `${appDomain}/post/${docId}`;
         // goResetFromInitialize("DetailSocialFeedScreen", {
         //    id: remoteMessage.data["info.docId"]
         // });
         break;
      case EUserNotificationOpenTypes.newReactionOnSocialFeedCommentByUser:
         redirectUrl = `${appDomain}/post/${docId}`;
         // goResetFromInitialize("DetailSocialFeedScreen", {
         //    id: remoteMessage.data["info.docId"]
         // });
         break;
      case EUserNotificationOpenTypes.newCommentOnSocialFeedByUser:
         redirectUrl = `${appDomain}/post/${docId}`;
         // goResetFromInitialize("DetailSocialFeedScreen", {
         //    id: remoteMessage.data["info.docId"]
         // });
         break;
      case EUserNotificationOpenTypes.newReplyOnSocialFeedCommentByUser:
         redirectUrl = `${appDomain}/post/${docId}`;
         // goResetFromInitialize("DetailSocialFeedScreen", {
         //    id: remoteMessage.data["info.docId"]
         // });
         break;

      case EUserNotificationOpenTypes.newSocialFeedFromSubscribingUser:
         redirectUrl = `${appDomain}/post/${docId}`;
         // goResetFromInitialize("DetailSocialFeedScreen", {
         //    id: remoteMessage.data["info.docId"]
         // });
         break;
      case EUserNotificationOpenTypes.receiptSubscriptionFromOneUser:
         redirectUrl = `${appDomain}/tai-khoan/${docId}`;
         // goResetFromInitialize("ViewUserInfoScreen", {
         //    id: remoteMessage.data["info.docId"]
         // });
         break;
      case EUserNotificationOpenTypes.orderCreated:
         redirectUrl = `${appDomain}/tai-khoan/don-hang?orderId=${docId}&type=order`;
         // goResetFromInitialize("DetailMyOrderScreen", {
         //    id: remoteMessage.data["info.docId"]
         // });
         break;
      case EUserNotificationOpenTypes.orderCancelled:
         redirectUrl = `${appDomain}/tai-khoan/don-hang?orderId=${docId}&type=order`;
         // goResetFromInitialize("DetailMyOrderScreen", {
         //    id: remoteMessage.data["info.docId"]
         // });
         break;
      case EUserNotificationOpenTypes.orderConfirmed:
         redirectUrl = `${appDomain}/tai-khoan/don-hang?orderId=${docId}&type=order`;
         // goResetFromInitialize("DetailMyOrderScreen", {
         //    id: remoteMessage.data["info.docId"]
         // });
         break;
      case EUserNotificationOpenTypes.orderRefunded:
         redirectUrl = `${appDomain}/tai-khoan/don-hang?orderId=${docId}&type=order`;
         // goResetFromInitialize("DetailMyOrderScreen", {
         //    id: remoteMessage.data["info.docId"]
         // });
         break;
      case EUserNotificationOpenTypes.orderSucceed:
         redirectUrl = `${appDomain}/tai-khoan/don-hang?orderId=${docId}&type=order`;
         // goResetFromInitialize("DetailMyOrderScreen", {
         //    id: remoteMessage.data["info.docId"]
         // });
         break;
      case EUserNotificationOpenTypes.approvePartnerReview:
         // redirectUrl = `${appDomain}/tai-khoan/don-hang?orderId=${docId}&type=order`;
         // goResetFromInitialize("ReviewProductScreen", {});
         break;
      case EUserNotificationOpenTypes.approveProductReview:
         // const resultPartner = await UserNotifications.getById(id);
         // if (resultPartner instanceof ModelError) {
         //    return;
         // }
         redirectUrl = `${appDomain}/tai-khoan/don-hang?type=product`;
         // goResetFromInitialize("companysDetail", {
         //    data: resultPartner.__partnerReview.__partner
         // });
         break;
      case EUserNotificationOpenTypes.approveQnA:
         const resultQnA = await UserNotifications.getById(id);
         if (resultQnA instanceof ModelError) {
            return;
         }
         redirectUrl = `${appDomain}/hoi-dap/${openId}`;
         // goResetFromInitialize("detailQnA", {
         //    id: resultQnA.__qna._id,
         //    callBack: () => {}
         // });
         break;
      case EUserNotificationOpenTypes.rejectQnA:
         // goResetFromInitialize("MyQnAScreen", {});
         break;
      case EUserNotificationOpenTypes.commentOnQnA:
         redirectUrl = `${appDomain}/hoi-dap/${docId}`;
         // goResetFromInitialize("detailQnA", {
         //    id: remoteMessage.data["info.docId"],
         //    callBack: () => {}
         // });
         break;
      case EUserNotificationOpenTypes.rejectPartnerReview:
         // goResetFromInitialize("ReviewProductScreen", {});
         break;
      case EUserNotificationOpenTypes.approveCommunityPost:
         const resultCommunityPost = await UserNotifications.getById(id);
         if (resultCommunityPost instanceof ModelError) {
            return;
         }
         redirectUrl = `${appDomain}/to-am-cua-toi/${
            resultCommunityPost?.__communityPost?.info?.slug ?? ""
         }`;
         break;
      case EUserNotificationOpenTypes.rejectPartnerReview:
         break;
      case EUserNotificationOpenTypes.rejectProductReview:
         redirectUrl = `${appDomain}/tai-khoan/don-hang?type=product`;
         break;
      case EUserNotificationOpenTypes.rejectQnA:
         break;
      case EUserNotificationOpenTypes.pointOfOrderCancelled:
         redirectUrl = `${appDomain}/tai-khoan/don-hang?type=coin`;
         break;
      case EUserNotificationOpenTypes.rejectCommunityPost:
         break;
      case EUserNotificationOpenTypes.pointOfOrderCancelled:
         redirectUrl = `${appDomain}/tai-khoan/don-hang?type=coin`;
         break;
      case EUserNotificationOpenTypes.pointOfOrderSucceed:
         redirectUrl = `${appDomain}/tai-khoan/don-hang?type=coin`;
         break;
      case EUserNotificationOpenTypes.pointOfQnAComment:
         redirectUrl = `${appDomain}/tai-khoan/don-hang?type=coin`;
         break;
      case EUserNotificationOpenTypes.pointOfRegistration:
         redirectUrl = `${appDomain}/tai-khoan/don-hang?type=coin`;
         break;
      case EUserNotificationOpenTypes.pointOfReviewPartner:
         redirectUrl = `${appDomain}/tai-khoan/don-hang?type=coin`;
         break;
      case EUserNotificationOpenTypes.pointOfReviewPartnerViaSpaceT:
         redirectUrl = `${appDomain}/tai-khoan/don-hang?type=coin`;
         break;
      case EUserNotificationOpenTypes.pointOfReviewProduct:
         redirectUrl = `${appDomain}/tai-khoan/don-hang?type=coin`;
         break;
      case EUserNotificationOpenTypes.pointOfSocialFeed:
         redirectUrl = `${appDomain}/tai-khoan/don-hang?type=coin`;
         break;
      case EUserNotificationOpenTypes.userBadgeUpdated:
         redirectUrl = `${appDomain}/tai-khoan/${docId || openId}`;
         break;
      case EUserNotificationOpenTypes.homepage:
         redirectUrl = `${appDomain}`;
         break;
      case EUserNotificationOpenTypes.shopHome:
         redirectUrl = `${appDomain}/shop`;
         break;
      case EUserNotificationOpenTypes.shopCategory:
         redirectUrl = `${appDomain}/shop/${docSlug}`;
         break;
      case EUserNotificationOpenTypes.flashSales:
         redirectUrl = `${appDomain}/shop/flash-sale`;
         break;
      case EUserNotificationOpenTypes.bestSeller:
         redirectUrl = `${appDomain}/shop/san-pham-ban-chay`;
         break;
      case EUserNotificationOpenTypes.newArrival:
         redirectUrl = `${appDomain}/shop/san-pham-moi`;
         break;
      case EUserNotificationOpenTypes.product:
         redirectUrl = `${appDomain}/shop/${docSlug}`;
         break;
      case EUserNotificationOpenTypes.brandHome:
         redirectUrl = `${appDomain}/shop/thuong-hieu/${docSlug}`;
         break;
      case EUserNotificationOpenTypes.feedHome:
         redirectUrl = `${appDomain}/feed`;
         break;
      case EUserNotificationOpenTypes.blogHome:
         redirectUrl = `${appDomain}/blog`;
         break;
      case EUserNotificationOpenTypes.libraryHome:
         redirectUrl = `${appDomain}/thu-vien-anh`;
         break;
      case EUserNotificationOpenTypes.designLibrary:
         redirectUrl = `${appDomain}/thu-vien-thiet-ke`;
         break;
      case EUserNotificationOpenTypes.actualWork:
         redirectUrl = `${appDomain}/cong-trinh-thuc-te`;
         break;
      case EUserNotificationOpenTypes.calculator:
         redirectUrl = `${appDomain}/bao-gia`;
         break;
      case EUserNotificationOpenTypes.interiorFirm:
         redirectUrl = `${appDomain}/cac-cong-ty-noi-that`;
         break;
      case EUserNotificationOpenTypes.feedPost:
         redirectUrl = `${appDomain}/post/${docId}`;
         break;
      default:
         break;
   }

   handleSeen(id);
   window.open(redirectUrl, "_blank");
};
