/**
 * Model(s)
 */
import { apiDomain, tokenKey } from "./__variables";
import Comment, { ECommentSortBy, IComment } from "./comment.model";
import Community from "./community.model";
import CommunityReaction from "./communityReaction.model";
import {
   ModelError,
   Paginate,
   TModelError,
   TPaginate,
   TPayload,
} from "./model";
import User from "./user.model";

export interface ICommunityComment extends IComment {
   ["__reactionCount"]?: number;
   ["communityPostId"]: string | null;
   ["communityPostCommentId"]: string | null;

   ["__communityPost"]?: Community | null;
   ["__communityPostComment"]?: CommunityComment | null;
   ["__reaction"]?: CommunityReaction | null;

   ["__replies"]?: CommunityComment[];
}

export class CommunityComment
   extends Comment<ICommunityComment>
   implements ICommunityComment
{
   public ["__reactionCount"]?: number;
   public ["communityPostId"]!: string | null;
   public ["communityPostCommentId"]!: string | null;

   public ["__communityPost"]!: Community | null;
   public ["__communityPostComment"]!: CommunityComment | null;
   public ["__reaction"]!: CommunityReaction | null;

   public ["__replies"]!: CommunityComment[];

   constructor(data?: ICommunityComment) {
      super(data);
      Object.assign(this, data);
      if (this?.__reaction) {
         this.__reaction = new CommunityReaction(this.__reaction);
      }

      if (this?.__user) {
         this.__user = new User(this.__user);
      }

      if (this?.__replies) {
         this.__replies = this.__replies.map(
            (reply) => new CommunityComment(reply)
         );
      }

      if (this?.__replyToUsers) {
         this.__replyToUsers = this.__replyToUsers.map(
            (replyToUser) => new User(replyToUser)
         );
      }

      if (this?.__communityPost) {
         this.__communityPost = new Community(this.__communityPost);
      }

      if (this?.__communityPostComment) {
         this.__communityPostComment = new CommunityComment(
            this.__communityPostComment
         );
      }
   }

   public static async paginate({
      id,
      commentId,
      page,
      limit,
      sort,
      sortBy,
   }: {
      id: string;
      commentId?: string;
      page?: number;
      limit?: number;
      sort?: "asc" | "desc";
      sortBy?: ECommentSortBy;
   }) {
      try {
         const token = localStorage.getItem(tokenKey);
         const res = await fetch(
            `${apiDomain}/www/community/post/comment/paginate`,
            {
               method: "GET",
               headers: {
                  "X-Requested-With": "XMLHttpRequest",
                  id: id,
                  ...(!token
                     ? undefined
                     : { Authorization: `Bearer ${token}` }),
                  page: !page ? String(1) : String(page),
                  limit: !limit ? String(20) : String(limit),
                  sort: !sort ? "desc" : encodeURI(sort),
                  "sort-by": !sortBy
                     ? ECommentSortBy.createdAt
                     : encodeURI(sortBy),
                  ...(!commentId ? undefined : { "comment-id": commentId }),
               },
            }
         );

         if (!res.ok) {
            return new ModelError((await res.json()) as TModelError);
         }

         const payload = (await res.json()) as TPayload<{
            ["paginator"]: TPaginate<ICommunityComment>;
         }>;

         const paginate = new Paginate(
            payload?.data?.paginator,
            CommunityComment
         );

         return paginate;
      } catch (error: any) {}
   }
}

export default CommunityComment;
