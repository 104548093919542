/* eslint-plugin-disable react */
/*eslint-disable */
/**
 * Variable(s)
 */
import { cloudFront } from "@Config/__env";
import { apiDomain } from "./__variables";

/**
 * Core(s)
 */
import Model, {
   Paginate,
   ModelError,
   TPayload,
   TPaginate,
   TModelError,
} from "./model";
import Media, { IMediaInfo, IMediaOptions } from "./modelMedia.model";

export enum EStatus {
   enable = "enable",
   disable = "disable",
   delete = "delete",
}
export interface IBrandLogo extends Media {
   ["status"]?: EStatus | null;
}

export interface IBrand extends Model {
   ["companyId"]?: string | null;
   ["info"]?: {
      ["name"]?: string | null;
      ["slug"]?: string | null;
      ["description"]?: string | null;
      ["origin"]?: string | null;
      ["isMigrated"]?: boolean;
   };
   ["__productCount"]?: number;
   ["options"]?: IMediaOptions;
   ["logo"]?: IMediaInfo;
   ["$url"]?: any | null;
   ["$url64ResizeMax"]?: string;
   ["$url5And6ColResizeMax"]?: string;
   ["$url3And4ColResizeMax"]?: string;
   ["$url2ColResizeMax"]?: string;
   ["$url1ColResizeMax"]?: string;
}

export class Brand extends Model<IBrand> implements IBrand {
   public ["companyId"]!: string | null;
   public ["info"]!: {
      ["name"]?: string | null;
      ["slug"]?: string | null;
      ["description"]?: string | null;
      ["slug"]?: string | null;
      ["isMigrated"]?: boolean;
   };
   public ["__productCount"]?: number;
   public ["options"]!: IMediaOptions;
   public ["logo"]?: IMediaInfo;
   public ["$url"]?: any | null;
   public ["$url64ResizeMax"]?: string;
   public ["$url5And6ColResizeMax"]?: string;
   public ["$url3And4ColResizeMax"]?: string;
   public ["$url2ColResizeMax"]?: string;
   public ["$url1ColResizeMax"]?: string;

   constructor(data?: IBrand) {
      super(data);

      Object.assign(this, data);
      const filePath =
         this.options?.isMigrated || this.info?.isMigrated
            ? `${this.logo?.originalName}`
            : `${this._id}${this.logo?.extension}`;
      if (
         filePath &&
         this.logo?.protocol &&
         this.logo?.domain &&
         this.logo?.container
      ) {
         this.$url = `${this.logo?.protocol}://${this.logo?.domain}/${this.logo?.container}/${filePath}`;
         this.$url64ResizeMax = `${cloudFront}/image-resized/64x64_max/${this.logo?.container}/${filePath}`;
         this.$url5And6ColResizeMax = `${cloudFront}/image-resized/256x2560_max/${this.logo?.container}/${filePath}`;
         this.$url3And4ColResizeMax = `${cloudFront}/image-resized/384x3840_max/${this.logo?.container}/${filePath}`;
         this.$url2ColResizeMax = `${cloudFront}/image-resized/768x7680_max/${this.logo?.container}/${filePath}`;
         this.$url1ColResizeMax = `${cloudFront}/image-resized/1024x10240_max/${this.logo?.container}/${filePath}`;
      }
   }

   public static async paginate({
      page,
      limit,
      sort,
      sortBy,
      ids,
   }: {
      keywords?: string;
      page?: number;
      limit?: number;
      sort?: "asc" | "desc";
      sortBy?: "info.publishedAt";
      creatorId?: string;
      ids?: string;
      offset?: number;
      cookiesToken?: string;
   }) {
      try {
         const response = await this.fetch({
            ["method"]: "GET",
            ["endPoint"]: "brand/paginate",
            ["headers"]: {
               ["page"]: !page ? String(1) : String(page),
               ["limit"]: !limit ? String(500) : String(limit),
               ["sort"]: !sort ? "desc" : encodeURI(sort),
               ["sort-by"]: !sortBy ? "createdAt" : encodeURI(sortBy),
               ...(!!ids && { ["ids"]: ids }),
            },
         });

         const payload = (await response) as TPayload<{
            ["paginator"]: TPaginate<IBrand>;
         }>;

         return new Paginate(payload?.data?.paginator, Brand);
      } catch (error) {}
   }

   public static async paginateBrandDay() {
      try {
         const response = await this.fetch({
            ["method"]: "GET",
            ["endPoint"]: "brand/brand-day",
         });
         if (response instanceof ModelError) {
            return response;
         }
         const payload = response as TPayload<{
            ["paginator"]: TPaginate<IBrand>;
         }>;

         return payload?.data?.paginator?.docs;
      } catch (error) {
         return [];
      }
   }

   public static async topBrands() {
      try {
         const response = await this.fetch({
            ["method"]: "GET",
            ["endPoint"]: "brand/on-shop",
         });
         if (response instanceof ModelError) {
            return response;
         }
         // The response is already a JSON payload, no need to parse text
         const payload = response as TPayload<{
            ["paginator"]: TPaginate<{
               _id: string;
               info: {
                  name: string;
                  slug: string;
               };
               thumbnailUrl: string;
               mediaUrl: string;
            }>;
         }>;

         return payload?.data?.paginator?.docs;
      } catch (error) {}
   }

   public static async getBrandById(slug: string) {
      try {
         const res = await fetch(`${apiDomain}/www/brand`, {
            method: "GET",
            headers: {
               Origin: "https://spacet.vn",
               "X-Requested-With": "XMLHttpRequest",
               slug: encodeURI(slug),
            },
         });

         if (!res.ok) {
            const errorText = await res.text();
            try {
               const errorJson = JSON.parse(errorText);
               return new ModelError(errorJson as TModelError);
            } catch (parseError) {
               throw new Error(`Invalid response: ${errorText}`);
            }
         }

         const responseText = await res.text();
         try {
            const payload = JSON.parse(responseText) as TPayload<{
               brand: Brand;
            }>;
            return new Brand(payload.data.brand);
         } catch (parseError) {
            throw new Error(`Invalid JSON response: ${responseText}`);
         }
      } catch (error) {}
   }
}

export default Brand;
