/* eslint-plugin-disable react */
/*eslint-disable */
/**
 * Variable(s)
 */
import { apiDomain } from "./__variables";

/**
 * Model(s)
 */
import Model, {
   ModelError,
   TModelError,
   TPaginate,
   Paginate,
   TPayload,
   IModel,
} from "./model";

export enum EStatus {
   enable = "enable",
   disable = "disable",
   delete = "delete",
}

export enum EPriceRange {
   high = "high",
   low = "low",
}

export interface IInteriorStyle extends IModel {
   ["info"]?: {
      ["name"]?: string | null;
      ["slug"]: string;
      ["position"]?: number;
   };
   ["options"]?: {
      ["status"]?: EStatus;
   };
}

export class InteriorStyle
   extends Model<IInteriorStyle>
   implements IInteriorStyle
{
   public ["info"]!: {
      ["name"]?: string | null;
      ["slug"]: string;
      ["position"]?: number;
   };
   public ["options"]!: {
      ["status"]?: EStatus;
   };

   constructor(data?: IInteriorStyle) {
      super(data);

      Object.assign(this, data);
   }

   public static async getInteriorStylePaginate(
      keywords?: string,
      page: number = 1,
      limit: number = 50,
      sort: "asc" | "desc" = "asc",
      sortBy: "createdAt" | "info.name" = "createdAt"
   ) {
      try {
         const response = await fetch(
            `${apiDomain}/www/interior/style/paginate`,
            {
               ["method"]: "GET",
               ["cache"]: "no-store",
               ["headers"]: {
                  ["X-Requested-With"]: "XMLHttpRequest",
                  ["page"]: String(page),
                  ["limit"]: String(limit),
                  ["sort"]: sort,
                  ["sort-by"]: sortBy,
                  ...(!keywords
                     ? undefined
                     : { ["keywords"]: encodeURI(keywords) }),
               },
            }
         );
         if (!response.ok) {
            return new ModelError((await response.json()) as TModelError);
         }

         const payload = (await response.json()) as TPayload<{
            ["paginator"]: TPaginate<IInteriorStyle>;
         }>;

         const paginate = new Paginate(payload?.data?.paginator, InteriorStyle);

         return paginate;
      } catch (error: any) {}
   }
}

export default InteriorStyle;
