import Brand from "models/brand.model";
import UserCartByBrand from "models/userCardByBrand";
import UserCart from "models/userCart.model";
import React, { useState } from "react";
// import Skeleton from "@mui/material/Skeleton";
// import { currency } from "utils";
import ProductVariant, {
   EStatus,
   EStockStatus,
   IProductVariantInfo,
} from "models/productVariant.model";
// import Link from "@Components/Link";
// import VoucherLabel from "@Pages/shop/components/voucherLabel";
import Modal from "@Components/modal";
import Button from "@Components/button";
import styles from "./PopoverShopCart.module.scss";
import classNames from "classnames";
// import { useBookmark } from "hook/useBookmark";
// import { ModelError } from "@Models/model";
// import UserBookmark from "@Models/userBookmark.model";
import Product from "@Models/product.model";
import ProductItem from "./ProductItem";

interface IStateProductItem {
   // dialogShopDiscount?: boolean;
   tooltipRemoveId?: string;
   showTooltipDelete: { [id: string]: boolean };
}
function CartByBrandItem({
   cartItem,
   orders,
   shopVoucherLoading,
   cartByBrandInteract,
   // voucherSelect,
   ordersIds,
   brandOrders,
   outstockProductVariantId,
   onCheckOrderByBrand,
   onCheckOrderItem,
   // onSelectVariant,
   onRemoveProductFromCart,
   onUpdateItemCart,
   toggleBookmarkProduct,
}: // onConfirmSelectVoucher,
{
   cartItem: UserCartByBrand;
   cartByBrandInteract?: UserCartByBrand;
   shopVoucherLoading?: boolean;
   orders: UserCart[];
   // voucherSelect?: VoucherV2;
   ordersIds: string[];
   brandOrders: Brand[];
   outstockProductVariantId?: string[];
   onCheckOrderByBrand: (arg?: any) => void;
   onCheckOrderItem: (arg?: any, arg2?: any) => void;
   // onSelectVariant: (arg: UserCart) => void;
   onRemoveProductFromCart: any;
   onUpdateItemCart: any;
   toggleBookmarkProduct: (
      cartItem: UserCartByBrand,
      product: Product,
      isBookmark?: boolean
   ) => Promise<void>;
   // onConfirmSelectVoucher: (byBrand: UserCartByBrand, arg?: VoucherV2) => void;
}) {
   // const tooltipDeleteRef = React.createRef<any>();
   const [state, setstate] = useState<IStateProductItem>({
      showTooltipDelete: {},
   });
   function setState(newState: Partial<IStateProductItem>) {
      setstate((prev) => ({
         ...prev,
         ...newState,
      }));
   }

   function handleToggleDialogDelete(id) {
      setState({ showTooltipDelete: { [id]: !state.showTooltipDelete[id] } });
   }
   function checkBrandSelected(id: string) {
      return brandOrders.findIndex((item) => item._id === id) > -1;
   }

   function checkProductVariantSelected(id: string) {
      return orders.findIndex((item) => item._id === id) > -1;
   }
   // function renderQuantityBox(isEnable: boolean, cartItem: UserCartByBrand, productItem: UserCart) {
   //    return (
   //       <div className={styles["quantity-box"]}>
   //          <button
   //             className={styles["minus-button"]}
   //             title="Giảm số lượng"
   //             disabled={!isEnable || !!(productItem?.info?.quantity && productItem?.info?.quantity < 2)}
   //             onClick={() => {
   //                if (productItem?.info?.quantity && productItem?.info?.quantity < 2) {
   //                   return;
   //                }
   //                onUpdateItemCart(cartItem, productItem, -1);
   //             }}
   //          >
   //             -
   //          </button>
   //          <input type="number" value={productItem?.info?.quantity} className={styles["quantity-input"]} readOnly />
   //          <button
   //             className={styles["plus-button"]}
   //             title="Tăng số lượng"
   //             disabled={!isEnable}
   //             onClick={() => {
   //                onUpdateItemCart(cartItem, productItem, 1);
   //             }}
   //          >
   //             +
   //          </button>
   //       </div>
   //    );
   // }
   // function caculatePriceItem(currVariant: ProductVariant) {
   //    if (currVariant?.info?.price === undefined && currVariant?.info?.priceAfterDiscount === undefined) {
   //       return (
   //          <div className={styles["price"]}>
   //             <Skeleton animation="wave" width={100} height={25} className="me-2" />
   //             <Skeleton animation="wave" width={150} height={24} />
   //          </div>
   //       );
   //    } else if (currVariant?.info?.price === 0 && currVariant?.info?.priceAfterDiscount === 0) {
   //       return (
   //          <div className={classNames(styles["price"], "text-danger")}>
   //             <span>Giá: Liên hệ</span>
   //          </div>
   //       );
   //    } else if (currVariant?.info?.price && currVariant?.info?.priceAfterDiscount) {
   //       const percentDiscount = 100 - (currVariant.info.priceAfterDiscount * 100) / currVariant.info.price;
   //       return (
   //          <div className={styles["price"]}>
   //             <div className={styles["discount-price"]}>
   //                {currency(currVariant.info.priceAfterDiscount || 0)}
   //                <span>đ</span>
   //             </div>
   //             <div className="d-flex align-items-center mt-1 mt-md-1">
   //                <span className={styles["real-price"]}>{currency(currVariant.info.price || 0)}đ</span>
   //                {currVariant.info.priceAfterDiscount && <div className={styles["percent-discount"]}>{Math.round(percentDiscount ?? 0)}%</div>}
   //             </div>
   //          </div>
   //       );
   //    } else {
   //       return (
   //          <div className={styles["price"]}>
   //             <div className={styles["discount-price"]}>
   //                {currency(currVariant.info.price || 0)}
   //                <span>đ</span>
   //             </div>
   //          </div>
   //       );
   //    }
   // }
   // function renderVariantOption(variant: IProductVariantInfo) {
   //    return (
   //       <>
   //          {variant.option01 && <span>{variant.option01}</span>}
   //          {variant.option02 && <span>{variant.option02}</span>}
   //          {/* {variant.option03 && <span>{variant.option03}</span>} */}
   //       </>
   //    );
   // }

   // function renderVoucherView() {
   //    return (
   //       <div className={styles["shop-voucher"]}>
   //          <div className="d-flex align-items-center">
   //             <svg xmlns="http://www.w3.org/2000/svg" width="24" height="14" viewBox="0 0 24 14" fill="none">
   //                <path
   //                   d="M16.4895 0H0.740102C0.325645 0 0 0.340708 0 0.774336V3.31416C0 3.68584 0.266437 3.99558 0.592082 4.05752C1.92427 4.33628 2.8716 5.4823 2.8716 7C2.8716 8.5177 1.92427 9.66372 0.592082 9.94248C0.236833 10.0044 0 10.3142 0 10.6858V13.2257C0 13.6593 0.325645 14 0.740102 14H16.4895V0ZM6.69052 4.21239H12.4337C12.8482 4.21239 13.1738 4.5531 13.1738 4.98673C13.1738 5.42035 12.8482 5.76106 12.4337 5.76106H6.69052C6.27606 5.76106 5.95042 5.42035 5.95042 4.98673C5.95042 4.5531 6.27606 4.21239 6.69052 4.21239ZM12.4337 9.78761H6.69052C6.27606 9.78761 5.95042 9.4469 5.95042 9.01328C5.95042 8.57965 6.27606 8.23894 6.69052 8.23894H12.4337C12.8482 8.23894 13.1738 8.57965 13.1738 9.01328C13.1738 9.4469 12.8482 9.78761 12.4337 9.78761Z"
   //                   fill="#FF6347"
   //                />
   //                <path
   //                   d="M23.4072 9.94248C22.075 9.66372 21.1277 8.5177 21.1277 7C21.1277 5.4823 22.075 4.33628 23.4072 4.05752C23.7329 3.99558 23.9993 3.68584 23.9993 3.31416V0.774336C23.9993 0.340708 23.6737 0 23.2592 0H17.1016V14H23.2592C23.6737 14 23.9993 13.6593 23.9993 13.2257V10.6858C23.9993 10.3142 23.7625 10.0044 23.4072 9.94248Z"
   //                   fill="#8294A2"
   //                />
   //             </svg>
   //             <div className="d-flex flex-column">
   //                {(cartItem?.discount?.appliedVoucher || (!cartItem?.discount?.appliedVoucher && !cartItem?.discount?.recommendVoucher)) && (
   //                   <div className="name ms-2 fw-600 fs-14">Shop Voucher</div>
   //                )}
   //                {cartItem?.discount?.voucherMessage && (
   //                   <div className="span ms-2  fs-12 fs-md-14">
   //                      {cartItem?.discount?.voucherMessage}
   //                      {/* Mua thêm {currency(state.recommendVoucher?.conditions?.minValue)}₫ để được mức giảm{" "}{state.recommendVoucher?.discount?.type === "AMOUNT"
   //                         ? currency(state.recommendVoucher?.discount?.amountOff) + "đ" : state.recommendVoucher?.discount?.percentOff + "%"} */}
   //                   </div>
   //                )}
   //             </div>
   //          </div>
   //          <div
   //             className="d-flex align-items-center pointer"
   //             // onClick={() => {
   //             //    setState({ dialogShopDiscount: true });
   //             // }}
   //          >
   //             {cartItem?.discount?.appliedVoucher ? (
   //                <VoucherLabel disabled={!cartItem?.discount?.appliedVoucher?.canUse} className="me-2">
   //                   -{cartItem?.discount?.appliedVoucher?.discount?.label}
   //                </VoucherLabel>
   //             ) : !cartItem?.discount?.recommendVoucher ? (
   //                <div className="text-primary me-1 fs-12">Chọn hoặc nhập mã</div>
   //             ) : (
   //                ""
   //             )}
   //             <svg xmlns="http://www.w3.org/2000/svg" width="5" height="10" viewBox="0 0 5 10" fill="none">
   //                <path
   //                   fillRule="evenodd"
   //                   clipRule="evenodd"
   //                   d="M1.43827 8.80689C1.27679 8.97166 1.067 9.05469 0.842857 9.05469C0.701482 9.05469 0.556711 9.02202 0.422879 8.94449C0.295668 8.87245 0.192274 8.77003 0.119126 8.64392C0.034511 8.50641 -6.10689e-08 8.35614 -7.37551e-08 8.21103C-9.32091e-08 7.9885 0.0835236 7.78896 0.227733 7.63127L0.238017 7.62003L2.94617 4.99887L0.241705 2.37739L0.234979 2.37034C0.081108 2.2091 -6.16352e-07 2.00445 -6.35726e-07 1.78283C-6.48748e-07 1.63388 0.0375817 1.48758 0.116147 1.35511C0.190184 1.22543 0.297383 1.11964 0.432208 1.04845C0.563424 0.974749 0.704761 0.943577 0.842857 0.943577C1.06205 0.943577 1.27133 1.02159 1.43511 1.1839L4.73484 4.3858C4.81422 4.46037 4.88511 4.55311 4.93336 4.66598C4.98045 4.77609 5 4.88951 5 4.99913C5 5.1102 4.97885 5.22149 4.93336 5.32788C4.88511 5.44074 4.81423 5.53348 4.73485 5.60805L1.43827 8.80689Z"
   //                   fill="#9F9F9F"
   //                />
   //             </svg>
   //          </div>
   //       </div>
   //    );
   // }

   const fOrderIsAvailable = cartItem.items.filter((it) => {
      return (
         it.__variant.options.stockStatus === EStockStatus.Stocking &&
         it.__variant.options.status === EStatus.enable &&
         it.__product?.options.status === EStatus.enable
      );
   });
   const isEnableBrand = fOrderIsAvailable && fOrderIsAvailable.length > 0;
   const isSelected = checkBrandSelected(cartItem.idBrand);

   return (
      <>
         <div className={styles["order-product-item"]}>
            <div className={styles["brand-info"]}>
               <div className={styles["wrap-checkbox"]}>
                  <div
                     className={`${
                        isSelected
                           ? classNames(styles["checkbox"], styles["checked"])
                           : styles["checkbox"]
                     } `}
                     onClick={() => {
                        if (isEnableBrand) {
                           onCheckOrderByBrand(cartItem);
                        }
                     }}
                  ></div>
               </div>
               <div className={styles["brand-name"]}>
                  <svg
                     className="me-2"
                     xmlns="http://www.w3.org/2000/svg"
                     width="16"
                     height="16"
                     viewBox="0 0 16 16"
                     fill="none"
                  >
                     <path
                        d="M13.9756 3.03366L15.2029 6.0224C15.4395 6.60782 15.3509 7.25977 14.9646 7.77667C14.5263 8.3517 13.8114 8.70596 13.0371 8.73177C11.8287 8.81484 10.7377 8.08253 10.4815 7.01649C10.4815 7.01649 10.4382 7.01649 10.431 7.01649C10.1917 8.05031 9.15597 8.77623 7.98366 8.73177C6.81671 8.76973 5.7892 8.04529 5.55079 7.01649C5.55079 7.01649 5.50748 7.01649 5.50026 7.01649C5.36049 7.58821 4.97359 8.08635 4.425 8.40087C3.87642 8.71539 3.21128 8.82043 2.57648 8.69278C2.01224 8.60932 1.49995 8.34652 1.13264 7.9521C0.656375 7.40918 0.535492 6.67976 0.815 6.0354L2.01339 3.03366C2.29493 2.39479 2.98984 1.98218 3.75321 2.00059H12.2358C12.9991 1.98218 13.694 2.39479 13.9756 3.03366Z"
                        fill="#9F9F9F"
                     />
                     <path
                        d="M13.1093 9.72585V13.0395C13.098 13.4031 12.9265 13.7478 12.6327 13.9976C12.3389 14.2474 11.9468 14.3819 11.5427 14.3714H9.49969V12.3248C9.46079 11.6416 8.815 11.1156 8.05585 11.1488C7.68066 11.1451 7.31753 11.2679 7.03795 11.4931C6.76895 11.7018 6.6136 12.0051 6.61201 12.3248V14.3714H4.56898C4.16003 14.3819 3.76359 14.2439 3.46892 13.9885C3.17425 13.733 3.00613 13.3817 3.00242 13.0135V9.72585H3.06017C3.96498 9.7274 4.83875 9.42904 5.5147 8.8877C6.93141 10 9.04313 10 10.4598 8.8877C11.1384 9.43365 12.0187 9.73247 12.9288 9.72585H13.1093Z"
                        fill="#9F9F9F"
                     />
                  </svg>
                  {cartItem?.__brand?.info?.name}
               </div>
            </div>
            {cartItem.items.map((productItem, i2) => {
               const isEnable =
                  isEnableBrand &&
                  productItem.__variant.options.stockStatus ===
                     EStockStatus.Stocking &&
                  productItem.__variant.options.status === EStatus.enable &&
                  productItem.__product?.options.status === EStatus.enable;

               const isSelected = checkProductVariantSelected(productItem._id);

               return (
                  <ProductItem
                     key={productItem._id + i2}
                     cartItem={cartItem}
                     productItem={productItem}
                     isEnable={isEnable}
                     isSelected={isSelected}
                     showTooltipDelete={state.showTooltipDelete}
                     onCheckOrderItem={onCheckOrderItem}
                     onUpdateItemCart={onUpdateItemCart}
                     handleToggleDialogDelete={handleToggleDialogDelete}
                     onRemoveProductFromCart={onRemoveProductFromCart}
                     toggleBookmarkProduct={toggleBookmarkProduct}
                  />
               );
            })}
            {/* <div className="shop-voucher">
                     <div className="d-flex align-items-center">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="14" viewBox="0 0 24 14" fill="none">
                           <path
                              d="M16.4895 0H0.740102C0.325645 0 0 0.340708 0 0.774336V3.31416C0 3.68584 0.266437 3.99558 0.592082 4.05752C1.92427 4.33628 2.8716 5.4823 2.8716 7C2.8716 8.5177 1.92427 9.66372 0.592082 9.94248C0.236833 10.0044 0 10.3142 0 10.6858V13.2257C0 13.6593 0.325645 14 0.740102 14H16.4895V0ZM6.69052 4.21239H12.4337C12.8482 4.21239 13.1738 4.5531 13.1738 4.98673C13.1738 5.42035 12.8482 5.76106 12.4337 5.76106H6.69052C6.27606 5.76106 5.95042 5.42035 5.95042 4.98673C5.95042 4.5531 6.27606 4.21239 6.69052 4.21239ZM12.4337 9.78761H6.69052C6.27606 9.78761 5.95042 9.4469 5.95042 9.01328C5.95042 8.57965 6.27606 8.23894 6.69052 8.23894H12.4337C12.8482 8.23894 13.1738 8.57965 13.1738 9.01328C13.1738 9.4469 12.8482 9.78761 12.4337 9.78761Z"
                              fill="#FF6347"
                           />
                           <path
                              d="M23.4072 9.94248C22.075 9.66372 21.1277 8.5177 21.1277 7C21.1277 5.4823 22.075 4.33628 23.4072 4.05752C23.7329 3.99558 23.9993 3.68584 23.9993 3.31416V0.774336C23.9993 0.340708 23.6737 0 23.2592 0H17.1016V14H23.2592C23.6737 14 23.9993 13.6593 23.9993 13.2257V10.6858C23.9993 10.3142 23.7625 10.0044 23.4072 9.94248Z"
                              fill="#8294A2"
                           />
                        </svg>
                     
                     </div>
                     <div className="d-flex align-items-center pointer">
                        <svg xmlns="http://www.w3.org/2000/svg" width="5" height="10" viewBox="0 0 5 10" fill="none">
                           <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M1.43827 8.80689C1.27679 8.97166 1.067 9.05469 0.842857 9.05469C0.701482 9.05469 0.556711 9.02202 0.422879 8.94449C0.295668 8.87245 0.192274 8.77003 0.119126 8.64392C0.034511 8.50641 -6.10689e-08 8.35614 -7.37551e-08 8.21103C-9.32091e-08 7.9885 0.0835236 7.78896 0.227733 7.63127L0.238017 7.62003L2.94617 4.99887L0.241705 2.37739L0.234979 2.37034C0.081108 2.2091 -6.16352e-07 2.00445 -6.35726e-07 1.78283C-6.48748e-07 1.63388 0.0375817 1.48758 0.116147 1.35511C0.190184 1.22543 0.297383 1.11964 0.432208 1.04845C0.563424 0.974749 0.704761 0.943577 0.842857 0.943577C1.06205 0.943577 1.27133 1.02159 1.43511 1.1839L4.73484 4.3858C4.81422 4.46037 4.88511 4.55311 4.93336 4.66598C4.98045 4.77609 5 4.88951 5 4.99913C5 5.1102 4.97885 5.22149 4.93336 5.32788C4.88511 5.44074 4.81423 5.53348 4.73485 5.60805L1.43827 8.80689Z"
                              fill="#9F9F9F"
                           />
                        </svg>
                     </div>
                  </div> */}
         </div>
         <Modal
            open={Boolean(state.tooltipRemoveId)}
            className={`model-confirm-delete-cart`}
            onClose={() => setState({ tooltipRemoveId: "" })}
         >
            <div className="modal-content">
               <div className="title">Xác nhận xoá?</div>
               <div className="des text-center">
                  Bạn có chắc <br /> muốn xoá sản phẩm này?
               </div>
               <div className="wrap-button">
                  <Button
                     className="btn btn-outline"
                     variant="bt-outline-primary"
                     onClick={() => setState({ tooltipRemoveId: "" })}
                  >
                     Huỷ
                  </Button>
                  <Button
                     className="btn btn-delete"
                     variant="bt-primary"
                     onClick={() => {
                        onRemoveProductFromCart(state.tooltipRemoveId || "");
                        setState({ tooltipRemoveId: "" });
                     }}
                  >
                     Xoá
                  </Button>
               </div>
            </div>
         </Modal>
         {/* {Boolean(state.dialogShopDiscount) && (
            <DialogShopDiscount
               visible={Boolean(state.dialogShopDiscount)}
               cartItem={cartItem}
               cartIds={orders?.filter((it) => it.__brand._id === cartItem.__brand._id).map((it) => it._id)}
               discountVoucherSelect={cartItem?.discount?.appliedVoucher}
               onClose={() => setState({ dialogShopDiscount: false })}
               onConfirm={(voucher) => {
                  setState({ dialogShopDiscount: false });
                  onConfirmSelectVoucher?.(cartItem, voucher);
               }}
            />
         )} */}
      </>
   );
}
export default CartByBrandItem;
