import classNames from "classnames";
import styles from "./index.module.scss";

type Props = {
   scale?: "small" | "medium" | "larger";
   type?: "primary" | "snippet";
};

const Loadingv2 = ({ scale = "medium", type = "primary" }: Props) => {
   if (type === "snippet") {
      return (
         <div
            className={classNames([styles["snippet"], styles[scale]])}
            data-title="dot-flashing"
         >
            <div className={styles["stage"]}>
               <div className={styles["dot-flashing"]}></div>
            </div>
         </div>
      );
   }

   return (
      <div
         className={classNames(
            styles["loading-customv2"],
            styles[type],
            styles[scale]
         )}
      >
         <img src="/assets/img/icon/loading-n.png" alt="" />
      </div>
   );
};

export default Loadingv2;
