/* eslint-plugin-disable react */
/*eslint-disable */

import { ActualWorkMedia } from "./actualWorkMedia.model";
import ActualWorkReaction, { IActualWorkReaction } from "./actualWorkReaction.model";
import InteriorStyle from "./interiorStyle.model";
import Keyword from "./keyword.model";

/**
 * Model(s)
 */
import { IModel, Model, ModelError, Paginate, TModelError, TPaginate, TPayload } from "./model";
import Partner from "./partner.model";
import { EReactionTypes } from "./reaction.model";
import UserBookmark, { IUserBookmark } from "./userBookmark.model";
import { apiDomain, tokenKey } from "./__variables";
import Location from "./location.model";
import User from "./user.model";

export enum EGroupIds {
   featured = "6155d2e3dff7654aaf8bb258",
}

export enum EActualWorkSortBy {
   id = "_id",
   title = "info.title",
   createdAt = "createdAt",
}

export enum EActualWorkStatus {
   enable = "enable",
   disable = "disable",
   delete = "delete",
}

export enum EActualWorkCompletionStatus {
   completed = "completed",
   concept = "concept",
}

export const enum EActualWorkConstructionTypes {
   apartment = "apartment",
   townhouse = "townhouse",
   villa = "villa",
   duplex = "duplex",
   other = "other",
}

export enum EActualWorkTypes {
   actualWork = "actualWork",
   designLibrary = "designLibrary",
}

export enum EActualWorkDesignTypes {
   unset = "unset",
   twoD = "twoD",
   threeD = "threeD",
   sketchUp = "sketchUp",
}

export interface IActualWork extends IModel {
   ["info"]?: {
      ["title"]?: string | null;
      ["description"]?: string | null;
      ["content"]?: string | null;
      ["slug"]?: string | null;
      ["acreage"]?: number | null;
      ["price"]?: number | null;
      ["imageFeatured"]?: string | null;
      ["typeOfDesign"]?: EActualWorkDesignTypes | null;
      ["typeOfConstruction"]?: EActualWorkConstructionTypes | null;
      ["completionStatus"]?: EActualWorkCompletionStatus | null;
      ["bookmarkCount"]?: number;
      ["viewCount"]?: number;
      ["fakeViewCount"]?: number;
   };
   ["options"]?: {
      ["status"]?: EActualWorkStatus | null;
      ["articleType"]?: EActualWorkTypes | null;
   };
   ["partnerId"]?: string | null;
   ["mediaId"]?: string | null;
   ["styleIds"]?: string[];
   ["keywordIds"]?: string[];
   ["groupIds"]?: string[];

   ["__user"]?: User | null;
   ["__partner"]?: Partner | null;
   ["__styles"]?: InteriorStyle[];
   ["__keywords"]?: Keyword[];
   // ["__groups"]?: ActualWorkGroup[];
   ["__bookmarkCount"]?: number;
   ["__media"]?: ActualWorkMedia;
   ["__medias"]?: ActualWorkMedia[];
   __location?: Location[];
}

export class ActualWork extends Model<IActualWork> implements IActualWork {
   public ["info"]!: {
      ["title"]: string | null;
      ["description"]: string | null;
      ["content"]: string | null;
      ["slug"]: string | null;
      ["acreage"]: number | null;
      ["price"]: number | null;
      ["imageFeatured"]: string | null;
      ["typeOfDesign"]: EActualWorkDesignTypes | null;
      ["typeOfConstruction"]: EActualWorkConstructionTypes | null;
      ["completionStatus"]: EActualWorkCompletionStatus | null;
      ["bookmarkCount"]: number;
      ["viewCount"]: number;
      ["fakeViewCount"]: number;
   };
   public ["options"]!: {
      ["status"]: EActualWorkStatus | null;
      ["articleType"]: EActualWorkTypes | null;
   };
   public ["partnerId"]!: string | null;
   public ["mediaId"]!: string | null;
   public ["styleIds"]!: string[];
   public ["keywordIds"]!: string[];
   public ["groupIds"]!: string[];

   public ["__user"]!: User | null;
   public ["__partner"]!: Partner | null;
   public ["__styles"]!: InteriorStyle[];
   public ["__keywords"]!: Keyword[];
   // ["__groups"]!: ActualWorkGroup[];
   public ["__bookmarkCount"]!: number;
   public ["__currentUserBookmark"]!: UserBookmark;
   public ["__media"]!: ActualWorkMedia;
   public ["__medias"]!: ActualWorkMedia[];
   public "__location"?: Location[];

   constructor(data) {
      super(data);

      Object.assign(this, data);

      if (this.__partner && !(this.__partner instanceof Partner)) {
         this.__partner = new Partner(this.__partner);
      }

      if (this.__media && !(this.__media instanceof ActualWorkMedia)) {
         this.__media = new ActualWorkMedia(this.__media);
      }

      if (this.__medias && this.__medias.every((media) => !(media instanceof ActualWorkMedia))) {
         this.__medias = this.__medias.map((media) => new ActualWorkMedia(media));
      }

      if (this.__styles && this.__styles.every((style) => !(style instanceof InteriorStyle))) {
         this.__styles = this.__styles.map((style) => new InteriorStyle(style));
      }

      if (this.__keywords && this.__keywords.every((keyword) => !(keyword instanceof Keyword))) {
         this.__keywords = this.__keywords.map((keyword) => new Keyword(keyword));
      }

      if (this.__location) {
         this.__location = this.__location?.map((location) => new Location(location));
      }
   }

   /**
    *
    * @param param0
    * @returns
    */
   public static async getPaginate({
      keywords,
      page,
      limit,
      sort,
      sortBy,
      fromPrice,
      toPrice,
      typeOfDesign,
      locationIds,
      typeOfConstruction,
      fromAcreage,
      toAcreage,
      interiorStyleIds,
      articleType,
      partnerId,
      groupIds,
      constructionTypeSlug,
      interiorStyleSlug,
      url,
      cookiesToken,
   }: {
      keywords?: string;
      page?: number;
      limit?: number;
      sort?: "asc" | "desc";
      sortBy?: "createdAt" | "info.title";
      fromPrice?: number;
      toPrice?: number;
      typeOfDesign?: EActualWorkDesignTypes;
      typeOfConstruction?: EActualWorkConstructionTypes;
      constructionTypeSlug?: string;
      interiorStyleSlug?: string;
      url?: string;
      fromAcreage?: number;
      toAcreage?: number;
      interiorStyleIds?: string; // Interior style ID
      articleType?: EActualWorkTypes; // No set is get all article ignore post type
      partnerId?: string;
      groupIds?: string;
      locationIds?: string;
      cookiesToken?: string;
   }) {
      try {
         const token = typeof window !== "undefined" && localStorage.getItem(tokenKey);
         const lastToken = cookiesToken || token;
         const response = await fetch(`${apiDomain}/www/actualWork/paginate?offcontent=true`, {
            ["method"]: "GET",
            ["cache"]: "no-store",
            ["headers"]: {
               ["Origin"]: "https://spacet.vn",
               ["X-Requested-With"]: "XMLHttpRequest",
               ["page"]: !page ? String(1) : String(page),
               ["limit"]: !limit ? String(50) : String(limit),
               ["sort"]: !sort ? "desc" : encodeURI(sort),
               ["sort-by"]: !sortBy ? "createdAt" : encodeURI(sortBy),
               ...(!lastToken ? undefined : { ["Authorization"]: `Bearer ${lastToken}` }),
               ...(!keywords ? undefined : { ["keywords"]: encodeURIComponent(keywords) }),
               ...(!fromPrice ? undefined : { ["from-price"]: String(fromPrice * 1000000) }),
               ...(!toPrice ? undefined : { ["to-price"]: String(toPrice * 1000000) }),
               ...(!typeOfConstruction
                  ? undefined
                  : {
                       ["construction-types"]: encodeURI(typeOfConstruction),
                    }),
               ...(!typeOfDesign ? undefined : { ["design-types"]: encodeURI(typeOfDesign) }),
               ...(!fromAcreage ? undefined : { ["from-acreage"]: String(fromAcreage) }),
               ...(!toAcreage ? undefined : { ["to-acreage"]: String(toAcreage) }),
               ...(!interiorStyleIds ? undefined : { ["interior-style-ids"]: encodeURI(interiorStyleIds) }),
               ...(!articleType ? undefined : { ["article-type"]: encodeURI(articleType) }),
               ...(!partnerId ? undefined : { ["partner-id"]: encodeURI(partnerId) }),
               ...(!groupIds ? undefined : { ["group-ids"]: encodeURI(groupIds) }),
               ...(!locationIds ? undefined : { ["location-slugs"]: encodeURI(locationIds) }),
               ...(!constructionTypeSlug
                  ? undefined
                  : {
                       ["construction-type-slugs"]: encodeURI(constructionTypeSlug),
                    }),
               ...(!interiorStyleSlug
                  ? undefined
                  : {
                       ["interior-style-slugs"]: encodeURI(interiorStyleSlug),
                    }),
               ...(!url ? undefined : { ["url"]: encodeURI(url) }),
            },
         });

         if (!response.ok) {
            return new ModelError((await response.json()) as TModelError);
         }

         const payload = (await response.json()) as TPayload<{
            ["paginator"]: TPaginate<IActualWork>;
            userBookmarks?: IUserBookmark[];
         }>;

         const paginate = new Paginate(payload?.data?.paginator, ActualWork);

         return { paginate, userBookmarks: payload.data?.userBookmarks ?? [] };
      } catch (error: any) {
         return new ModelError(error as TModelError);
      }
   }

   public static async getActualWorkBySlug(slug: string, type: EActualWorkTypes, cookiesToken?: string) {
      try {
         const token = typeof window !== "undefined" && localStorage.getItem(tokenKey);
         const lastToken = cookiesToken || token;
         const response = await fetch(`${apiDomain}/www/actualWork`, {
            ["method"]: "GET",
            ["cache"]: "no-store",
            ["headers"]: {
               ["Origin"]: "https://spacet.vn",
               ["X-Requested-With"]: "XMLHttpRequest",
               ...(!lastToken ? undefined : { ["Authorization"]: `Bearer ${lastToken}` }),
               ["slug"]: slug,
               ["type"]: encodeURI(type),
            },
         });

         if (!response.ok) {
            return new ModelError((await response.json()) as TModelError);
         }

         const payload = (await response.json()) as TPayload<{
            ["actualWork"]: IActualWork;
            ["bookmark"]: IUserBookmark;
            ["currentUserReactions"]: {
               [EReactionTypes.like]: IActualWorkReaction;
            };
         }>;

         return {
            ["actualWork"]: new ActualWork(payload.data.actualWork),
            ["bookmark"]: !payload.data.bookmark ? null : new UserBookmark(payload.data.bookmark),
            ["currentUserReactions"]: {
               [EReactionTypes.like]: payload.data?.currentUserReactions?.like ? new ActualWorkReaction(payload.data.currentUserReactions.like) : null,
            },
         };
      } catch (error: any) {}
   }

   public static async getAllSlugActualWork() {
      try {
         const response = await fetch(`${apiDomain}/www/general/actual-work-slugs`, {
            ["method"]: "GET",
            ["cache"]: "no-store",
            ["headers"]: {
               ["Origin"]: "https://spacet.vn",
               ["X-Requested-With"]: "XMLHttpRequest",
            },
         });
         if (!response.ok) {
            return new ModelError((await response.json()) as TModelError);
         }

         const payload = await response.json();

         const paginate = new Paginate(payload?.data, ActualWork);

         return paginate;
      } catch (error: any) {}
   }
   public static async getAllSlugDesignLibrary() {
      try {
         const response = await fetch(`${apiDomain}/www/general/design-library-slugs`, {
            ["method"]: "GET",
            ["cache"]: "no-store",
            ["headers"]: {
               ["Origin"]: "https://spacet.vn",
               ["X-Requested-With"]: "XMLHttpRequest",
            },
         });
         if (!response.ok) {
            return new ModelError((await response.json()) as TModelError);
         }

         const payload = await response.json();

         const paginate = new Paginate(payload?.data, ActualWork);

         return paginate;
      } catch (error: any) {}
   }

   public static async suggestions({
      id,
      offset,
      limit,
      type = EActualWorkTypes.actualWork,
      cookiesToken,
   }: {
      id: string;
      offset?: number;
      limit?: number;
      type?: EActualWorkTypes;
      cookiesToken?: string;
   }) {
      try {
         const token = typeof window !== "undefined" && localStorage.getItem(tokenKey);
         const lastToken = cookiesToken || token;
         const res = await Model.fetchData({
            method: "GET",
            endPoint: "actualwork/suggestions",
            headers: {
               id,
               "article-type": type,
               ...(!lastToken ? undefined : { ["Authorization"]: `Bearer ${lastToken}` }),
               ...(offset ? { offset: String(offset) } : { offset: "0" }),
               ...(limit ? { limit: String(limit) } : undefined),
            },
         });
         if (res instanceof ModelError) {
            return res;
         }
         const payload = (await (res as any).json()) as TPayload<{
            ["suggestion"]: TPaginate<ActualWork>;
            userBookmarks?: IUserBookmark[];
         }>;
         const paginate = new Paginate(payload?.data?.suggestion, ActualWork);

         return { paginate, userBookmarks: payload.data?.userBookmarks ?? [] };
      } catch (error) {
         return new ModelError(error);
      }
   }
}

export default ActualWork;
