/* eslint-disable @next/next/no-img-element */
import classNames from "classnames";
import Link from "next/link";
import { EActualWorkTypes } from "../../../models/actualWork.model";
import { ECategoryIds } from "../../../models/article.model";
import { ModelError } from "../../../models/model";
import UserNotifications, {
   EUserNotificationActions,
   EUserNotificationOpenTypes,
} from "../../../models/userNotifications.model";
import store from "../../../state";
import { verify } from "../../../state/action/auth";
import { formatNumber, getTimeFromNow } from "../../../utils";
import Avatar from "../../avatar";
import styles from "../styles.module.scss";
import NotificationReport from "./notificationReport/NotificationReport";
import { EBadge } from "models/user.model";
import { appDomain } from "models/__variables";

interface IProps {
   noti: UserNotifications;
   onClickItem?: (
      type: EUserNotificationOpenTypes,
      noti: UserNotifications
   ) => void;
   onChangeNotification?: (noti: UserNotifications) => void;
}

interface IAction {
   id?: string;
   action: EUserNotificationActions;
}

function DefaultNotiUI({
   noti,
   onClick,
}: {
   noti: UserNotifications;
   onClick: any;
}) {
   const { openSlug, openType, openId } = noti?.info || {};

   let redirectUrl: any = "";
   switch (noti?.info?.openType) {
      case EUserNotificationOpenTypes.homepage:
         redirectUrl = `${appDomain}`;
         break;
      case EUserNotificationOpenTypes.shopHome:
         redirectUrl = `${appDomain}/shop`;
         break;
      case EUserNotificationOpenTypes.shopCategory:
         redirectUrl = `${appDomain}/shop/${openSlug || ""}`;
         break;
      case EUserNotificationOpenTypes.flashSales:
         redirectUrl = `${appDomain}/shop/flash-sale`;
         break;
      case EUserNotificationOpenTypes.bestSeller:
         redirectUrl = `${appDomain}/shop/san-pham-ban-chay`;
         break;
      case EUserNotificationOpenTypes.newArrival:
         redirectUrl = `${appDomain}/shop/san-pham-moi`;
         break;
      case EUserNotificationOpenTypes.product:
         redirectUrl = `${appDomain}/shop/${openSlug || ""}`;
         break;
      case EUserNotificationOpenTypes.brandHome:
         redirectUrl = `${appDomain}/shop/thuong-hieu/${openSlug || ""}`;
         break;
      case EUserNotificationOpenTypes.feedHome:
         redirectUrl = `${appDomain}/feed`;
         break;
      case EUserNotificationOpenTypes.blogHome:
         redirectUrl = `${appDomain}/blog`;
         break;
      case EUserNotificationOpenTypes.libraryHome:
         redirectUrl = `${appDomain}/thu-vien-anh`;
         break;
      case EUserNotificationOpenTypes.designLibrary:
         redirectUrl = `${appDomain}/thu-vien-thiet-ke`;
         break;
      case EUserNotificationOpenTypes.actualWork:
         redirectUrl = `${appDomain}/cong-trinh-thuc-te`;
         break;
      case EUserNotificationOpenTypes.calculator:
         redirectUrl = `${appDomain}/bao-gia`;
         break;
      case EUserNotificationOpenTypes.interiorFirm:
         redirectUrl = `${appDomain}/cac-cong-ty-noi-that`;
         break;
      case EUserNotificationOpenTypes.feedPost:
         redirectUrl = `${appDomain}/post/${openId}`;
         break;
      default:
         break;
   }
   const TagEl = redirectUrl ? "a" : "div";
   return (
      <TagEl
         className={classNames([
            styles["noti-item"],
            noti?.options?.action !== EUserNotificationActions.seen &&
               styles["unread"],
         ])}
         onClick={onClick}
         {...(redirectUrl
            ? {
                 href: redirectUrl,
              }
            : {})}
      >
         <div className={styles["img"]}>
            <img src="/assets/img/cover/used-point.jpg" alt="" />
         </div>
         <div className={styles["text"]}>
            {noti?.info?.title && (
               <div
                  className={classNames([
                     styles["title"],
                     "fw-500 mb-1",
                     "truncate-line-2",
                  ])}
               >
                  {noti?.info?.title}
               </div>
            )}
            <div className={classNames([styles["title"], "truncate-line-3"])}>
               {noti.info.message}
            </div>
            <span className={styles["time"]}>
               {getTimeFromNow(noti.createdAt ?? "")}
            </span>
         </div>
      </TagEl>
   );
}

export default function Notification({
   noti,
   onChangeNotification,
   onClickItem,
}: IProps) {
   const handleSeen = async () => {
      onClickItem?.(noti.info.openType, noti);
      try {
         if (noti?.options?.action === EUserNotificationActions.seen) return;
         const seen = await UserNotifications.update({
            id: noti._id,
            action: EUserNotificationActions.seen,
         });
         if (seen instanceof ModelError) {
            throw Error(seen?.message);
         } else {
            store.dispatch(verify());
            // await this.componentDidMount();
         }
      } catch (error) {}
   };

   const getTypeMagazine = (categoryId: string): string => {
      switch (categoryId) {
         case ECategoryIds.interiorStyle:
            return "phong-cach-noi-that";
         case ECategoryIds.furnitureKnowledge:
            return "kien-thuc-noi-that";
         case ECategoryIds.inspiration:
            return "nguon-cam-hung";
         case ECategoryIds.news:
            return "tin-tuc";
         case ECategoryIds.lifeStyle:
            return "phong-cach-song";
         default:
            return "kien-thuc";
      }
   };

   switch (noti.info.openType) {
      case EUserNotificationOpenTypes.orderCreated:
         return (
            <Link
               href={`/tai-khoan/don-hang?orderId=${
                  noti?.__order?._id ?? ""
               }&type=order`}
               className={classNames([
                  styles["noti-item"],
                  noti?.options?.action !== EUserNotificationActions.seen &&
                     styles["unread"],
               ])}
               onClick={handleSeen}
            >
               <div className={styles["img"]}>
                  <img src={"/assets/img/cover/order-point.jpg"} alt="" />
               </div>
               <div className={styles["text"]}>
                  {noti?.info?.title && (
                     <div
                        className={classNames([
                           styles["title"],
                           "fw-500 mb-1",
                           "truncate-line-2",
                        ])}
                     >
                        {noti?.info?.title}
                     </div>
                  )}
                  <div
                     className={classNames([
                        styles["title"],
                        "truncate-line-3",
                     ])}
                  >
                     Đơn hàng{" "}
                     <span className={styles["highlight"]}>
                        #{noti?.__order?.xId ?? ""}
                     </span>{" "}
                     đã được đặt thành công
                  </div>
                  <span className={styles["time"]}>
                     {getTimeFromNow(noti.createdAt ?? "")}
                  </span>
               </div>
            </Link>
         );
      case EUserNotificationOpenTypes.orderConfirmed:
         return (
            <Link
               href={`/tai-khoan/don-hang?orderId=${
                  noti?.__order?._id ?? ""
               }&type=order`}
               className={classNames([
                  styles["noti-item"],
                  noti?.options?.action !== EUserNotificationActions.seen &&
                     styles["unread"],
               ])}
               onClick={handleSeen.bind({
                  id: noti._id,
                  action: EUserNotificationActions.seen,
               })}
            >
               <div className={styles["img"]}>
                  <img src={"/assets/img/cover/order-point.jpg"} alt="" />
               </div>
               <div className={styles["text"]}>
                  {noti?.info?.title && (
                     <div
                        className={classNames([
                           styles["title"],
                           "fw-500 mb-1",
                           "truncate-line-2",
                        ])}
                     >
                        {noti?.info?.title}
                     </div>
                  )}
                  <div
                     className={classNames([
                        styles["title"],
                        "truncate-line-3",
                     ])}
                  >
                     Đơn hàng{" "}
                     <span className={styles["highlight"]}>
                        #{noti?.__order?.xId ?? ""}
                     </span>{" "}
                     đã được xác nhận
                  </div>
                  <span className={styles["time"]}>
                     {getTimeFromNow(noti.createdAt ?? "")}
                  </span>
               </div>
            </Link>
         );
      case EUserNotificationOpenTypes.orderRefunded:
         return (
            <Link
               href={`/tai-khoan/don-hang?orderId=${
                  noti?.__order?._id ?? ""
               }&type=order`}
               className={classNames([
                  styles["noti-item"],
                  noti?.options?.action !== EUserNotificationActions.seen &&
                     styles["unread"],
               ])}
               onClick={handleSeen}
            >
               <div className={styles["img"]}>
                  <img src="/assets/img/cover/order-point.jpg" alt="" />
               </div>
               <div className={styles["text"]}>
                  {noti?.info?.title && (
                     <div
                        className={classNames([
                           styles["title"],
                           "fw-500 mb-1",
                           "truncate-line-2",
                        ])}
                     >
                        {noti?.info?.title}
                     </div>
                  )}
                  <div
                     className={classNames([
                        styles["title"],
                        "truncate-line-3",
                     ])}
                  >
                     Đơn hàng{" "}
                     <span className={styles["highlight"]}>
                        #{noti?.__order?.xId ?? ""}
                     </span>{" "}
                     đã được hoàn tiền
                  </div>
                  <span className={styles["time"]}>
                     {getTimeFromNow(noti.createdAt ?? "")}
                  </span>
               </div>
            </Link>
         );
      case EUserNotificationOpenTypes.orderCancelled:
         return (
            <Link
               href={`/tai-khoan/don-hang?orderId=${
                  noti?.__order?._id ?? ""
               }&type=order`}
               className={classNames([
                  styles["noti-item"],
                  noti?.options?.action !== EUserNotificationActions.seen &&
                     styles["unread"],
               ])}
               onClick={handleSeen}
            >
               <div className={styles["img"]}>
                  <img src={"/assets/img/cover/order-point.jpg"} alt="" />
               </div>
               <div className={styles["text"]}>
                  {noti?.info?.title && (
                     <div
                        className={classNames([
                           styles["title"],
                           "fw-500 mb-1",
                           "truncate-line-2",
                        ])}
                     >
                        {noti?.info?.title}
                     </div>
                  )}
                  <div
                     className={classNames([
                        styles["title"],
                        "truncate-line-3",
                     ])}
                  >
                     Đơn hàng{" "}
                     <span className={styles["highlight"]}>
                        #{noti?.__order?.xId ?? ""}
                     </span>{" "}
                     đã được huỷ
                  </div>
                  <span className={styles["time"]}>
                     {getTimeFromNow(noti.createdAt ?? "")}
                  </span>
               </div>
            </Link>
         );
      case EUserNotificationOpenTypes.orderSucceed:
         return (
            <Link
               href={`/tai-khoan/don-hang?orderId=${
                  noti?.__order?._id ?? ""
               }&type=order`}
               className={classNames([
                  styles["noti-item"],
                  noti?.options?.action !== EUserNotificationActions.seen &&
                     styles["unread"],
               ])}
               onClick={handleSeen}
            >
               <div className={styles["img"]}>
                  <img src={"/assets/img/cover/order-point.jpg"} alt="" />
               </div>
               <div className={styles["text"]}>
                  {noti?.info?.title && (
                     <div
                        className={classNames([
                           styles["title"],
                           "fw-500 mb-1",
                           "truncate-line-2",
                        ])}
                     >
                        {noti?.info?.title}
                     </div>
                  )}
                  <div
                     className={classNames([
                        styles["title"],
                        "truncate-line-3",
                     ])}
                  >
                     Đơn hàng{" "}
                     <span className={styles["highlight"]}>
                        #{noti?.__order?.xId ?? ""}
                     </span>{" "}
                     đã được giao thành công
                  </div>
                  <span className={styles["time"]}>
                     {getTimeFromNow(noti.createdAt ?? "")}
                  </span>
               </div>
            </Link>
         );
      case EUserNotificationOpenTypes.approveProductReview:
         return (
            <Link
               href="/tai-khoan/don-hang?type=product"
               className={classNames([
                  styles["noti-item"],
                  noti?.options?.action !== EUserNotificationActions.seen &&
                     styles["unread"],
               ])}
               onClick={handleSeen}
            >
               <div className={styles["img"]}>
                  <img src="/assets/img/cover/review-product.jpg" alt="" />
               </div>
               <div className={styles["text"]}>
                  {noti?.info?.title && (
                     <div
                        className={classNames([
                           styles["title"],
                           "fw-500 mb-1",
                           "truncate-line-2",
                        ])}
                     >
                        {noti?.info?.title}
                     </div>
                  )}
                  <div
                     className={classNames([
                        styles["title"],
                        "truncate-line-3",
                     ])}
                  >
                     Đánh giá sản phẩm của bạn đã được duyệt
                  </div>
                  <span className={styles["time"]}>
                     {getTimeFromNow(noti.createdAt ?? "")}
                  </span>
               </div>
            </Link>
         );
      case EUserNotificationOpenTypes.rejectProductReview:
         return (
            <Link
               href="/tai-khoan/don-hang?type=product"
               className={classNames([
                  styles["noti-item"],
                  noti?.options?.action !== EUserNotificationActions.seen &&
                     styles["unread"],
               ])}
               onClick={handleSeen}
            >
               <div className={styles["img"]}>
                  <img src="/assets/img/cover/review-product.jpg" alt="" />
               </div>
               <div className={styles["text"]}>
                  <div
                     className={classNames([
                        styles["title"],
                        "truncate-line-3",
                     ])}
                  >
                     Đánh giá sản phẩm của bạn không được duyệt bởi nội dung
                     không phù hợp
                  </div>
                  <span className={styles["time"]}>
                     {getTimeFromNow(noti.createdAt ?? "")}
                  </span>
               </div>
            </Link>
         );
      case EUserNotificationOpenTypes.approvePartnerReview:
         return (
            <div
               className={classNames([
                  styles["noti-item"],
                  noti?.options?.action !== EUserNotificationActions.seen &&
                     styles["unread"],
               ])}
               onClick={handleSeen}
            >
               <div className={styles["img"]}>
                  <img src="/assets/img/cover/review-partner.jpg" alt="" />
               </div>
               <div className={styles["text"]}>
                  {noti?.info?.title && (
                     <div
                        className={classNames([
                           styles["title"],
                           "fw-500 mb-1",
                           "truncate-line-2",
                        ])}
                     >
                        {noti?.info?.title}
                     </div>
                  )}
                  <div
                     className={classNames([
                        styles["title"],
                        "truncate-line-3",
                     ])}
                  >
                     Đánh giá công ty của bạn đã được duyệt
                  </div>
                  <span className={styles["time"]}>
                     {getTimeFromNow(noti.createdAt ?? "")}
                  </span>
               </div>
            </div>
         );
      case EUserNotificationOpenTypes.rejectPartnerReview:
         return (
            <div
               className={classNames([
                  styles["noti-item"],
                  noti?.options?.action !== EUserNotificationActions.seen &&
                     styles["unread"],
               ])}
               onClick={handleSeen}
            >
               <div className={styles["img"]}>
                  <img src="/assets/img/cover/review-partner.jpg" alt="" />
               </div>
               <div className={styles["text"]}>
                  <div
                     className={classNames([
                        styles["title"],
                        "truncate-line-3",
                     ])}
                  >
                     Đánh giá công ty của bạn không được duyệt bởi nội dung
                     không phù hợp
                  </div>
                  <span className={styles["time"]}>
                     {getTimeFromNow(noti.createdAt ?? "")}
                  </span>
               </div>
            </div>
         );
      case EUserNotificationOpenTypes.approveQnA:
         return (
            <Link
               href={`/hoi-dap/${noti?.info?.openId ?? ""}`}
               className={classNames([
                  styles["noti-item"],
                  noti?.options?.action !== EUserNotificationActions.seen &&
                     styles["unread"],
               ])}
               onClick={handleSeen}
            >
               <div className={styles["img"]}>
                  <img src="/assets/img/cover/qna-point.jpg" alt="" />
               </div>
               <div className={styles["text"]}>
                  {noti?.info?.title && (
                     <div
                        className={classNames([
                           styles["title"],
                           "fw-500 mb-1",
                           "truncate-line-2",
                        ])}
                     >
                        {noti?.info?.title}
                     </div>
                  )}
                  <div
                     className={classNames([
                        styles["title"],
                        "truncate-line-3",
                     ])}
                  >
                     Câu hỏi của bạn đã được duyệt
                  </div>
                  <span className={styles["time"]}>
                     {getTimeFromNow(noti.createdAt ?? "")}
                  </span>
               </div>
            </Link>
         );
      case EUserNotificationOpenTypes.rejectQnA:
         return (
            <div
               className={classNames([
                  styles["noti-item"],
                  noti?.options?.action !== EUserNotificationActions.seen &&
                     styles["unread"],
               ])}
               onClick={handleSeen}
            >
               <div className={styles["img"]}>
                  <img src="/assets/img/cover/qna-point.jpg" alt="" />
               </div>
               <div className={styles["text"]}>
                  {noti?.info?.title && (
                     <div
                        className={classNames([
                           styles["title"],
                           "fw-500 mb-1",
                           "truncate-line-2",
                        ])}
                     >
                        {noti?.info?.title}
                     </div>
                  )}
                  <div
                     className={classNames([
                        styles["title"],
                        "truncate-line-3",
                     ])}
                  >
                     Câu hỏi của bạn không được duyệt bởi nội dung không phù hợp
                  </div>
                  <span className={styles["time"]}>
                     {getTimeFromNow(noti.createdAt ?? "")}
                  </span>
               </div>
            </div>
         );
      case EUserNotificationOpenTypes.commentOnQnA:
         return (
            <Link
               href={`/hoi-dap/${noti?.__qnaComment?.qnaId ?? ""}`}
               className={classNames([
                  styles["noti-item"],
                  noti?.options?.action !== EUserNotificationActions.seen &&
                     styles["unread"],
               ])}
               onClick={handleSeen}
            >
               <div className={styles["img"]}>
                  <Avatar
                     url={
                        noti.__qnaComment?.__user?.__media?.$url ??
                        noti.__articleReaction?.__user?.__media?.$url
                     }
                     size={40}
                  />
               </div>
               <div className={styles["text"]}>
                  {noti?.info?.title && (
                     <div
                        className={classNames([
                           styles["title"],
                           "fw-500 mb-1",
                           "truncate-line-2",
                        ])}
                     >
                        {noti?.info?.title}
                     </div>
                  )}
                  <div
                     className={classNames([
                        styles["title"],
                        "truncate-line-3",
                     ])}
                  >
                     <span className={styles["highlight"]}>
                        {noti.__qnaComment?.__user?.publicInfo.nickname}
                     </span>{" "}
                     vừa bình luận câu hỏi của bạn: &#34;
                     {noti.__qnaComment?.__qna?.info.title}&#34;
                  </div>
                  <span className={styles["time"]}>
                     {getTimeFromNow(noti.createdAt ?? "")}
                  </span>
               </div>
            </Link>
         );
      case EUserNotificationOpenTypes.replyOnQnAComment:
         return (
            <Link
               href={`/hoi-dap/${noti?.__qnaComment?.qnaId ?? ""}`}
               className={classNames([
                  styles["noti-item"],
                  noti?.options?.action !== EUserNotificationActions.seen &&
                     styles["unread"],
               ])}
               onClick={handleSeen}
            >
               <div className={styles["img"]}>
                  <Avatar
                     url={noti.__qnaComment?.__user?.__media?.$url}
                     size={40}
                  />
               </div>
               <div className={styles["text"]}>
                  {noti?.info?.title && (
                     <div
                        className={classNames([
                           styles["title"],
                           "fw-500 mb-1",
                           "truncate-line-2",
                        ])}
                     >
                        {noti?.info?.title}
                     </div>
                  )}
                  <div
                     className={classNames([
                        styles["title"],
                        "truncate-line-3",
                     ])}
                  >
                     <span className={styles["highlight"]}>
                        {noti.__qnaComment?.__user?.publicInfo.nickname}
                     </span>{" "}
                     vừa trả lời bình luận của bạn ở Hỏi đáp
                  </div>
                  <span className={styles["time"]}>
                     {getTimeFromNow(noti.createdAt ?? "")}
                  </span>
               </div>
            </Link>
         );
      case EUserNotificationOpenTypes.reactionOnQnAComment:
         return (
            <Link
               href={`/hoi-dap/${noti?.__qnaComment?.qnaId ?? ""}`}
               className={classNames([
                  styles["noti-item"],
                  noti?.options?.action !== EUserNotificationActions.seen &&
                     styles["unread"],
               ])}
               onClick={handleSeen}
            >
               <div className={styles["img"]}>
                  <Avatar
                     url={noti.__qnaReaction?.__user?.__media?.$url}
                     size={40}
                  />
               </div>
               <div className={styles["text"]}>
                  {noti?.info?.title && (
                     <div
                        className={classNames([
                           styles["title"],
                           "fw-500 mb-1",
                           "truncate-line-2",
                        ])}
                     >
                        {noti?.info?.title}
                     </div>
                  )}
                  <div
                     className={classNames([
                        styles["title"],
                        "truncate-line-3",
                     ])}
                  >
                     <span className={styles["highlight"]}>
                        {noti.__qnaReaction?.__user?.publicInfo.nickname}
                     </span>{" "}
                     vừa thích bình luận của bạn ở Hỏi đáp
                  </div>
                  <span className={styles["time"]}>
                     {getTimeFromNow(noti.createdAt ?? "")}
                  </span>
               </div>
            </Link>
         );
      case EUserNotificationOpenTypes.approveCommunityPost:
         return (
            <Link
               href={`/to-am-cua-toi/${
                  noti?.__communityPost?.info?.slug ?? ""
               }`}
               className={classNames([
                  styles["noti-item"],
                  noti?.options?.action !== EUserNotificationActions.seen &&
                     styles["unread"],
               ])}
               onClick={handleSeen}
            >
               <div className={styles["img"]}>
                  <img src={"/assets/img/cover/used-point.jpg"} alt="" />
               </div>
               <div className={styles["text"]}>
                  {noti?.info?.title && (
                     <div
                        className={classNames([
                           styles["title"],
                           "fw-500 mb-1",
                           "truncate-line-2",
                        ])}
                     >
                        {noti?.info?.title}
                     </div>
                  )}
                  <div
                     className={classNames([
                        styles["title"],
                        "truncate-line-3",
                     ])}
                  >
                     Bài đăng của bạn đã được duyệt
                  </div>
                  <span className={styles["time"]}>
                     {getTimeFromNow(noti.createdAt ?? "")}
                  </span>
               </div>
            </Link>
         );
      case EUserNotificationOpenTypes.rejectCommunityPost:
         return (
            <div
               className={classNames([
                  styles["noti-item"],
                  noti?.options?.action !== EUserNotificationActions.seen &&
                     styles["unread"],
               ])}
               onClick={handleSeen}
            >
               <div className={styles["img"]}>
                  <img src="/assets/img/cover/qna-point.jpg" alt="" />
               </div>
               <div className={styles["text"]}>
                  {noti?.info?.title && (
                     <div
                        className={classNames([
                           styles["title"],
                           "fw-500 mb-1",
                           "truncate-line-2",
                        ])}
                     >
                        {noti?.info?.title}
                     </div>
                  )}
                  <div
                     className={classNames([
                        styles["title"],
                        "truncate-line-3",
                     ])}
                  >
                     Bài đăng của bạn không được duyệt bởi nội dung không phù
                     hợp
                  </div>
                  <span className={styles["time"]}>
                     {getTimeFromNow(noti.createdAt ?? "")}
                  </span>
               </div>
            </div>
         );
      case EUserNotificationOpenTypes.commentOnCommunityPost:
         return (
            <Link
               href={`/to-am-cua-toi/${
                  noti.__communityPostComment?.__communityPost?.info.slug ?? ""
               }`}
               className={classNames([
                  styles["noti-item"],
                  noti?.options?.action !== EUserNotificationActions.seen &&
                     styles["unread"],
               ])}
               onClick={handleSeen}
            >
               <div className={styles["img"]}>
                  <Avatar
                     url={
                        noti.__communityPostComment?.__user?.__media?.$url ??
                        noti.__communityPostComment?.__user?.__media?.$url
                     }
                     size={40}
                  />
               </div>
               <div className={styles["text"]}>
                  {noti?.info?.title && (
                     <div
                        className={classNames([
                           styles["title"],
                           "fw-500 mb-1",
                           "truncate-line-2",
                        ])}
                     >
                        {noti?.info?.title}
                     </div>
                  )}
                  <div
                     className={classNames([
                        styles["title"],
                        "truncate-line-3",
                     ])}
                  >
                     <span className={styles["highlight"]}>
                        {
                           noti.__communityPostComment?.__user?.publicInfo
                              .nickname
                        }
                     </span>{" "}
                     vừa bình luận bài đăng Tổ ấm của tôi của bạn: &#34;
                     {noti.__communityPostComment?.__communityPost?.info.title}
                     &#34;
                  </div>
                  <span className={styles["time"]}>
                     {getTimeFromNow(noti.createdAt ?? "")}
                  </span>
               </div>
            </Link>
         );
      case EUserNotificationOpenTypes.reactionOnCommunityPostComment:
         return (
            <Link
               href={`/to-am-cua-toi/${
                  noti.__communityReaction?.__communityPost?.info.slug ?? ""
               }`}
               className={classNames([
                  styles["noti-item"],
                  noti?.options?.action !== EUserNotificationActions.seen &&
                     styles["unread"],
               ])}
               onClick={handleSeen}
            >
               <div className={styles["img"]}>
                  <Avatar
                     url={
                        noti.__communityReaction?.__user?.__media?.$url ??
                        noti.__communityReaction?.__user?.__media?.$url
                     }
                     size={40}
                  />
               </div>
               <div className={styles["text"]}>
                  {noti?.info?.title && (
                     <div
                        className={classNames([
                           styles["title"],
                           "fw-500 mb-1",
                           "truncate-line-2",
                        ])}
                     >
                        {noti?.info?.title}
                     </div>
                  )}
                  <div
                     className={classNames([
                        styles["title"],
                        "truncate-line-3",
                     ])}
                  >
                     <span className={styles["highlight"]}>
                        {noti.__communityReaction?.__user?.publicInfo.nickname}
                     </span>{" "}
                     vừa thích bình luận của bạn ở bài đăng Tổ ấm của tôi
                  </div>
                  <span className={styles["time"]}>
                     {getTimeFromNow(noti.createdAt ?? "")}
                  </span>
               </div>
            </Link>
         );
      case EUserNotificationOpenTypes.replyOnCommunityPostComment:
         return (
            <Link
               href={`/to-am-cua-toi/${
                  noti.__communityPostComment?.__communityPost?.info.slug ?? ""
               }`}
               className={classNames([
                  styles["noti-item"],
                  noti?.options?.action !== EUserNotificationActions.seen &&
                     styles["unread"],
               ])}
               onClick={handleSeen}
            >
               <div className={styles["img"]}>
                  <Avatar
                     url={
                        noti.__communityPostComment?.__user?.__media?.$url ??
                        noti.__communityPostComment?.__user?.__media?.$url
                     }
                     size={40}
                  />
               </div>
               <div className={styles["text"]}>
                  {noti?.info?.title && (
                     <div
                        className={classNames([
                           styles["title"],
                           "fw-500 mb-1",
                           "truncate-line-2",
                        ])}
                     >
                        {noti?.info?.title}
                     </div>
                  )}
                  <div
                     className={classNames([
                        styles["title"],
                        "truncate-line-3",
                     ])}
                  >
                     <span className={styles["highlight"]}>
                        {
                           noti.__communityPostComment?.__user?.publicInfo
                              .nickname
                        }
                     </span>{" "}
                     vừa trả lời bình luận của bạn ở bài đăng Tổ ấm của tôi
                  </div>
                  <span className={styles["time"]}>
                     {getTimeFromNow(noti.createdAt ?? "")}
                  </span>
               </div>
            </Link>
         );
      case EUserNotificationOpenTypes.reactionOnCommunityPost:
         return (
            <Link
               href={`/to-am-cua-toi/${
                  noti.__communityReaction?.__communityPost?.info.slug ?? ""
               }`}
               className={classNames([
                  styles["noti-item"],
                  noti?.options?.action !== EUserNotificationActions.seen &&
                     styles["unread"],
               ])}
               onClick={handleSeen}
            >
               <div className={styles["img"]}>
                  <Avatar
                     url={
                        noti.__communityReaction?.__user?.__media?.$url ??
                        noti.__communityReaction?.__user?.__media?.$url
                     }
                     size={40}
                  />
               </div>
               <div className={styles["text"]}>
                  {noti?.info?.title && (
                     <div
                        className={classNames([
                           styles["title"],
                           "fw-500 mb-1",
                           "truncate-line-2",
                        ])}
                     >
                        {noti?.info?.title}
                     </div>
                  )}
                  <div
                     className={classNames([
                        styles["title"],
                        "truncate-line-3",
                     ])}
                  >
                     <span className={styles["highlight"]}>
                        {noti.__communityReaction?.__user?.publicInfo.nickname}
                     </span>{" "}
                     vừa thích bài đăng Tổ ấm của tôi của bạn: &#34;
                     {noti.__communityReaction?.__communityPost?.info.title}
                     &#34;
                  </div>
                  <span className={styles["time"]}>
                     {getTimeFromNow(noti.createdAt ?? "")}
                  </span>
               </div>
            </Link>
         );
      case EUserNotificationOpenTypes.pointOfReviewProduct:
         return (
            <Link
               href="/tai-khoan/don-hang?type=coin"
               className={classNames([
                  styles["noti-item"],
                  noti?.options?.action !== EUserNotificationActions.seen &&
                     styles["unread"],
               ])}
               onClick={handleSeen}
            >
               <div className={styles["img"]}>
                  <img src="/assets/img/cover/used-point.jpg" alt="" />
               </div>
               <div className={styles["text"]}>
                  {noti?.info?.title && (
                     <div
                        className={classNames([
                           styles["title"],
                           "fw-500 mb-1",
                           "truncate-line-2",
                        ])}
                     >
                        {noti?.info?.title}
                     </div>
                  )}
                  <div
                     className={classNames([
                        styles["title"],
                        "truncate-line-3",
                     ])}
                  >
                     Bạn vừa nhận được{" "}
                     {formatNumber(noti?.__userPoint?.info?.value ?? 0)} Xu cho
                     đánh giá sản phẩm{" "}
                     <span className={styles["highlight"]}>
                        {noti?.__userPoint?.__productReview?.__productVariant
                           ?.__product?.info?.title ?? ""}
                     </span>
                  </div>
                  <span className={styles["time"]}>
                     {getTimeFromNow(noti.createdAt ?? "")}
                  </span>
               </div>
            </Link>
         );

      case EUserNotificationOpenTypes.pointOfReviewPartner:
         return (
            <Link
               href="/tai-khoan/don-hang?type=coin"
               className={classNames([
                  styles["noti-item"],
                  noti?.options?.action !== EUserNotificationActions.seen &&
                     styles["unread"],
               ])}
               onClick={handleSeen}
            >
               <div className={styles["img"]}>
                  <img src="/assets/img/cover/used-point.jpg" alt="" />
               </div>
               <div className={styles["text"]}>
                  {noti?.info?.title && (
                     <div
                        className={classNames([
                           styles["title"],
                           "fw-500 mb-1",
                           "truncate-line-2",
                        ])}
                     >
                        {noti?.info?.title}
                     </div>
                  )}
                  <div
                     className={classNames([
                        styles["title"],
                        "truncate-line-3",
                     ])}
                  >
                     Bạn vừa nhận được{" "}
                     {formatNumber(noti?.__userPoint?.info?.value ?? 0)} Xu cho
                     đánh giá đối tác{" "}
                     <span className={styles["highlight"]}>
                        {noti?.__userPoint?.__partnerReview?.__partner?.info
                           ?.name ?? ""}
                     </span>
                  </div>
                  <span className={styles["time"]}>
                     {getTimeFromNow(noti.createdAt ?? "")}
                  </span>
               </div>
            </Link>
         );
      case EUserNotificationOpenTypes.pointOfReviewPartnerViaSpaceT:
         return (
            <Link
               href="/tai-khoan/don-hang?type=coin"
               className={classNames([
                  styles["noti-item"],
                  noti?.options?.action !== EUserNotificationActions.seen &&
                     styles["unread"],
               ])}
               onClick={handleSeen}
            >
               <div className={styles["img"]}>
                  <img src="/assets/img/cover/used-point.jpg" alt="" />
               </div>
               <div className={styles["text"]}>
                  {noti?.info?.title && (
                     <div
                        className={classNames([
                           styles["title"],
                           "fw-500 mb-1",
                           "truncate-line-2",
                        ])}
                     >
                        {noti?.info?.title}
                     </div>
                  )}
                  <div
                     className={classNames([
                        styles["title"],
                        "truncate-line-3",
                     ])}
                  >
                     Bạn vừa nhận được{" "}
                     {formatNumber(noti?.__userPoint?.info?.value ?? 0)} Xu cho
                     đánh giá đối tác đã thực hiện công trình cho bạn{" "}
                     <span className={styles["highlight"]}>
                        {noti?.__userPoint?.__partnerReview?.__partner?.info
                           ?.name ?? ""}
                     </span>
                  </div>
                  <span className={styles["time"]}>
                     {getTimeFromNow(noti.createdAt ?? "")}
                  </span>
               </div>
            </Link>
         );
      case EUserNotificationOpenTypes.pointOfRegistration:
         return (
            <Link
               href="/tai-khoan/don-hang?type=coin"
               className={classNames([
                  styles["noti-item"],
                  noti?.options?.action !== EUserNotificationActions.seen &&
                     styles["unread"],
               ])}
               onClick={handleSeen}
            >
               <div className={styles["img"]}>
                  <img src="/assets/img/cover/used-point.jpg" alt="" />
               </div>
               <div className={styles["text"]}>
                  {noti?.info?.title && (
                     <div
                        className={classNames([
                           styles["title"],
                           "fw-500 mb-1",
                           "truncate-line-2",
                        ])}
                     >
                        {noti?.info?.title}
                     </div>
                  )}
                  <div
                     className={classNames([
                        styles["title"],
                        "truncate-line-3",
                     ])}
                  >
                     Bạn vừa nhận được{" "}
                     {formatNumber(noti?.__userPoint?.info?.value ?? 0)} Xu cho{" "}
                     <span className={styles["highlight"]}>
                        đăng kí thành công
                     </span>
                  </div>
                  <span className={styles["time"]}>
                     {getTimeFromNow(noti.createdAt ?? "")}
                  </span>
               </div>
            </Link>
         );
      case EUserNotificationOpenTypes.pointOfQnAComment:
         return (
            <Link
               href="/tai-khoan/don-hang?type=coin"
               className={classNames([
                  styles["noti-item"],
                  noti?.options?.action !== EUserNotificationActions.seen &&
                     styles["unread"],
               ])}
               onClick={handleSeen}
            >
               <div className={styles["img"]}>
                  <img src="/assets/img/cover/used-point.jpg" alt="" />
               </div>
               <div className={styles["text"]}>
                  <div
                     className={classNames([
                        styles["title"],
                        "truncate-line-3",
                     ])}
                  >
                     Bạn vừa nhận được{" "}
                     {formatNumber(noti?.__userPoint?.info?.value ?? 0)} Xu cho
                     trả lời câu hỏi
                  </div>
                  <span className={styles["time"]}>
                     {getTimeFromNow(noti.createdAt ?? "")}
                  </span>
               </div>
            </Link>
         );
      case EUserNotificationOpenTypes.pointOfOrderCancelled:
         return (
            <Link
               href="/tai-khoan/don-hang?type=coin"
               className={classNames([
                  styles["noti-item"],
                  noti?.options?.action !== EUserNotificationActions.seen &&
                     styles["unread"],
               ])}
               onClick={handleSeen}
            >
               <div className={styles["img"]}>
                  <img src="/assets/img/cover/used-point.jpg" alt="" />
               </div>
               <div className={styles["text"]}>
                  {noti?.info?.title && (
                     <div
                        className={classNames([
                           styles["title"],
                           "fw-500 mb-1",
                           "truncate-line-2",
                        ])}
                     >
                        {noti?.info?.title}
                     </div>
                  )}
                  <div
                     className={classNames([
                        styles["title"],
                        "truncate-line-3",
                     ])}
                  >
                     {" "}
                     Bạn vừa được hoàn lại{" "}
                     <span className={styles["highlight"]}>
                        {formatNumber(noti?.__userPoint?.info?.value ?? 0)} điểm
                     </span>
                  </div>
                  <span className={styles["time"]}>
                     {getTimeFromNow(noti.createdAt ?? "")}
                  </span>
               </div>
            </Link>
         );
      case EUserNotificationOpenTypes.pointOfOrderSucceed:
         return (
            <Link
               href="/tai-khoan/don-hang?type=coin"
               className={classNames([
                  styles["noti-item"],
                  noti?.options?.action !== EUserNotificationActions.seen &&
                     styles["unread"],
               ])}
               onClick={handleSeen}
            >
               <div className={styles["img"]}>
                  <img src="/assets/img/cover/used-point.jpg" alt="" />
               </div>
               <div className={styles["text"]}>
                  {noti?.info?.title && (
                     <div
                        className={classNames([
                           styles["title"],
                           "fw-500 mb-1",
                           "truncate-line-2",
                        ])}
                     >
                        {noti?.info?.title}
                     </div>
                  )}
                  <div
                     className={classNames([
                        styles["title"],
                        "truncate-line-3",
                     ])}
                  >
                     Bạn vừa nhận được{" "}
                     {formatNumber(noti?.__userPoint?.info?.value ?? 0)} Xu cho
                     đơn hàng{" "}
                     <span className={styles["highlight"]}>
                        #{noti?.__userPoint?.__order?.xId ?? ""}
                     </span>
                  </div>
                  <span className={styles["time"]}>
                     {getTimeFromNow(noti.createdAt ?? "")}
                  </span>
               </div>
            </Link>
         );
      case EUserNotificationOpenTypes.pointOfCommunityPost:
         return (
            <Link
               href="/tai-khoan/don-hang?type=coin"
               className={classNames([
                  styles["noti-item"],
                  noti?.options?.action !== EUserNotificationActions.seen &&
                     styles["unread"],
               ])}
               onClick={handleSeen}
            >
               <div className={styles["img"]}>
                  <img src="/assets/img/cover/used-point.jpg" alt="" />
               </div>
               <div className={styles["text"]}>
                  {noti?.info?.title && (
                     <div
                        className={classNames([
                           styles["title"],
                           "fw-500 mb-1",
                           "truncate-line-2",
                        ])}
                     >
                        {noti?.info?.title}
                     </div>
                  )}
                  <div
                     className={classNames([
                        styles["title"],
                        "truncate-line-3",
                     ])}
                  >
                     Bạn vừa nhận được{" "}
                     {formatNumber(noti?.__userPoint?.info?.value ?? 0)} Xu cho
                     bài đăng{" "}
                     <span className={styles["highlight"]}>
                        {noti?.__userPoint?.__communityPost?.info?.title ?? ""}
                     </span>
                  </div>
                  <span className={styles["time"]}>
                     {getTimeFromNow(noti.createdAt ?? "")}
                  </span>
               </div>
            </Link>
         );
      case EUserNotificationOpenTypes.pointOfSocialFeed:
         return (
            <Link
               href="/tai-khoan/don-hang?type=coin"
               className={classNames([
                  styles["noti-item"],
                  noti?.options?.action !== EUserNotificationActions.seen &&
                     styles["unread"],
               ])}
               onClick={handleSeen}
            >
               <div className={styles["img"]}>
                  <img src="/assets/img/cover/used-point.jpg" alt="" />
               </div>
               <div className={styles["text"]}>
                  {noti?.info?.title && (
                     <div
                        className={classNames([
                           styles["title"],
                           "fw-500 mb-1",
                           "truncate-line-2",
                        ])}
                     >
                        {noti?.info?.title}
                     </div>
                  )}
                  <div
                     className={classNames([
                        styles["title"],
                        "truncate-line-3",
                     ])}
                  >
                     Bạn vừa nhận được{" "}
                     {formatNumber(noti?.__userPoint?.info?.value ?? 0)} Xu cho
                     bài đăng{" "}
                     <span className={styles["highlight"]}>
                        {noti?.__userPoint?.__communityPost?.info?.title ?? ""}
                     </span>
                  </div>
                  <span className={styles["time"]}>
                     {getTimeFromNow(noti.createdAt ?? "")}
                  </span>
               </div>
            </Link>
         );
      case EUserNotificationOpenTypes.commentOnSocialFeed:
         return (
            <Link
               href={`/feed/${noti.__socialFeed?._id}`}
               className={classNames([
                  styles["noti-item"],
                  noti?.options?.action !== EUserNotificationActions.seen &&
                     styles["unread"],
               ])}
               onClick={handleSeen}
            >
               <div className={styles["img"]}>
                  <img src="/assets/img/cover/user.svg" alt="" />
               </div>
               <div className={styles["text"]}>
                  {noti?.info?.title && (
                     <div
                        className={classNames([
                           styles["title"],
                           "fw-500 mb-1",
                           "truncate-line-2",
                        ])}
                     >
                        {noti?.info?.title}
                     </div>
                  )}
                  <div
                     className={classNames([
                        styles["title"],
                        "truncate-line-3",
                     ])}
                  >
                     Bạn vừa nhận được một Bình luận bài đăng Social Feed
                  </div>
                  <span className={styles["time"]}>
                     {getTimeFromNow(noti.createdAt ?? "")}
                  </span>
               </div>
            </Link>
         );
      case EUserNotificationOpenTypes.replyOnSocialFeedComment:
         return (
            <Link
               href={`/feed/${noti.__socialFeedComment?.__socialFeed?._id}`}
               className={classNames([
                  styles["noti-item"],
                  noti?.options?.action !== EUserNotificationActions.seen &&
                     styles["unread"],
               ])}
               onClick={handleSeen}
            >
               <div className={styles["img"]}>
                  <img src="/assets/img/cover/user.svg" alt="" />
               </div>
               <div className={styles["text"]}>
                  {noti?.info?.title && (
                     <div
                        className={classNames([
                           styles["title"],
                           "fw-500 mb-1",
                           "truncate-line-2",
                        ])}
                     >
                        {noti?.info?.title}
                     </div>
                  )}
                  <div
                     className={classNames([
                        styles["title"],
                        "truncate-line-3",
                     ])}
                  >
                     Ai đó vừa trả lời bình luận của bạn trên Social Feed
                  </div>
                  <span className={styles["time"]}>
                     {getTimeFromNow(noti.createdAt ?? "")}
                  </span>
               </div>
            </Link>
         );
      case EUserNotificationOpenTypes.reactionOnSocialFeed:
         return (
            <Link
               href={`/feed/${noti.__socialFeed?._id}`}
               className={classNames([
                  styles["noti-item"],
                  noti?.options?.action !== EUserNotificationActions.seen &&
                     styles["unread"],
               ])}
               onClick={handleSeen}
            >
               <div className={styles["img"]}>
                  <img src="/assets/img/cover/user.svg" alt="" />
               </div>
               <div className={styles["text"]}>
                  {noti?.info?.title && (
                     <div
                        className={classNames([
                           styles["title"],
                           "fw-500 mb-1",
                           "truncate-line-2",
                        ])}
                     >
                        {noti?.info?.title}
                     </div>
                  )}
                  <div
                     className={classNames([
                        styles["title"],
                        "truncate-line-3",
                     ])}
                  >
                     Bạn vừa nhận được một lượt Thích bài đăng Social Feed
                  </div>
                  <span className={styles["time"]}>
                     {getTimeFromNow(noti.createdAt ?? "")}
                  </span>
               </div>
            </Link>
         );
      case EUserNotificationOpenTypes.reactionOnSocialFeedComment:
         return (
            <Link
               href={`/feed/${noti.__socialFeedComment?.__socialFeed?._id}`}
               className={classNames([
                  styles["noti-item"],
                  noti?.options?.action !== EUserNotificationActions.seen &&
                     styles["unread"],
               ])}
               onClick={handleSeen}
            >
               <div className={styles["img"]}>
                  <img src="/assets/img/cover/user.svg" alt="" />
               </div>
               <div className={styles["text"]}>
                  {noti?.info?.title && (
                     <div
                        className={classNames([
                           styles["title"],
                           "fw-500 mb-1",
                           "truncate-line-2",
                        ])}
                     >
                        {noti?.info?.title}
                     </div>
                  )}
                  <div
                     className={classNames([
                        styles["title"],
                        "truncate-line-3",
                     ])}
                  >
                     Bạn vừa nhận được một lượt Thích cho bình luận trên Social
                     Feed
                  </div>
                  <span className={styles["time"]}>
                     {getTimeFromNow(noti.createdAt ?? "")}
                  </span>
               </div>
            </Link>
         );
      case EUserNotificationOpenTypes.newSocialFeedFromSubscribingUser:
         return (
            <Link
               href={`/feed/${noti.__socialFeed?._id}`}
               className={classNames([
                  styles["noti-item"],
                  noti?.options?.action !== EUserNotificationActions.seen &&
                     styles["unread"],
               ])}
               onClick={handleSeen}
            >
               <div className={styles["img"]}>
                  <Avatar
                     url={noti.__socialFeed?.ownedBy?.doc?.__media?.$url}
                     size={40}
                  />
               </div>
               <div className={styles["text"]}>
                  {noti?.info?.title && (
                     <div
                        className={classNames([
                           styles["title"],
                           "fw-500 mb-1",
                           "truncate-line-2",
                        ])}
                     >
                        {noti?.info?.title}
                     </div>
                  )}
                  <div
                     className={classNames([
                        styles["title"],
                        "truncate-line-3",
                     ])}
                  >
                     <span className={styles["highlight"]}>
                        {noti.__socialFeed?.ownedBy?.doc?.publicInfo?.nickname}
                     </span>{" "}
                     vừa tạo bài đăng mới
                  </div>
                  <span className={styles["time"]}>
                     {getTimeFromNow(noti.createdAt ?? "")}
                  </span>
               </div>
            </Link>
         );
      case EUserNotificationOpenTypes.receiptSubscriptionFromOneUser:
         return (
            <Link
               href={`/tai-khoan/${noti.__userSubscribe?.__user?._id}`}
               className={classNames([
                  styles["noti-item"],
                  noti?.options?.action !== EUserNotificationActions.seen &&
                     styles["unread"],
               ])}
               onClick={handleSeen}
            >
               <div className={styles["img"]}>
                  <Avatar
                     url={noti.__userSubscribe?.__user?.__media?.$url}
                     size={40}
                  />
               </div>
               <div className={styles["text"]}>
                  {noti?.info?.title && (
                     <div
                        className={classNames([
                           styles["title"],
                           "fw-500 mb-1",
                           "truncate-line-2",
                        ])}
                     >
                        {noti?.info?.title}
                     </div>
                  )}
                  <div
                     className={classNames([
                        styles["title"],
                        "truncate-line-3",
                     ])}
                  >
                     <span className={styles["highlight"]}>
                        {noti.__userSubscribe?.__user?.publicInfo.nickname}
                     </span>{" "}
                     bắt đầu theo dõi bạn
                  </div>
                  <span className={styles["time"]}>
                     {getTimeFromNow(noti.createdAt ?? "")}
                  </span>
               </div>
            </Link>
         );

      //new notification social feed
      case EUserNotificationOpenTypes.newReactionOnSocialFeedByUser:
         return (
            <Link
               href={`/feed/${noti.__socialFeedReaction?.__socialFeed?._id}`}
               className={classNames([
                  styles["noti-item"],
                  noti?.options?.action !== EUserNotificationActions.seen &&
                     styles["unread"],
               ])}
               onClick={handleSeen}
            >
               <div className={styles["img"]}>
                  <Avatar
                     url={noti.__socialFeedReaction?.__user?.__media?.$url}
                     size={40}
                  />
               </div>
               <div className={styles["text"]}>
                  {noti?.info?.title && (
                     <div
                        className={classNames([
                           styles["title"],
                           "fw-500 mb-1",
                           "truncate-line-2",
                        ])}
                     >
                        {noti?.info?.title}
                     </div>
                  )}
                  <div
                     className={classNames([
                        styles["title"],
                        "truncate-line-3",
                     ])}
                  >
                     <span className={styles["highlight"]}>
                        {noti.__socialFeedReaction?.__user?.publicInfo.nickname}
                     </span>{" "}
                     vừa thích bài đăng của bạn: &#34;
                     {
                        noti.__socialFeedReaction?.__socialFeed?.info
                           ?.description
                     }
                     &#34;
                  </div>
                  <span className={styles["time"]}>
                     {getTimeFromNow(noti.createdAt ?? "")}
                  </span>
               </div>
            </Link>
         );
      case EUserNotificationOpenTypes.newReactionOnSocialFeedCommentByUser:
         return (
            <Link
               href={`/feed/${noti.__socialFeedReaction?.__socialFeed?._id}`}
               className={classNames([
                  styles["noti-item"],
                  noti?.options?.action !== EUserNotificationActions.seen &&
                     styles["unread"],
               ])}
               onClick={handleSeen}
            >
               <div className={styles["img"]}>
                  <Avatar
                     url={noti.__socialFeedReaction?.__user?.__media?.$url}
                     size={40}
                  />
               </div>
               <div className={styles["text"]}>
                  {noti?.info?.title && (
                     <div
                        className={classNames([
                           styles["title"],
                           "fw-500 mb-1",
                           "truncate-line-2",
                        ])}
                     >
                        {noti?.info?.title}
                     </div>
                  )}
                  <div
                     className={classNames([
                        styles["title"],
                        "truncate-line-3",
                     ])}
                  >
                     <span className={styles["highlight"]}>
                        {
                           noti?.__socialFeedReaction?.__user?.publicInfo
                              .nickname
                        }
                     </span>{" "}
                     vừa thích bình luận của bạn: &#34;
                     {
                        noti.__socialFeedReaction?.__reactionForComment?.info
                           ?.content
                     }
                     &#34;
                  </div>
                  <span className={styles["time"]}>
                     {getTimeFromNow(noti.createdAt ?? "")}
                  </span>
               </div>
            </Link>
         );
      case EUserNotificationOpenTypes.newCommentOnSocialFeedByUser:
         return (
            <Link
               href={`/feed/${noti.__socialFeedComment?.__socialFeed?._id}`}
               className={classNames([
                  styles["noti-item"],
                  noti?.options?.action !== EUserNotificationActions.seen &&
                     styles["unread"],
               ])}
               onClick={handleSeen}
            >
               <div className={styles["img"]}>
                  <Avatar
                     url={noti.__socialFeedComment?.__user?.__media?.$url}
                     size={40}
                  />
               </div>
               <div className={styles["text"]}>
                  {noti?.info?.title && (
                     <div
                        className={classNames([
                           styles["title"],
                           "fw-500 mb-1",
                           "truncate-line-2",
                        ])}
                     >
                        {noti?.info?.title}
                     </div>
                  )}
                  <div
                     className={classNames([
                        styles["title"],
                        "truncate-line-3",
                     ])}
                  >
                     <span className={styles["highlight"]}>
                        {noti.__socialFeedComment?.__user?.publicInfo.nickname}
                     </span>{" "}
                     vừa bình luận bài đăng của bạn: &#34;
                     {noti.__socialFeedComment?.__socialFeed?.info?.description}
                     &#34;
                  </div>
                  <span className={styles["time"]}>
                     {getTimeFromNow(noti.createdAt ?? "")}
                  </span>
               </div>
            </Link>
         );
      case EUserNotificationOpenTypes.newReplyOnSocialFeedCommentByUser:
         return (
            <Link
               href={`/feed/${noti.__socialFeedComment?.__socialFeed?._id}`}
               className={classNames([
                  styles["noti-item"],
                  noti?.options?.action !== EUserNotificationActions.seen &&
                     styles["unread"],
               ])}
               onClick={handleSeen}
            >
               <div className={styles["img"]}>
                  <Avatar
                     url={noti.__socialFeedComment?.__user?.__media?.$url}
                     size={40}
                  />
               </div>
               <div className={styles["text"]}>
                  {noti?.info?.title && (
                     <div
                        className={classNames([
                           styles["title"],
                           "fw-500 mb-1",
                           "truncate-line-2",
                        ])}
                     >
                        {noti?.info?.title}
                     </div>
                  )}
                  <div
                     className={classNames([
                        styles["title"],
                        "truncate-line-3",
                     ])}
                  >
                     <span className={styles["highlight"]}>
                        {noti.__socialFeedComment?.__user?.publicInfo.nickname}
                     </span>{" "}
                     vừa trả lời bình luận của bạn: &#34;
                     {
                        noti.__socialFeedComment?.__replyForComment?.info
                           ?.content
                     }
                     &#34;
                  </div>
                  <span className={styles["time"]}>
                     {getTimeFromNow(noti.createdAt ?? "")}
                  </span>
               </div>
            </Link>
         );
      case EUserNotificationOpenTypes.receiptSubscriptionFromOneUser:
         return (
            <Link
               href={`/tai-khoan/${noti.__userSubscribe?.__user?._id}`}
               className={classNames([
                  styles["noti-item"],
                  noti?.options?.action !== EUserNotificationActions.seen &&
                     styles["unread"],
               ])}
               onClick={handleSeen}
            >
               <div className={styles["img"]}>
                  <Avatar
                     url={noti.__userSubscribe?.__user?.__media?.$url}
                     size={40}
                  />
               </div>
               <div className={styles["text"]}>
                  {noti?.info?.title && (
                     <div
                        className={classNames([
                           styles["title"],
                           "fw-500 mb-1",
                           "truncate-line-2",
                        ])}
                     >
                        {noti?.info?.title}
                     </div>
                  )}
                  <div
                     className={classNames([
                        styles["title"],
                        "truncate-line-3",
                     ])}
                  >
                     <span className={styles["highlight"]}>
                        {noti.__userSubscribe?.__user?.publicInfo.nickname}
                     </span>{" "}
                     bắt đầu theo dõi bạn
                  </div>
                  <span className={styles["time"]}>
                     {getTimeFromNow(noti.createdAt ?? "")}
                  </span>
               </div>
            </Link>
         );

      //actualwork
      case EUserNotificationOpenTypes.replyOnActualWorkComment:
         return (
            <Link
               href={`/${
                  noti.__actualWorkComment?.__actualWork?.options
                     .articleType === EActualWorkTypes.actualWork
                     ? "cong-trinh-thuc-te"
                     : "thu-vien-thiet-ke"
               }/${noti.__actualWorkComment?.__actualWork?.info.slug ?? ""}`}
               className={classNames([
                  styles["noti-item"],
                  noti?.options?.action !== EUserNotificationActions.seen &&
                     styles["unread"],
               ])}
               onClick={handleSeen}
            >
               <div className={styles["img"]}>
                  <Avatar
                     url={
                        noti.__actualWorkComment?.__user?.__media?.$url ??
                        noti.__actualWorkComment?.__user?.__media?.$url
                     }
                     size={40}
                  />
               </div>
               <div className={styles["text"]}>
                  {noti?.info?.title && (
                     <div
                        className={classNames([
                           styles["title"],
                           "fw-500 mb-1",
                           "truncate-line-2",
                        ])}
                     >
                        {noti?.info?.title}
                     </div>
                  )}
                  <div
                     className={classNames([
                        styles["title"],
                        "truncate-line-3",
                     ])}
                  >
                     <span className={styles["highlight"]}>
                        {noti.__actualWorkComment?.__user?.publicInfo.nickname}
                     </span>{" "}
                     vừa trả lời bình luận của bạn ở bài viết{" "}
                     {noti.__actualWorkComment?.__actualWork?.options
                        .articleType === EActualWorkTypes.actualWork
                        ? "Công trình thực tế"
                        : "Mẫu thiết kế"}
                  </div>
                  <span className={styles["time"]}>
                     {getTimeFromNow(noti.createdAt ?? "")}
                  </span>
               </div>
            </Link>
         );
      case EUserNotificationOpenTypes.reactionOnActualWorkComment:
         return (
            <Link
               href={`/${
                  noti.__actualWorkReaction?.__actualWork?.options
                     .articleType === EActualWorkTypes.actualWork
                     ? "cong-trinh-thuc-te"
                     : "thu-vien-thiet-ke"
               }/${noti.__actualWorkReaction?.__actualWork?.info.slug ?? ""}`}
               className={classNames([
                  styles["noti-item"],
                  noti?.options?.action !== EUserNotificationActions.seen &&
                     styles["unread"],
               ])}
               onClick={handleSeen}
            >
               <div className={styles["img"]}>
                  <Avatar
                     url={
                        noti.__actualWorkReaction?.__user?.__media?.$url ??
                        noti.__actualWorkReaction?.__user?.__media?.$url
                     }
                     size={40}
                  />
               </div>
               <div className={styles["text"]}>
                  {noti?.info?.title && (
                     <div
                        className={classNames([
                           styles["title"],
                           "fw-500 mb-1",
                           "truncate-line-2",
                        ])}
                     >
                        {noti?.info?.title}
                     </div>
                  )}
                  <div
                     className={classNames([
                        styles["title"],
                        "truncate-line-3",
                     ])}
                  >
                     <span className={styles["highlight"]}>
                        {noti.__actualWorkReaction?.__user?.publicInfo.nickname}
                     </span>{" "}
                     vừa thích bình luận của bạn ở bài viết{" "}
                     {noti.__actualWorkReaction?.__actualWork?.options
                        .articleType === EActualWorkTypes.actualWork
                        ? "Công trình thực tế"
                        : "Mẫu thiết kế"}
                  </div>
                  <span className={styles["time"]}>
                     {getTimeFromNow(noti.createdAt ?? "")}
                  </span>
               </div>
            </Link>
         );

      //article
      case EUserNotificationOpenTypes.replyOnArticleComment:
         const category = getTypeMagazine(
            noti.__articleComment?.__article?.categoryId ?? ""
         );
         return (
            <Link
               href={`/blog/${category}/${
                  noti.__articleComment?.__article?.info.slug ?? ""
               }`}
               className={classNames([
                  styles["noti-item"],
                  noti?.options?.action !== EUserNotificationActions.seen &&
                     styles["unread"],
               ])}
               onClick={handleSeen}
            >
               <div className={styles["img"]}>
                  <Avatar
                     url={
                        noti.__articleComment?.__user?.__media?.$url ??
                        noti.__articleComment?.__user?.__media?.$url
                     }
                     size={40}
                  />
               </div>
               <div className={styles["text"]}>
                  {noti?.info?.title && (
                     <div
                        className={classNames([
                           styles["title"],
                           "fw-500 mb-1",
                           "truncate-line-2",
                        ])}
                     >
                        {noti?.info?.title}
                     </div>
                  )}
                  <div
                     className={classNames([
                        styles["title"],
                        "truncate-line-3",
                     ])}
                  >
                     <span className={styles["highlight"]}>
                        {noti.__articleComment?.__user?.publicInfo.nickname}
                     </span>{" "}
                     vừa trả lời bình luận của bạn ở bài viết Kiến thức
                  </div>
                  <span className={styles["time"]}>
                     {getTimeFromNow(noti.createdAt ?? "")}
                  </span>
               </div>
            </Link>
         );
      case EUserNotificationOpenTypes.reactionOnArticleComment:
         const categoryMagazine = getTypeMagazine(
            noti.__articleReaction?.__article?.categoryId ?? ""
         );
         return (
            <Link
               href={`/blog/${categoryMagazine}/${
                  noti.__articleReaction?.__article?.info.slug ?? ""
               }`}
               className={classNames([
                  styles["noti-item"],
                  noti?.options?.action !== EUserNotificationActions.seen &&
                     styles["unread"],
               ])}
               onClick={handleSeen}
            >
               <div className={styles["img"]}>
                  <Avatar
                     url={
                        noti.__articleReaction?.__user?.__media?.$url ??
                        noti.__articleReaction?.__user?.__media?.$url
                     }
                     size={40}
                  />
               </div>
               <div className={styles["text"]}>
                  {noti?.info?.title && (
                     <div
                        className={classNames([
                           styles["title"],
                           "fw-500 mb-1",
                           "truncate-line-2",
                        ])}
                     >
                        {noti?.info?.title}
                     </div>
                  )}
                  <div
                     className={classNames([
                        styles["title"],
                        "truncate-line-3",
                     ])}
                  >
                     <span className={styles["highlight"]}>
                        {noti.__articleReaction?.__user?.publicInfo.nickname}
                     </span>{" "}
                     vừa thích bình luận của bạn ở bài viết Kiến thức
                  </div>
                  <span className={styles["time"]}>
                     {getTimeFromNow(noti.createdAt ?? "")}
                  </span>
               </div>
            </Link>
         );

      // badge
      case EUserNotificationOpenTypes.userBadgeUpdated:
         return (
            <a
               className={classNames([
                  styles["noti-item"],
                  noti?.options?.action !== EUserNotificationActions.seen &&
                     styles["unread"],
               ])}
               onClick={() => {
                  onClickItem?.(noti.info.openType, noti);
               }}
            >
               <div className={styles["img"]}>
                  <img src="/assets/img/cover/badge.jpg" alt="" />
               </div>
               <div className={styles["text"]}>
                  {noti?.info?.title && (
                     <div
                        className={classNames([
                           styles["title"],
                           "fw-500 mb-1",
                           "truncate-line-2",
                        ])}
                     >
                        {noti?.info?.title}
                     </div>
                  )}
                  <div
                     className={classNames([
                        styles["title"],
                        "truncate-line-3",
                     ])}
                  >
                     {(() => {
                        if (
                           noti?.__user?.publicInfo?.badge === EBadge.creator
                        ) {
                           return "Bạn vừa trở thành một trong những Nhà sáng tạo nội dung của Space T";
                        } else if (
                           noti?.__user?.publicInfo?.badge === EBadge.expert
                        ) {
                           return "Bạn vừa trở thành một trong những Chuyên gia nội dung của Space T";
                        } else if (
                           noti?.__user?.publicInfo?.badge === EBadge.enterprise
                        ) {
                           return "Bạn vừa trở thành một trong những Đối tác kinh doanh của Space T";
                        }
                        return "";
                     })()}
                  </div>
                  <span className={styles["time"]}>
                     {getTimeFromNow(noti.createdAt ?? "")}
                  </span>
               </div>
            </a>
         );

      //report
      case EUserNotificationOpenTypes.approveReportSocialFeed:
         return (
            <NotificationReport
               noti={noti}
               onSeen={handleSeen}
               onChangeNotification={onChangeNotification}
            />
         );
      case EUserNotificationOpenTypes.approveReportQnA:
         return (
            <NotificationReport
               noti={noti}
               onSeen={handleSeen}
               onChangeNotification={onChangeNotification}
            />
         );
      case EUserNotificationOpenTypes.approveReportQnAComment:
         return (
            <NotificationReport
               noti={noti}
               onSeen={handleSeen}
               onChangeNotification={onChangeNotification}
            />
         );
      case EUserNotificationOpenTypes.approveReportSocialFeedComment:
         return (
            <NotificationReport
               noti={noti}
               onSeen={handleSeen}
               onChangeNotification={onChangeNotification}
            />
         );

      default:
         return <DefaultNotiUI noti={noti} onClick={handleSeen} />;
   }
}
