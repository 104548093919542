import React, { useEffect, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { ModelError } from "../../../../models/model";
import yup from "../../../../core/yupPatern";
import { EStep } from "../const";
import User, { EMethod } from "models/user.model";
import { useRouter } from "next/router";
import { useInterval } from "hook/useInterval";
import { executeRecaptcha } from "helpers/GoogleRecaptcha";

interface IFormInputs {
   account?: string;
   phone?: string;
}

interface IProps {
   form: IFormInputs;
   onLogin: () => void;
   setStep: ({ step, dataStep }: { step: EStep; dataStep?: any }) => void;
   onBack: () => void;
}

const SignupWithPhoneForm: React.FC<IProps> = ({
   form,
   onLogin,
   setStep,
   onBack,
}) => {
   const router: any = useRouter();
   const { t } = useTranslation();
   const [s, setS] = useState(60);
   const [status, setStatus] = useState("stop");
   const [error, setError] = useState<ModelError>();
   const [previousURL, setPreviousURL] = useState("");

   useEffect(() => {
      if (
         error?.errors?.OTP?.[0]?.code === "handler.signupverify.OTP.pending"
      ) {
         setStatus("start");
      }
   }, [error?.errors]);

   useEffect(() => {
      if (typeof window != "undefined") {
         setPreviousURL(sessionStorage.getItem("previous_url") || "");
      }
      if (
         router.query?.regis_login &&
         !router.query?.regis_login.includes("dang-nhap")
      ) {
         setPreviousURL(router.query?.regis_login);
         sessionStorage.setItem("previous_url", router.query?.regis_login);
      }
   }, []);

   const schema = yup.object().shape({
      account: yup.string().required("Vui lòng nhập số điện thoại"),
   });

   const {
      register,
      handleSubmit,
      getValues,
      formState: { errors },
      setError: formSetError,
   } = useForm<IFormInputs>({
      resolver: yupResolver(schema),
      // defaultValues: {
      //    account: form?.account || form?.phone
      // }
   });

   const onSubmit = async () => {
      try {
         const value = getValues("account");
         if (!value) {
            return;
         }

         const tokenCaptcha = await executeRecaptcha();
         const verify = await User.signupVerify(value, tokenCaptcha);
         if (verify instanceof ModelError) {
            setError(verify);
            formSetError("account", {
               message: verify.firstMessageErr,
            });
         } else {
            setError(undefined);
            // setStep({ step: EStep.signupWithPhoneAndPassword, dataStep: { _id: verify.otpId, phone: value, method: EMethod.sms, previousURL } });
            setStep({
               step: EStep.otpInput,
               dataStep: {
                  _id: verify.otpId,
                  phone: value,
                  method: EMethod.sms,
                  previousURL,
               },
            });
         }
      } catch (error) {}
   };

   useInterval(
      () => {
         if (s > 0) {
            setS(s - 1);
         } else {
            setStatus("stop");
            setS(60);
         }
      },
      status === "start" ? 1000 : null
   );

   return (
      <div className="signup-phone">
         <h1 className="signup-phone-title">Đăng ký với số điện thoại</h1>
         <p className="signup-phone-des">
            Nhập số điện thoại của bạn để bắt đầu với SpaceT tại đây!
         </p>

         <form className="form-register" onSubmit={handleSubmit(onSubmit)}>
            <div className="item-form">
               <label className="label">Số điện thoại</label>
               <div className="input-wrap">
                  <input
                     type="text"
                     className={`input input-account ${
                        errors.account || error?.errors?.account ? "error" : ""
                     }`}
                     {...register("account", {
                        pattern:
                           /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/i,
                     })}
                     placeholder="Số điện thoại"
                  />
               </div>
               {s < 60 &&
               error?.errors?.OTP?.[0]?.code ===
                  "handler.signupverify.OTP.pending" ? (
                  <span className="error-text">
                     {t(`${error?.errors?.OTP?.[0]?.message}`)}
                     {s < 60 &&
                        error?.errors?.OTP?.[0]?.code ===
                           "handler.signupverify.OTP.pending" &&
                        ` ${s}s`}
                  </span>
               ) : errors?.account?.message ? (
                  <span className="error-text">
                     {t(`${errors?.account?.message}`)}
                  </span>
               ) : undefined}
            </div>

            <button
               className="btn btn-auth"
               type="submit"
               style={{ backgroundColor: "#FF6347" }}
            >
               Đăng ký
            </button>
         </form>

         <div
            className="login-helper d-flex justify-content-center"
            style={{ marginTop: 30, textAlign: "center" }}
         >
            Đã có tài khoản?{" "}
            <div className="ms-1" onClick={onLogin}>
               Đăng nhập ngay
            </div>
         </div>

         <div className="back-step" onClick={onBack}>
            <img
               width="14px"
               height="7px"
               src="/assets/img/icon/arrow-left-icon.svg"
               alt=""
            />
         </div>
      </div>
   );
};

export default SignupWithPhoneForm;
