/* eslint-plugin-disable react */
/*eslint-disable */
/**
 * Variable(s)
 */
import { apiDomain, tokenKey } from "./__variables";

/**
 * Core(s)
 */
import Model, {
   ModelError,
   Paginate,
   TModelError,
   TPaginate,
   TPayload,
} from "./model";

export enum EQnaTopicSortBy {
   createdAt = "createdAt",
   topicTitle = "info.title",
}

export enum EQnaTopicStatus {
   enable = "enable",
   disable = "disable",
   delete = "delete",
}

export const allImageTopic =
   "https://spacet-release.s3.ap-southeast-1.amazonaws.com/img/qna-topic/All-topic.jpg";

export const defaultImageTopic =
   "https://spacet-release.s3.ap-southeast-1.amazonaws.com/img/qna-topic/Default-icon.svg";

export interface IQnATopic extends Model {
   ["info"]?: {
      ["title"]?: string | null;
      ["slug"]?: string | null;
      ["qnaCount"]?: number | null;
   };
   ["options"]?: {
      ["status"]?: EQnaTopicStatus;
   };
   ["image"]: {
      ["container"]: string | null;
      ["domain"]: string | null;
      ["originalName"]: string | null;
      ["protocol"]: string | null;
      ["size"]: number | null;
      ["extension"]: string | null;
      ["fileType"]: string | null;
      ["status"]: string | null;
   };
}

export default class QnATopic extends Model implements IQnATopic {
   public ["info"]?: {
      ["title"]?: string | null;
      ["slug"]?: string | null;
      ["qnaCount"]?: number | null;
   };
   public ["options"]?: {
      ["status"]?: EQnaTopicStatus;
   };
   public ["image"]!: {
      ["container"]: string | null;
      ["domain"]: string | null;
      ["originalName"]: string | null;
      ["protocol"]: string | null;
      ["size"]: number | null;
      ["extension"]: string | null;
      ["fileType"]: string | null;
      ["status"]: string | null;
   };
   public ["$url"]?: any | null;

   constructor(data?: IQnATopic) {
      super(data);

      Object.assign(this, data);
      this.$url = `${this?.image.protocol}://${this?.image?.domain}/${this?.image?.container}/${this._id}${this?.image?.extension}`;
   }

   public static async paginate({
      limit,
      page,
      offset,
      sort,
      sortBy,
      excludeIds,
   }: {
      page?: number;
      limit?: number;
      sort?: "asc" | "desc";
      sortBy?: EQnaTopicSortBy;
      offset?: number;
      excludeIds?: string[];
   }) {
      try {
         // const token = localStorage.getItem(tokenKey);
         const response = await Model.fetchData({
            method: "GET",
            endPoint: "qna-topic/paginate",
            headers: {
               limit: !limit ? String(100) : String(limit),
               sort: !sort ? "asc" : encodeURI(sort),
               "sort-by": !sortBy
                  ? EQnaTopicSortBy.topicTitle
                  : encodeURI(sortBy),
               // ...!token ? undefined : { "Authorization": `Bearer ${token}` },
               ...(!page ? undefined : { page: String(page) }),
               ...(!offset ? undefined : { offset: String(offset) }),
            },
         });
         if (response instanceof ModelError) {
            return response;
         }

         const payload = (await response.json()) as TPayload<{
            ["paginator"]: TPaginate<IQnATopic>;
         }>;

         return new Paginate(payload?.data?.paginator, QnATopic);
      } catch (error: any) {}
   }
}
