// import User from "../../models/user.model";
// import { TUserPoint } from "../../models/userPoint.model";
import { userInfoKey } from "@Config/index";
import * as types from "../type";
import { EAuthType, EStep } from "@Components/modal/auth/const";
import { clearAuthCache, clearProfileCache, setProfileCache } from "utils/auth";
import { isClient } from "utils";
import User from "@Models/user.model";

export interface IAuthState {
   user: User;
   points: {
      totalPoint: any;
      usedPoint: any;
      notExpiredPoint: any;
      useablePoint: any;
   };
   notifications: any;
   bookmark: any;
   userStatus: {
      emailExists: any;
      passwordExists: any;
      phoneExists: any;
   };
   profile: any;
   modalAuth: {
      visible: boolean;
      step: EStep;
      type: EAuthType;
      dataStep: any;
      redirect: any; // string
   };
   introducingPopover: {
      visible: boolean;
      top: number;
      left: number;
   };
   modalWelcome: {
      visible: boolean;
      showGreetingPost: boolean;
   };
}

let userInfo: any = isClient ? localStorage.getItem(userInfoKey) : undefined;
userInfo = userInfo ? JSON.parse(userInfo) : undefined;

const initState: IAuthState = {
   user: undefined,
   points: {
      totalPoint: undefined,
      usedPoint: undefined,
      notExpiredPoint: undefined,
      useablePoint: undefined,
   },
   notifications: undefined,
   bookmark: undefined,
   userStatus: {
      emailExists: undefined,
      passwordExists: undefined,
      phoneExists: undefined,
   },
   ...userInfo,

   // profile
   profile: undefined,

   // Other
   modalAuth: {
      visible: false,
      step: EStep.signup,
      type: EAuthType.signup,
      dataStep: null,
      redirect: null,
   },
   introducingPopover: {
      visible: false,
      top: 10,
      left: 10,
   },
   modalWelcome: {
      visible: false,
      showGreetingPost: false,
   },
};
export default function authReducer(
   state = initState,
   action: {
      type: string;
      payload: any;
   }
) {
   const { payload } = action;
   switch (action.type) {
      case types.VERIFY:
         setProfileCache({
            user: action.payload.userInfo,
            points: action.payload.userPoints,
            notifications: action.payload.userNotifications,
            bookmark: action.payload?.userBookmarks,
            userStatus: action.payload.userStatus,
         });
         return {
            ...state,
            user: action.payload.userInfo,
            points: action.payload.userPoints,
            notifications: action.payload.userNotifications,
            bookmark: action.payload?.userBookmarks,
            userStatus: action.payload.userStatus,
         };
      case types.UNVERIFY:
         clearAuthCache();
         return {
            ...state,
            user: null,
            points: {
               totalPoint: null,
               usedPoint: null,
               notExpiredPoint: null,
               useablePoint: null,
            },
            notifications: null,
            bookmark: null,
            userStatus: {
               emailExists: null,
               passwordExists: null,
               phoneExists: null,
            },
         };
      case types.GET_PROFILE:
         return {
            ...state,
            profile: payload,
         };
      case types.SIGNIN:
         setProfileCache({
            user: action.payload.userInfo,
            points: action.payload.userPoints,
            notifications: action.payload.userNotifications,
            bookmark: action.payload?.userBookmarks,
            userStatus: action.payload.userStatus,
         });
         return {
            ...state,
            user: action.payload.userInfo,
            points: action.payload.userPoints,
            notifications: action.payload.userNotifications,
            bookmark: action.payload?.userBookmarks,
            userStatus: action.payload.userStatus,
         };
      case types.SIGNOUT:
         clearProfileCache();
         return {
            ...state,
            user: null,
            points: {
               totalPoint: null,
               usedPoint: null,
               notExpiredPoint: null,
               useablePoint: null,
            },
            notifications: null,
            bookmark: null,
            userStatus: {
               emailExists: null,
               passwordExists: null,
               phoneExists: null,
            },
         };
      case types.TOGGLE_MODAL_AUTH:
         let currentType = state.modalAuth.type;
         if (payload.type) {
            currentType = payload.type;
         } else {
            if (
               [EStep.signinStep1, EStep.signinStep2].includes(payload?.step)
            ) {
               currentType = EAuthType.signin;
            } else if (
               [
                  EStep.forgotPasswordStep1,
                  EStep.forgotPasswordStep2,
                  EStep.forgotPasswordStep3,
                  EStep.forgotPasswordStep4,
               ].includes(payload?.step)
            ) {
               currentType = EAuthType.forgot;
            } else if (
               [
                  EStep.signup,
                  EStep.signupWithEmail,
                  EStep.signupWithPhone,
                  EStep.signupWithPhoneAndPassword,
               ].includes(payload?.step)
            ) {
               currentType = EAuthType.signup;
            }
         }
         return {
            ...state,
            modalAuth: {
               ...state.modalAuth,
               ...payload,
               visible:
                  payload?.visible !== undefined
                     ? payload?.visible
                     : state.modalAuth.visible,
               step: payload?.step || state.modalAuth.step,
               type: currentType,
               dataStep: payload?.dataStep || state.modalAuth.dataStep,
            },
         };
      case types.TOGGLE_INTRODUCING_POPOVER:
         return {
            ...state,
            introducingPopover: {
               visible: payload.visible,
               top: payload.top ?? 10,
               left: payload.left ?? 10,
            },
         };

      case types.TOGGLE_MODAL_WELCOME:
         return {
            ...state,
            modalWelcome: {
               ...state.modalWelcome,
               ...payload,
               visible:
                  payload.visible !== undefined
                     ? payload.visible
                     : state.modalWelcome.visible,
            },
         };
      default:
         return state;
   }
}
