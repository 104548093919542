import classNames from "classnames";
import Image from "next/image";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Popover } from "@material-ui/core";
import { useRouter } from "next/router";
import useCustomHookState from "hook/useCustomHookState";
import Link from "@Components/Link";
import LayoutContainer from "@Components/Container";
import styles from "./_scss/index.module.scss";

const SuperBar = ({ leftBar, showInline }: { leftBar?: any; showInline?: boolean }) => {
   const router = useRouter();
   const { state, setState } = useCustomHookState<any>({});
   const handlePopover = (event: React.MouseEvent<HTMLAnchorElement>, type?: "chat" | "post") => {
      setState({ anchorPopover: event.currentTarget, typePopOver: type });
   };
   const handleClosePopover = () => {
      setState({ anchorPopover: undefined });
   };
   return (
      <div className={classNames(styles["menu-top"], "d-none  d-lg-flex")}>
         <LayoutContainer className="container">
            <div className="d-flex justify-content-between align-items-center">
               <div>{leftBar}</div>
               <div className={styles["grid-menu-top"]}>
                  <Link
                     href="/noithatgiasi"
                     className={classNames(styles["menu-top-item"], router.pathname.startsWith("/noithatgiasi") ? styles["active"] : "")}
                  >
                     Nội thất giá sỉ
                  </Link>
                  <Link
                     href="/referral-program"
                     className={classNames(styles["menu-top-item"], router.pathname.startsWith("/referral-program") ? styles["active"] : "")}
                  >
                     Referral Program
                  </Link>

                  <Link
                     href="/chuong-trinh-lien-ket"
                     className={classNames(styles["menu-top-item"], router.pathname.startsWith("/chuong-trinh-lien-ket") ? styles["active"] : "")}
                  >
                     Tiếp thị liên kết
                  </Link>
                  {/* <Link
                     href="https://partner.spacet.vn/"
                     rel="nofollow"
                     className={classNames(styles["menu-top-item"])}
                  >
                     Đối tác nội thất
                  </Link> */}
                  <Link
                     href="/chinh-sach/ban-hang-cung-space-t"
                     rel="nofollow"
                     className={classNames(styles["menu-top-item"], router.pathname.startsWith("/chinh-sach/ban-hang-cung-space-t") ? styles["active"] : "")}
                  >
                     Kênh người bán
                  </Link>

                  {/*  */}
                  <span className={classNames(styles["menu-top-item"], styles["menu-download"])}>
                     Tải ứng dụng
                     <div className={styles["pannel-download"]}>
                        <Image width={190} height={190} alt="Space T" src="/assets/img/icon/QR_Code_spacet.png" />
                        <div className={styles["list-app"]}>
                           <a
                              href={"https://apps.apple.com/us/app/space-t-n%E1%BB%99i-th%E1%BA%A5t-cho-t%E1%BB%95-%E1%BA%A5m/id1608834398"}
                              className="d-inline-block"
                              target="_blank"
                              rel="noopener noreferrer nofollow"
                              title={"Space T Application"}
                              style={{ width: "100%" }}
                           >
                              <Image src="/assets/img/icon/appdownload-appstore.svg" width={90} height={26} alt="Space T" />
                           </a>
                           <a
                              href={"https://play.google.com/store/apps/details?id=com.spacet.mobile"}
                              className="d-inline-block"
                              target="_blank"
                              rel="noopener noreferrer nofollow"
                              title={"Space T Application"}
                              style={{ width: "100%" }}
                           >
                              <Image src="/assets/img/icon/appdownload-googleplay.svg" width={90} height={26} alt="Space T" />
                           </a>
                        </div>
                     </div>
                  </span>

                  {/*  */}
                  {showInline ? (
                     <div className={styles["menu-top-item"]}>
                        <Link href="tel:+84848847938" className={styles["menu-top-sub-item"]} rel="nofollow">
                           <Image width={16} height={16} alt="Space T" src="/assets/img/icon/phone-green.svg" />
                           <span>084-884-7938</span>
                        </Link>
                        <Link
                           href="https://zalo.me/1171954959320860843"
                           target="_blank"
                           className={styles["menu-top-sub-item"]}
                           style={{ marginRight: "0.5rem" }}
                           rel="nofollow"
                        >
                           <Image width={16} height={16} src="/assets/img/icon/zalo-icon.svg" alt="chat" />
                           <span>Zalo</span>
                        </Link>
                     </div>
                  ) : (
                     <span className={classNames(styles["menu-top-item"], styles["menu-download"])} onClick={(event: any) => handlePopover(event, "chat")}>
                        Kết nối với chúng tôi
                        <KeyboardArrowDownIcon />
                     </span>
                  )}
               </div>
            </div>
         </LayoutContainer>

         {!showInline && (
            <Popover
               className="post-popover"
               onClose={handleClosePopover}
               BackdropProps={{ className: "backdrop" }}
               anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "center",
               }}
               transformOrigin={{
                  vertical: "top",
                  horizontal: "center",
               }}
               classes={{ paper: styles["popover"] }}
               anchorEl={state.anchorPopover}
               open={Boolean(state.anchorPopover)}
            >
               <div className={styles["content"]}>
                  <span className={styles["arrow-top"]}></span>
                  <Link href="tel:+84848847938" className={styles["item"]} rel="nofollow">
                     <Image width={16} height={16} alt="Space T" src="/assets/img/icon/phone-green.svg" />
                     <span>084-884-7938</span>
                  </Link>
                  <Link href="https://zalo.me/1171954959320860843" target="_blank" className={styles["item"]} rel="nofollow">
                     <Image width={16} height={16} src="/assets/img/icon/zalo-icon.svg" alt="chat" />
                     <span>Zalo</span>
                  </Link>
               </div>
            </Popover>
         )}
      </div>
   );
};
export default SuperBar;
