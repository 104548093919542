import React, { useEffect } from "react";
import { connect } from "react-redux";
// import { useRouter } from "next/router";
import { _metadata } from "../../utils/local-data";
// import { useAppStoreContext } from "../AppStoreContext";
import { Toaster } from "react-hot-toast";
import __variables from "models/__variables";
import { useRouter } from "next/router";

function Layout({ ...props }) {
   // const { setLoading } = useAppStoreContext();
   const router = useRouter();

   useEffect(() => {
      if (__variables.env === "PRODUCTION") return;
      let count = 0;
      const timer = setInterval(() => {
         const nextjsPortal = document.querySelector("nextjs-portal");
         if (nextjsPortal) {
            document.body.style.overflow = "unset";
            document.body.style.margin = "0px";
            document.body.style.padding = "0px";
            setTimeout(() => {
               document.body.style.overflow = "unset";
               document.body.style.margin = "0px";
               document.body.style.padding = "0px";
            }, 200);
            count++;
            if (count > 4) clearInterval(timer);
         }
      }, 500);

      return () => clearInterval(timer);
   }, [router]);

   return (
      <>
         {props?.children}
         <Toaster
            position="top-center"
            toastOptions={{ className: "react-hot-toast" }}
         />
      </>
   );
}
export default connect((props) => props)(Layout);
