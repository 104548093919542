/* eslint-plugin-disable react */
/*eslint-disable */

/**
 * Variable(s)
 */
import { apiDomain } from "./__variables";
import ActualWork from "./actualWork.model";

/**
 * Core(s)
 */
import Model, {
   ModelError,
   Paginate,
   TModelError,
   TPaginate,
   TPayload,
} from "./model";

/**
 * Model(s)
 */
import Partner from "./partner.model";
import PartnerReviewImage from "./partnerReviewImage.model";
import User from "./user.model";

export enum EStatus {
   initializing = "initializing",
   pending = "pending",
   enable = "enable",
   disable = "disable",
}
export enum EUsageStatus {
   pending = "pending",
   applied = "applied",
}

export enum EPartnerReviewRatingPoints {
   one = 1,
   two = 2,
   three = 3,
   four = 4,
   five = 5,
}
export interface IPartnerReview extends Model {
   ["info"]: {
      ["point"]: number;
      ["content"]: string | null;
      ["name"]?: string;
   };
   ["options"]: {
      ["status"]: EStatus;
      ["usageStatus"]: EUsageStatus;
   };
   ["ratingPoints"]?: {
      ["cost"]: EPartnerReviewRatingPoints;
      ["quality"]: EPartnerReviewRatingPoints;
      ["time"]: EPartnerReviewRatingPoints;
   };
   ["bonusPoint"]: number;
   ["variantId"]: string;
   ["orderId"]: string;
   ["userId"]: string;
   ["publishedAt"]: string;
   ["expiredAt"]: string;
   ["createdAt"]: string;
   ["updatedAt"]: string;

   ["__user"]?: User;
   ["__images"]?: PartnerReviewImage[];
   ["__partner"]?: Partner;
}

export class PartnerReview
   extends Model<IPartnerReview>
   implements IPartnerReview
{
   public ["info"]!: {
      ["point"]: number;
      ["content"]: string | null;
      ["name"]?: string;
   };
   public ["options"]!: {
      ["status"]: EStatus;
      ["usageStatus"]: EUsageStatus;
   };
   public ["bonusPoint"]!: number;
   public ["variantId"]!: string;
   public ["orderId"]!: string;
   public ["userId"]!: string;
   public ["publishedAt"]!: string;
   public ["expiredAt"]!: string;
   public ["createdAt"]!: string;
   public ["updatedAt"]!: string;

   public ["__user"]?: User;
   public ["__images"]?: PartnerReviewImage[];
   public ["__partner"]?: Partner;
   public ["ratingPoints"]?: {
      ["cost"]: EPartnerReviewRatingPoints;
      ["quality"]: EPartnerReviewRatingPoints;
      ["time"]: EPartnerReviewRatingPoints;
   };

   constructor(data?: IPartnerReview) {
      super(data);

      Object.assign(this, data);

      if (this.__user && !(this.__user instanceof User)) {
         this.__user = new User(this.__user);
      }
      if (this.__images && !(this.__images instanceof PartnerReviewImage)) {
         this.__images = this.__images.map(
            (image) => new PartnerReviewImage(image)
         );
      }
      if (this.__partner && !(this.__partner instanceof Partner)) {
         this.__partner = new Partner(this.__partner);
      }
   }
   /**
    *
    * @param page
    * @param limit
    * @param sort
    * @param sortBy
    * @returns
    */
   public static async partnerReviewPaginate({
      id,
      page,
      limit,
      sort,
      sortBy,
      point,
   }: {
      id: string;
      point?: string;
      page?: number;
      limit?: number;
      sort?: "desc" | "asc";
      sortBy?: "publishedAt" | "info.point";
   }) {
      try {
         const response = await fetch(
            `${apiDomain}/www/partner/review/paginate`,
            {
               ["method"]: "GET",
               ["cache"]: "default",
               ["headers"]: {
                  ["X-Requested-With"]: "XMLHttpRequest",
                  ["page"]: !page ? String(1) : String(page),
                  ["limit"]: !limit ? String(20) : String(limit),
                  ["sort"]: sort ?? "desc",
                  ["sort-by"]: sortBy ?? "publishedAt",
                  ["id"]: id,
                  ...(!point ? undefined : { ["point"]: point }),
               },
            }
         );

         if (!response.ok) {
            return new ModelError((await response.json()) as TModelError);
         }

         const payload = (await response.json()) as TPayload<{
            ["paginator"]: TPaginate<PartnerReview>;
         }>;

         const paginate = new Paginate(payload?.data?.paginator, PartnerReview);

         return paginate;
      } catch (error: any) {}
   }

   /**
    *
    * @param page
    * @param limit
    * @param sort
    * @param sortBy
    * @returns
    */
   public static async partnerReviewCoverActualWorkImage(slug: string) {
      try {
         const res = await Model.fetchData({
            method: "GET",
            endPoint: "partner/cover-actual-work-image",
            headers: {
               slug,
            },
         });
         if (res instanceof ModelError) {
            return res;
         }
         const payload = (await res.json()) as TPayload<ActualWork[]>;

         return payload?.data?.map?.((media) => new ActualWork(media));
      } catch (error) {
         return new ModelError(error);
      }
   }
}

export default PartnerReview;
