import { CircularProgress } from "@mui/material";
import classNames from "classnames";
import Link from "next/link";

interface IProps
   extends React.PropsWithChildren<React.ButtonHTMLAttributes<any>> {
   className?: string;
   width?: string;
   variant?:
      | "bt-outline-primary"
      | "bt-primary"
      | "bt-gray"
      | "bt-outline-white"
      | "bt-text"
      | "bt-outline-primary2";
   size?: "small" | "medium" | "large";
   loading?: boolean;
   disabled?: boolean;
   onClick?: () => void;
}

const Button: React.FC<IProps> = ({
   className,
   width,
   variant = "bt-primary",
   size = "medium",
   loading,
   disabled,
   onClick,
   children,
   ...props
}) => {
   return (
      <button
         className={classNames("button", className, variant, size)}
         disabled={disabled}
         data-loading={loading}
         onClick={() => {
            if (!loading && !disabled) onClick?.();
         }}
         style={{ ...props.style, width }}
         {...props}
      >
         {children}
         {loading && (
            <div className="circular-icon">
               <CircularProgress size={20} color="inherit" />
            </div>
         )}
      </button>
   );
};

export default Button;
