import ProductTaggingImage from "../../../models/productTaggingImage.model";
import TaggingItem from "./TaggingItem";

interface IProps {
   router: any;
   showTagging?: boolean;
   products: ProductTaggingImage[];
   toggleProgressbar: () => void;
   onDeleteProductTagging?: (product: ProductTaggingImage) => void;
   setToggleDialogProductNotAccept?: React.Dispatch<React.SetStateAction<boolean>>;
   rel?: string;
}

export default function Tagging({
   router,
   showTagging,
   setToggleDialogProductNotAccept,
   products,
   toggleProgressbar,
   onDeleteProductTagging,
   ...props
}: IProps) {
   if (!products || products.length < 1) return <></>;
   return (
      <>
         {products.map((tagging, index) => {
            return (
               <TaggingItem
                  key={tagging._id}
                  index={index}
                  router={router}
                  tagging={new ProductTaggingImage(tagging)}
                  showTagging={showTagging}
                  toggleProgressbar={toggleProgressbar}
                  onDeleteProductTagging={onDeleteProductTagging}
                  setToggleDialogProductNotAccept={setToggleDialogProductNotAccept}
                  {...props}
               />
            );
         })}
      </>
   );
}
