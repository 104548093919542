import { Socket, Manager } from "socket.io-client";
// import { tokenKey } from "../models/__variables";
import store from "../state";
import { verify } from "../state/action/auth";
// import { handleRedirectToSite } from "utils/handleRedirectToSite";
import { TUserNotificationCases } from "models/userNotifications.model";
import { getTokenCache } from "utils/auth";

const domainSocket = process.env.REACT_APP_SOCKET_DOMAIN;
// const supportSocket = "https://socket.spacet.vn/support";

type socketRefs = Socket | undefined;

const socketCollection: {
   manager?: Manager;
   channels: {
      ["/"]: socketRefs;
   };
} = {
   manager: undefined,
   channels: {
      "/": undefined,
   },
};

type TNamespaces = keyof typeof socketCollection.channels;

export const getSocketCollection = () => {
   const { manager, channels } = socketCollection;

   const token = getTokenCache();

   if (!token) {
      for (const [namespace, channel] of Object.entries(channels)) {
         if (!channel) {
            continue;
         }

         channel.disconnect();

         const convertedNamespace = namespace as TNamespaces;
         socketCollection.channels[convertedNamespace] &&
            (socketCollection.channels[convertedNamespace] = undefined);
      }

      delete socketCollection.manager;

      return socketCollection;
   }

   if (!manager) {
      socketCollection.manager = new Manager(domainSocket, {
         path: "/socket",
         // extraHeaders work only for polling, not for websocket,
         // add authorize to query for websocket
         extraHeaders: {
            Authorization: `Bearer ${token}`,
         },
         query: {
            authorization: `Bearer ${token}`,
         },
         transports: ["websocket"],
      });
   }

   for (const [namespace, channel] of Object.entries(channels)) {
      if (channel || !socketCollection.manager) {
         continue;
      }
      const convertedNamespace = namespace as TNamespaces;
      socketCollection.channels[convertedNamespace] =
         socketCollection.manager.socket(convertedNamespace) ?? undefined;

      registerListeners(
         <Socket>socketCollection.channels[convertedNamespace],
         convertedNamespace
      );
   }

   return socketCollection;
};

const registerListeners = (socket: Socket, namespace: TNamespaces = "/") => {
   switch (namespace) {
      case "/":
         socket.on("connect_error", (error) => {});
         socket.on("connect", () => {});
         socket.on("disconnect", () => {});
         socket.on("notifications", (data: TUserNotificationCases) => {
            store.dispatch(verify());
         });
         break;
      default:
         break;
   }
};
