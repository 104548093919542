/**
 * Module(s)
 */
/**
 * Element(s)
 */
/**
 * Model(s)
 */
import MenuModel, { Menu } from "../../models/menu.model";
import { MenuItem } from "../../models/menuItem.model";
import { ModelError } from "../../models/model";

export class MenuStorage {
   private _activeMenuItemIds: string[] = [];

   private _menu!: Menu;
   private _rawMenuItems: MenuItem[] = [];

   private _currentPathName!: string;

   constructor() {}

   async init() {
      try {
         const menu = await MenuModel.getMenuByAlias("main");
         if (menu instanceof ModelError) {
            throw Error("Error on get shop menu.");
         }

         this._menu = menu;
         // Recursive to get raw menu

         // Collect active menu item ids in initialize step
         await this.collectActiveMenuItemId();

         if (menu.__items) {
            const recursiveHandler = (...menuItems: MenuItem[]) => {
               menuItems.forEach((menuItem) => {
                  this._rawMenuItems.push(menuItem);

                  if (!menuItem.__childs || menuItem.__childs?.length < 1) {
                     return;
                  }

                  recursiveHandler(...menuItem.__childs);
               });
            };

            recursiveHandler(...menu.__items);
         }
      } catch (error) {}

      return this;
   }

   async collectActiveMenuItemId(customPathname?: string) {
      if (typeof window !== "undefined" || customPathname) {
         const pathname = customPathname ?? window.location.pathname;

         const newIdCollection: string[] = [];

         if (pathname === this._currentPathName) {
            return this._activeMenuItemIds;
         }

         this._currentPathName = pathname;

         const recursiveHandler = (menuItem: MenuItem) => {
            if (pathname === `/${menuItem.fullSlug}`) {
               newIdCollection.push(...menuItem.parentIds, menuItem._id);
            }

            if (menuItem.__childs && menuItem.__childs?.length > 0) {
               for (let i = 0; i < menuItem.__childs?.length; i++) {
                  const childMenuItem = menuItem.__childs[i];
                  recursiveHandler(childMenuItem);
               }
            }
         };

         const { menu } = this;

         if (!menu?.__items) {
            return (this._activeMenuItemIds = []);
         }

         for (let i = 0; i < menu.__items?.length; i++) {
            const menuItemLevel_01 = menu.__items[i];
            recursiveHandler(menuItemLevel_01);
         }

         if (newIdCollection?.length === 0) {
            const rootPathNames = ["/", "/shop"];

            // Find start withs without root pages
            const recursiveHandlerWithoutRoots = (menuItem: MenuItem) => {
               if (
                  !rootPathNames.includes(`/${menuItem.fullSlug}`) &&
                  pathname.startsWith(`/${menuItem.fullSlug}`)
               ) {
                  newIdCollection.push(...menuItem.parentIds, menuItem._id);
               }

               if (menuItem.__childs && menuItem.__childs?.length > 0) {
                  for (let i = 0; i < menuItem.__childs?.length; i++) {
                     const childMenuItem = menuItem.__childs[i];
                     recursiveHandlerWithoutRoots(childMenuItem);
                  }
               }
            };

            for (let i = 0; i < menu.__items?.length; i++) {
               const menuItemLevel_01 = menu.__items[i];
               recursiveHandlerWithoutRoots(menuItemLevel_01);
            }

            // If ID collection still empty => find with the roots
            if (newIdCollection?.length === 0) {
               const recursiveHandlerRoots = (menuItem: MenuItem) => {
                  if (
                     `/${menuItem.fullSlug}` !== "/" &&
                     pathname.startsWith(`/${menuItem.fullSlug}`)
                  ) {
                     newIdCollection.push(...menuItem.parentIds, menuItem._id);
                  }

                  if (menuItem.__childs && menuItem.__childs?.length > 0) {
                     for (let i = 0; i < menuItem.__childs?.length; i++) {
                        const childMenuItem = menuItem.__childs[i];
                        recursiveHandlerRoots(childMenuItem);
                     }
                  }
               };

               for (let i = 0; i < menu.__items?.length; i++) {
                  const menuItemLevel_01 = menu.__items[i];
                  recursiveHandlerRoots(menuItemLevel_01);
               }
            }

            // With the final still empty => return homepage
            if (newIdCollection?.length === 0) {
               const recursiveHandlerHome = (menuItem: MenuItem) => {
                  if (
                     `/${menuItem.fullSlug}` === "/" &&
                     pathname.split("/")?.length === 1
                  ) {
                     newIdCollection.push(...menuItem.parentIds, menuItem._id);
                  }

                  if (menuItem.__childs && menuItem.__childs?.length > 0) {
                     for (let i = 0; i < menuItem.__childs?.length; i++) {
                        const childMenuItem = menuItem.__childs[i];
                        recursiveHandlerHome(childMenuItem);
                     }
                  }
               };

               for (let i = 0; i < menu.__items?.length; i++) {
                  const menuItemLevel_01 = menu.__items[i];
                  recursiveHandlerHome(menuItemLevel_01);
               }
            }
         }

         this._activeMenuItemIds = [...new Set(newIdCollection)];

         return this._activeMenuItemIds;
      }
   }

   set setActiveMenu(activeIds: string[]) {
      this._activeMenuItemIds = activeIds;
   }

   getMenuItemById(menuItemId: string) {
      const index = this._rawMenuItems.findIndex(
         (rawMenuItem) => rawMenuItem._id === menuItemId
      );

      if (index < 0) {
         return undefined;
      }

      return this._rawMenuItems[index];
   }

   // Get level 1
   get LevelOne() {
      if (!this._menu?.__items) {
         return [];
      }

      return this._menu?.__items;
   }
   // v2
   get levelOne() {
      if (!this._menu?.__items) {
         return [];
      }

      return this._menu?.__items;
   }

   // Get level 2
   get levelTwo() {
      if (!this._menu?.__items) {
         return [];
      }

      // Find level one activating
      let activatingLevelOne: MenuItem | undefined = undefined;
      const { __items } = this._menu;

      for (let i = 0; i < __items?.length; i++) {
         if (!this._activeMenuItemIds.includes(__items[i]._id)) {
            continue;
         }

         activatingLevelOne = __items[i];
         break;
      }

      if (!activatingLevelOne || !activatingLevelOne.__childs) {
         return [];
      }

      return activatingLevelOne.__childs;
   }

   get activeMenuItemIds() {
      return this._activeMenuItemIds;
   }

   get menu() {
      return this._menu;
   }
}

const MenuStorageInstance = new MenuStorage();

export default MenuStorageInstance.init();
