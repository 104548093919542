import { ModelError } from "@Models/model";
import User from "@Models/user.model";
import { NextRouter } from "next/router";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import store, { RootState } from "state";
import { getProfile, verify } from "state/action/auth";

export default function FacebookLoginMobileHandler({
   router,
}: {
   router: NextRouter;
}) {
   const user = useSelector((state: RootState) => state.auth.user);
   useEffect(() => {
      if (user?._id) return;

      const urlParams = new URLSearchParams(
         window.location.href?.split("#")[1] as any
      );
      if (!urlParams?.has("access_token")) return;

      const token = urlParams.get("access_token");
      if (!token) return;

      User.signinWithFaceBook(token, window.location.href?.split("#")[0]).then(
         (signinWithFacebook) => {
            if (signinWithFacebook instanceof ModelError) {
            } else {
               store.dispatch(verify());
               store.dispatch(getProfile());
            }
         }
      );
   }, [user?._id, JSON.stringify(router.query)]);

   return <></>;
}
