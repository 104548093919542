import React from "react";
import Link from "next/link";

interface IProps {
   url?: string;
   size?: number;
   className?: string;
   userId?: string;
   alt?: string;
}
interface IStates {
   loaded: boolean;
}

export default class Avatar extends React.Component<IProps, IStates> {
   constructor(props: IProps) {
      super(props);
      this.state = {
         loaded: false,
      };
   }

   onLoaded = () => {
      this.setState({ loaded: true });
   };

   render() {
      const { size = 44, url, userId, className, alt } = this.props;
      const AvatarElement = (
         <div
            className={`block-user__avatar avatar ${className}`}
            style={{
               width: size,
               height: size,
               border: url ? "1px solid #e0e0e0" : "",
               backgroundImage: url ? "" : "url(/assets/img/cover/avatar.png)",
            }}
         >
            {url ? <img src={url} onLoad={this.onLoaded} alt={alt ?? ""} /> : undefined}
         </div>
      );
      if (userId) {
         return <Link href={`/tai-khoan/${userId}`}>{AvatarElement}</Link>;
      }
      return AvatarElement;
   }
}
