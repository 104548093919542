/**
 * Model(s)
 */
import { apiDomain, tokenKey } from "./__variables";
import { ECommentSortBy, ECommentStatus } from "./comment.model";
import Model, {
   IModel,
   ModelError,
   Paginate,
   TModelError,
   TPaginate,
   TPayload,
} from "./model";
import Reaction, { EReactionTypes } from "./reaction.model";
import SocialFeed from "./socialFeed.model";
import SocialFeedReaction from "./socialFeedReaction.model";
import User from "./user.model";

export enum EMediaStatus {
   enable = "enable",
   disable = "disable",
}
export interface ISocialFeedComment extends IModel {
   ["info"]: {
      ["content"]: string | null;
      ["contentWeb"]?: string;
   };
   ["options"]: {
      ["status"]: ECommentStatus;
   };
   ["target"]: {
      ["doc"]: string;
      ["model"]: string;
      ["sourceDoc"]: string;
      ["sourceModel"]: string;
   };
   ["image"]?: {
      ["container"]: string | null;
      ["domain"]: string | null;
      ["originalName"]: string | null;
      ["protocol"]: string | null;
      ["size"]: number | null;
      ["width"]: number | null;
      ["height"]: number | null;
      ["extension"]: string | null;
      ["fileType"]: string | null;
      ["position"]: number | null;
      ["status"]: EMediaStatus;
   };
   ["userId"]: string | null;
   ["replyToUserIds"]?: string[];

   ["__user"]?: User | null;
   // ["__reaction"]?: TCommunityReaction | null;
   ["__reactionCount"]?: {
      [key in EReactionTypes]?: number;
   };
   ["__replyToUsers"]?: User[];
   ["__replyCount"]?: number;
   ["__replies"]?: SocialFeedComment[];
   ["__currentUserReactions"]?: SocialFeedReaction[];
   ["__currentUserReactionsMap"]?: {
      [key in EReactionTypes]?: Reaction;
   };
   ["__socialFeed"]?: SocialFeed;
   ["__replyForComment"]?: SocialFeedComment;
}

export class SocialFeedComment extends Model implements ISocialFeedComment {
   public ["info"]!: {
      ["content"]: string | null;
      ["contentWeb"]?: string;
   };
   public ["options"]!: {
      ["status"]: ECommentStatus;
   };
   public ["target"]!: {
      ["doc"]: string;
      ["model"]: string;
      ["sourceDoc"]: string;
      ["sourceModel"]: string;
   };
   public ["image"]?: {
      ["container"]: string | null;
      ["domain"]: string | null;
      ["originalName"]: string | null;
      ["protocol"]: string | null;
      ["size"]: number | null;
      ["width"]: number | null;
      ["height"]: number | null;
      ["extension"]: string | null;
      ["fileType"]: string | null;
      ["position"]: number | null;
      ["status"]: EMediaStatus;
   };
   public ["userId"]!: string | null;
   public ["replyToUserIds"]?: string[];

   public ["__user"]?: User | null;
   public ["__reactionCount"]?: {
      [key in EReactionTypes]?: number;
   };
   public ["__replyToUsers"]?: User[];
   public ["__replyCount"]?: number;
   public ["__replies"]?: SocialFeedComment[];
   public ["__currentUserReactions"]?: SocialFeedReaction[];
   public ["__currentUserReactionsMap"]?: {
      [key in EReactionTypes]?: Reaction;
   };
   public ["__socialFeed"]?: SocialFeed;
   public ["__replyForComment"]?: SocialFeedComment;

   public ["$url"]?: any | null;

   constructor(data?: ISocialFeedComment) {
      super(data);
      Object.assign(this, data);
      if (this?.__user) {
         this.__user = new User(this.__user);
      }

      if (this?.__replies) {
         this.__replies = this.__replies.map(
            (reply) => new SocialFeedComment(reply)
         );
      }

      if (this?.__replyToUsers) {
         this.__replyToUsers = this.__replyToUsers.map(
            (replyToUser) => new User(replyToUser)
         );
      }
      if (this?.__socialFeed) {
         this.__socialFeed = new SocialFeed(this.__socialFeed);
      }
      if (this.__replyForComment) {
         this.__replyForComment = new SocialFeedComment(this.__replyForComment);
      }
      this.$url = `${this?.image?.protocol}://${this?.image?.domain}/${this?.image?.container}/${this._id}${this?.image?.extension}`;
   }

   public static async paginate({
      id,
      commentId,
      page,
      limit,
      sort,
      sortBy,
      offset,
   }: {
      id: string;
      commentId?: string;
      page?: number;
      limit?: number;
      sort?: "asc" | "desc";
      sortBy?: ECommentSortBy;
      offset?: number;
   }) {
      try {
         const token = localStorage.getItem(tokenKey);
         const res = await fetch(
            `${apiDomain}/www/social-feed/comment/paginate`,
            {
               method: "GET",
               headers: {
                  "X-Requested-With": "XMLHttpRequest",
                  id: id,
                  ...(!token
                     ? undefined
                     : { Authorization: `Bearer ${token}` }),
                  ...(!page ? undefined : { page: String(page) }),
                  ...(!offset ? undefined : { offset: String(offset) }),
                  limit: !limit ? String(20) : String(limit),
                  sort: !sort ? "desc" : encodeURI(sort),
                  "sort-by": !sortBy
                     ? ECommentSortBy.createdAt
                     : encodeURI(sortBy),
                  ...(!commentId ? undefined : { "comment-id": commentId }),
               },
            }
         );

         if (!res.ok) {
            return new ModelError((await res.json()) as TModelError);
         }

         const payload = (await res.json()) as TPayload<{
            ["paginator"]: TPaginate<ISocialFeedComment>;
         }>;

         const paginate = new Paginate(
            payload?.data?.paginator,
            SocialFeedComment
         );

         return paginate;
      } catch (error: any) {}
   }

   /**
    *
    * @param content
    * @param socialFeedId
    * @param socialFeedCommentId
    * @param replyToUserIds
    * @returns
    */
   public static async create(
      content: string,
      socialFeedId: string,
      socialFeedCommentId: string | null,
      replyToUserIds: string[] = []
   ) {
      const token = localStorage.getItem(tokenKey);

      if (!token) {
         return new ModelError({
            httpCode: 401,
            message: "401 Unauthorized error",
            errors: {
               process: [
                  {
                     code: "401",
                     message: "401 Unauthorized error",
                  },
               ],
            },
         });
      }
      try {
         const response = await fetch(`${apiDomain}/www/social-feed/comment`, {
            method: "POST",
            headers: {
               "X-Requested-With": "XMLHttpRequest",
               Authorization: `Bearer ${token}`,
               "Content-Type": "application/json",
               code: "web",
            },
            body: JSON.stringify({
               content: content,
               socialFeedId: socialFeedId,
               socialFeedCommentId: socialFeedCommentId ?? null,
               replyToUserIds: replyToUserIds,
            }),
         });

         if (!response.ok) {
            return new ModelError((await response.json()) as TModelError);
         }

         const payload = (await response.json()) as TPayload<{
            newComment: ISocialFeedComment;
         }>;

         return new SocialFeedComment(payload.data.newComment);
      } catch (error: any) {}
   }

   /**
    *
    * @param id
    * @returns
    */
   public static async delete(id: string) {
      const token = localStorage.getItem(tokenKey);
      if (!token) {
         return new ModelError({
            httpCode: 401,
            message: "401 Unauthorized error",
            errors: {
               process: [
                  {
                     code: "401",
                     message: "401 Unauthorized error",
                  },
               ],
            },
         });
      }
      try {
         const response = await fetch(`${apiDomain}/www/social-feed/comment`, {
            method: "DELETE",
            headers: {
               "X-Requested-With": "XMLHttpRequest",
               Authorization: `Bearer ${token}`,
               "Content-Type": "application/json",
               ids: id,
            },
         });

         if (!response.ok) {
            return new ModelError((await response.json()) as TModelError);
         }

         return;
      } catch (error: any) {
         return new ModelError({
            httpCode: 500,
            message: error.message as string,
            errors: {
               process: [
                  {
                     code: "process.error.5000",
                     message: "Process error on handling.",
                  },
               ],
            },
         });
      }
   }

   /**
    *
    * @param socialFeedCommentId
    * @param file
    * @returns
    */
   public static async commentImage(file: File, socialFeedCommentId: string) {
      try {
         const token = localStorage.getItem(tokenKey);

         if (!token) {
            return new ModelError({
               ["httpCode"]: 401,
               ["message"]: "You don't have permission to access this API.",
               ["errors"]: {
                  ["process"]: [
                     {
                        ["code"]: "process.error.401",
                        ["message"]: "Đăng xuất không thành công.",
                     },
                  ],
               },
            });
         }

         const data = new FormData();
         data.append("file", file);
         data.append("socialFeedCommentId", socialFeedCommentId);
         const res = await fetch(`${apiDomain}/www/social-feed/comment/image`, {
            method: "POST",
            headers: {
               Authorization: `Bearer ${token}`,
               "X-Requested-With": "XMLHttpRequest",
            },
            body: data,
         });
         if (res instanceof ModelError) {
            return res;
         }
         const payload = (await res.json()) as TPayload<{
            comment: SocialFeedComment;
         }>;
         return new SocialFeedComment(payload.data.comment);
      } catch (error) {
         return new ModelError(error);
      }
   }
}

export default SocialFeedComment;
