import Model, { ModelError, Paginate, TPaginate, TPayload } from "./model";
import User, { IUser } from "./user.model";

export enum EUserSubscribeSortBy {
   createdAt = "createdAt",
}

export enum EUserSubscribeStatus {
   enable = "enable",
   delete = "delete",
}

export interface IUserSubscribe extends Model {
   target: {
      doc: string | User;
      model: string | null;
   };
   options: {
      status: EUserSubscribeStatus;
   };
   userId: string | null;
   __item?: User;
   ["__user"]?: User;
}

export default class UserSubscribe
   extends Model<IUserSubscribe>
   implements IUserSubscribe
{
   public "target"!: {
      doc: string | User;
      model: string | null;
   };
   public "options": {
      status: EUserSubscribeStatus;
   };
   public "userId": string | null;
   public "__item"?: User;
   public "__user"?: User;

   constructor(data?: IUserSubscribe) {
      super(data);
      Object.assign(this, data);

      if (this.__item) {
         this.__item = new User(this.__item);
      }
      if (this.__user) {
         this.__user = new User(this.__user);
      }
      if (this.target.doc && typeof this.target.doc === "object") {
         this.target.doc = new User(this.target.doc);
      }
   }

   public static async subscribeUser(userTargetId: string) {
      const response = await Model.fetchPost(
         null,
         null,
         {
            targetId: userTargetId,
         },
         "user/subscribe",
         true
      );
      if (response instanceof ModelError) return response;
      const payload = (await (response as Response).json()) as TPayload<{
         newSubscribe: UserSubscribe;
      }>;

      return payload.data.newSubscribe;
   }
   public static async unSubscribeUser(subscribeId: string) {
      const response = await Model.fetchDelete(
         null,
         {
            id: subscribeId,
         },
         "user/subscribe",
         true
      );
      if (response instanceof ModelError) return response;
      const payload = (await (response as Response).json()) as TPayload<{
         deletedSubscribe: UserSubscribe;
      }>;

      return payload.data.deletedSubscribe;
   }

   /**
    *
    * @param profileId
    * @returns
    */
   public static async subscriberPaginate({
      profileId,
   }: {
      profileId: string;
   }) {
      try {
         const response = await Model.fetchData({
            method: "GET",
            headers: {
               "profile-id": profileId,
               limit: "100",
            },
            endPoint: "profile/subscriber/paginate",
         });

         if (response instanceof ModelError) {
            return response;
         }

         const payload = (await response.json()) as TPayload<{
            ["paginator"]: TPaginate<IUserSubscribe>;
         }>;
         const paginate = new Paginate(payload?.data?.paginator, UserSubscribe);
         return paginate;
      } catch (error: any) {
         return error;
      }
   }

   /**
    *
    * @param profileId
    * @returns
    */
   public static async subscribingPaginate({
      profileId,
   }: {
      profileId: string;
   }) {
      try {
         const response = await Model.fetchData({
            method: "GET",
            headers: {
               "profile-id": profileId,
               limit: "100",
            },
            endPoint: "profile/subscribing/paginate",
         });

         if (response instanceof ModelError) {
            return response;
         }

         const payload = (await response.json()) as TPayload<{
            ["paginator"]: TPaginate<IUserSubscribe>;
         }>;
         const paginate = new Paginate(payload?.data?.paginator, UserSubscribe);
         return paginate;
      } catch (error: any) {
         return error;
      }
   }
}
