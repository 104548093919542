import Script from "next/script";
import { useEffect } from "react";
import { isMobile, isIOS } from "react-device-detect";

export default function NextScriptCustom() {
   useEffect(() => {
      window.fbAsyncInit = function () {
         if (!window.FB) {
            return;
         }
         window.FB?.init({
            appId: "711063823782715",
            cookie: true,
            xfbml: true,
            version: "v20.0",
         });
         window.FB?.logPageView();
         window.FB?.getLoginStatus(function (response) {
            // statusChangeCallback(response); // Returns the login status.
         });
      };
   }, []);
   return (
      <>
         {/* <PageView />
         <ScriptLazy /> */}
         {/* GT manager */}
         <Script
            id="gtag-code"
            async
            strategy="afterInteractive"
            src={`https://www.googletagmanager.com/gtm.js?id="${process.env.NEXT_PUBLIC_GA_TRACKING_ID}"`}
         ></Script>
         <Script id="google-tag-manager" strategy="afterInteractive">
            {`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
               new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
               j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
               'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
               })(window,document,'script','dataLayer',"${process.env.NEXT_PUBLIC_GA_TRACKING_ID}");
            `}
         </Script>
         {/* GA Manager */}
         {/* <Script
            id="gtag-code"
            async
            strategy="afterInteractive"
            src={`https://www.googletagmanager.com/gtag/js?id="${process.env.NEXT_PUBLIC_ANALYTICS_TRACKING_ID}"`}
         ></Script>
         <Script id="google-tag-manager" strategy="afterInteractive">
            {`
               window.dataLayer = window.dataLayer || [];
               function gtag(){dataLayer.push(arguments);}
               gtag('js', new Date());

               gtag('config', "${process.env.NEXT_PUBLIC_ANALYTICS_TRACKING_ID}",{ debug_mode: true });
            `}
         </Script> */}

         <Script
            async
            defer
            src="https://connect.facebook.net/en_US/sdk.js"
            crossOrigin="anonymous"
         ></Script>
         <Script
            defer
            id="facebook-jssdk"
            src="https://connect.facebook.com/en_US/sdk.js"
         ></Script>

         {/* heatmap for visualize user behavior */}
         {(!isMobile || isIOS) && (
            <Script
               id="heatmap"
               async
               defer
               dangerouslySetInnerHTML={{
                  __html: `
                        (function (h, e, a, t, m, p) {
                           m = e.createElement(a); 
                           m.async = !0;
                           m.src = t;
                           p = e.getElementsByTagName(a)[0];
                           p.parentNode.insertBefore(m, p);
                        })(window, document, 'script', 'https://u.heatmap.it/log.js');`,
               }}
            ></Script>
         )}

         {/* Core dash script */}
         {/* <Script id="coredash" strategy="afterInteractive" src="https://rum.corewebvitals.io/cwv/661ca5d1c18a61f4fb757938.js" />
         <Script id="wincoredas" strategy="afterInteractive" dangerouslySetInnerHTML={{ __html: `window.__CWVL='mypagelabel';` }}></Script> */}
      </>
   );
}
