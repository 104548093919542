/* eslint-disable no-useless-escape */
import * as yup from "yup";

export const nameRegex = /[a-zA-Z][a-zA-Z ]{2,}/i;

export const emailRegex =
   /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i;

export const urlRegex =
   /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/i;

export const phoneRegex = new RegExp(/^(0|\+84)(\d{9})$/, "i");

export const numberLengthRegex = new RegExp(/([0-9]{6})\b/, "i");

export default yup;
