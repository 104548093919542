import axios from "axios";
import { apiDomain, tokenKey } from "./__variables";
import Model, {
   ModelError,
   Paginate,
   TModelError,
   TPaginate,
   TPayload,
} from "./model";
import ActualWork, { IActualWork } from "./actualWork.model";
import { IUserBookmark } from "./userBookmark.model";

export interface ISearchSuggetions {
   histories: string[];
   suggestions: string[];
   limit: number;
}

export interface ISearch {}

export class SearchModel extends Model<ISearch> {
   constructor(data?: ISearch) {
      super(data);
   }

   public static async fetchSuggetions({
      keywords,
      limit,
   }: {
      keywords?: string;
      limit: string;
   }) {
      try {
         const token = localStorage.getItem(tokenKey);
         const response = await fetch(`${apiDomain}/www/search/suggetions`, {
            ["method"]: "GET",
            ["headers"]: {
               ["Origin"]: "https://spacet.vn",
               ["X-Requested-With"]: "XMLHttpRequest",
               ["sort"]: "desc",
               ...(token
                  ? { ["Authorization"]: `Bearer ${token}` }
                  : undefined),
               ...(keywords ? { keywords: encodeURI(keywords) } : undefined),
               ...(limit ? { limit } : undefined),
            },
         });
         if (!response.ok) {
            return new ModelError(await response.json());
         }

         const payload = (await response.json()) as TPayload<{
            ["histories"]: string[];
            ["suggestions"]: string[];
            ["limit"]: number;
         }>;

         return payload.data;
      } catch (error) {}
   }

   public static async saveKeywords({ keywords }: { keywords: string }) {
      const token = localStorage.getItem(tokenKey);
      try {
         const response = await fetch(`${apiDomain}/www/search/history`, {
            ["method"]: "POST",
            ["headers"]: {
               ["Origin"]: "https://spacet.vn",
               ["X-Requested-With"]: "XMLHttpRequest",
               ...(!token
                  ? undefined
                  : { ["Authorization"]: `Bearer ${token}` }),
               ...(!keywords
                  ? undefined
                  : { ["keywords"]: encodeURI(keywords) }),
            },
         });
         if (!response.ok) {
            return new ModelError(await response.json());
         }

         const payload = (await response.json()) as TPayload<{
            ["keywords"]: string;
         }>;

         return payload.data;
      } catch (error) {}
   }

   public static async deleteAllSearchHistories() {
      try {
         const token = localStorage.getItem(tokenKey);
         const res = await fetch(`${apiDomain}/www/search/histories`, {
            method: "DELETE",
            headers: {
               ["Origin"]: "https://spacet.vn",
               ["X-Requested-With"]: "XMLHttpRequest",
               ...(!token
                  ? undefined
                  : { ["Authorization"]: `Bearer ${token}` }),
            },
         });

         if (!res.ok) {
            return new ModelError(await res.json());
         }

         return { status: true };
      } catch (error) {}
   }

   public static async deleteSearchHistory(keyword) {
      try {
         const token = localStorage.getItem(tokenKey);
         const res = await fetch(`${apiDomain}/www/search/history`, {
            ["method"]: "DELETE",
            ["headers"]: {
               ["Origin"]: "https://spacet.vn",
               ["X-Requested-With"]: "XMLHttpRequest",
               ["Content-Type"]: "application/json",
               ...(!token
                  ? undefined
                  : { ["Authorization"]: `Bearer ${token}` }),
            },
            ["body"]: JSON.stringify({
               ...(keyword === undefined
                  ? undefined
                  : { ["keyword"]: keyword }),
            }),
         });

         if (!res.ok) {
            return new ModelError(await res.json());
         }

         return { status: true };
      } catch (error) {}
   }

   public static async searchResultByKeyword({
      page,
      limit,
      sort,
      sortBy,
      keywords,
      cookiesToken,
   }: {
      page?: number;
      limit?: number;
      sort?: string;
      sortBy?: string;
      keywords?: string;
      cookiesToken?: string;
   }) {
      const res = await this.fetchData({
         endPoint: "search",
         method: "GET",
         token: cookiesToken,
         headers: {
            page: page ? String(page) : "1",
            limit: limit ? String(limit) : "10",
            sort: sort ? String(sort) : "desc",
            ["sort-by"]: sortBy ? String(sort) : "createdAt",
            ...(keywords ? { keywords: encodeURI(keywords) } : undefined),
         },
      });

      if (res instanceof ModelError) {
         return res;
      }
      const payload = (await res.json()) as TPayload<any>;
      return payload.data;
   }
}
