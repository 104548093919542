import { initializeApp } from "firebase/app";
import {
   getMessaging,
   getToken,
   onMessage,
   isSupported,
} from "firebase/messaging";
import firebaseConfig, { cacheFirebaseLocalToken } from "../config/firebase";
import { isClient } from "utils";

let app, messaging, db;

export const useFirebaseMessaging = () => {};

if (isClient) {
   isSupported()
      .then((status) => {
         if (status) {
            app = initializeApp(firebaseConfig);
            messaging = getMessaging(app);
            getToken(messaging, {
               vapidKey: firebaseConfig.FIREBASE_USEPUBLICVAPIDKEY,
            })
               .then((currentToken) => {
                  if (currentToken) {
                     requestPermission();
                     localStorage.setItem(
                        cacheFirebaseLocalToken,
                        currentToken
                     );
                  } else {
                  }
               })
               .catch((err) => {});
         }
      })
      .catch((e) => {});

   function requestPermission() {
      Notification.requestPermission().then((permission) => {
         if (permission === "granted") {
         }
      });
   }
}
