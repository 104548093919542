/* eslint-disable @next/next/no-img-element */
import { useRouter } from "next/router";
import React, {
   ReactNode,
   useCallback,
   useEffect,
   useImperativeHandle,
   useMemo,
   useRef,
   useState,
} from "react";
import { useTranslation } from "react-i18next";
import debounce from "lodash/debounce";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import NorthSharpIcon from "@mui/icons-material/NorthSharp";
import SouthSharpIcon from "@mui/icons-material/SouthSharp";
import AddSharpIcon from "@mui/icons-material/AddSharp";
import { yupResolver } from "@hookform/resolvers/yup";
import Image from "next/image";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import DrawerBase from "components/drawer";
import yup from "../../core/yupPatern";
import Keyword, { EType as ETypeKeyword } from "../../models/keyword.model";
import { ModelError, TModelError } from "../../models/model";
import SocialFeed, {
   EBannerType,
   ESocialFeedPostDeviceType,
} from "../../models/socialFeed.model";
import SocialFeedMedia from "../../models/socialFeedMedia.model";
import User from "../../models/user.model";
import { tokenKey } from "../../models/__variables";
import store, { RootState } from "../../state";
import {
   addFirstSocialFeed,
   setTotalSocialFeed,
   updateSocialFeed,
} from "../../state/action/socialFeed";
import { resizeImage } from "../../utils";
import ImageTagProduct from "../../components/imageTagProduct";
import DialogNotification from "../../components/dialogNotification/DialogNotification";
import Dropzone from "react-dropzone-uploader";
import "react-dropzone-uploader/dist/styles.css";
import LayoutMedia from "../../components/layoutMedia/LayoutMedia";
import styles from "./SocialFeed.module.scss";
import {
   Dialog,
   DialogContent,
   DialogTitle,
   Drawer,
   Popover,
} from "@mui/material";
import classNames from "classnames";
import ProductTaggingImage from "../../models/productTaggingImage.model";
import { gridBreakpoints } from "../../config";
import useAutosizeTextArea from "../../hook/useAutosizeTextArea";
import { toggleModalAuth, getProfile } from "state/action/auth";
import { EAuthType } from "@Components/modal/auth/const";
import InputHashtag, {
   switchHashTagValtoHashTagArr,
} from "@Components/inputHashtag";
import { parseArrayToString } from "utils/social-feed";
import InputHyperlinkv2, {
   NewRefObject as NewRefObjectv2,
} from "@Components/inputHyperlink/v2";
import Slider from "react-slick";
import Button from "@Components/button";
import recommendImageAddTagging from "./images/recommend-add-tagging.gif";
import { UserStorages } from "storages";
import EmojiPicker from "emoji-picker-react";
import useCustomHookState from "hook/useCustomHookState";
import { isMobile } from "react-device-detect";
import { logclient } from "utils/log";
// import { logclient } from "utils/log";

interface IAuthRedux {
   user: User;
}

// enum EElement {
//    input = "input",
//    textarea = "textarea",
// }
// enum EType {
//    number = "number",
//    text = "text",
//    email = "email",
//    file = "file",
// }
enum EName {
   content = "content",
}

export interface IMedia {
   media: SocialFeedMedia;
   mediasId: string;
   [ETypeKeyword.space]: Keyword[];
   [ETypeKeyword.color]: Keyword[];
   [ETypeKeyword.interiorStyle]: Keyword[];
}
interface IFormInputs {
   content: string;
}

interface IProps {
   refAction: any;
   onClose?: () => void;
   onChangeSocialFeed?: (socialFeed: SocialFeed) => void;
   onAddSocialFeed?: (socialFeed: SocialFeed) => void;
   socialFeed?: SocialFeed;
   type?: number;
   showFirstHashtag?: boolean;
   onChangeTypeDialog?: (value: "medium" | "large") => void;
   onSchedulePost?: any;
}

// const form: {
//    element: EElement;
//    type: EType;
//    name: EName;
//    placeholder: string;
//    label?: string;
//    col?: number;
//    icon?: string;
//    attention?: string[];
//    auth?: boolean;
// }[] = [
//    {
//       element: EElement.textarea,
//       type: EType.text,
//       name: EName.content,
//       placeholder: "Nhập chia sẻ của bạn ở đây...",
//    },
// ];

// interface IState {
//    keywordGroupsByType?: {
//       [type in ETypeKeyword]?: Keyword[];
//    };
// }

export default function CreateSocialFeed({
   refAction,
   onClose,
   onChangeSocialFeed,
   onAddSocialFeed,
   socialFeed,
   type,
   showFirstHashtag,
   onSchedulePost,
}: IProps) {
   const { t } = useTranslation();
   const { user } = useSelector<RootState>((state) => state.auth) as {
      user: User;
   };
   const { total } = useSelector<RootState>((state) => state.socialFeed) as {
      total: number;
   };
   const {
      modalPost: { bannerType },
   } = useSelector<RootState>((state) => state.socialFeed) as {
      modalPost: { bannerType: EBannerType };
   };
   const [values, setValues] = useState<IFormInputs>({
      content: socialFeed?.info?.descriptionWeb ?? "",
   });
   const [success, setSuccess] = useState<string | ReactNode | undefined>(
      undefined
   );
   const [errorsMes, setErrorsMes] = useState<TModelError>();
   const [errorsMesImage, setErrorsMesImage] = useState<string | undefined>("");
   const [errorsMesSizeImage, setErrorsMesSizeImage] = useState<
      string | undefined
   >("");
   const [medias, setMedias] = useState<IMedia[]>([]);

   // const [loadingUploadImage, setLoadingUploadImage] = useState<number>(0);
   const [draftId, setDraftId] = useState<string | undefined>(undefined);
   const auth = useSelector<RootState>((state) => state.auth) as IAuthRedux;
   const [isTaggingProduct, setIsTaggingProduct] = useState<boolean>(false);
   const [isTaggingProductMb, setIsTaggingProductMb] = useState<boolean>(false);
   const [firstUploadImage, setFirstUploadImage] = useState<boolean>(false);
   const [toggleRemindTaggingExit, setToggleRemindTaggingExit] =
      useState(false);

   const ref = useRef(null);
   const refInputHyperlink = useRef(null) as NewRefObjectv2;
   const refInputHyperlinkControl = useRef(null) as any;

   const router = useRouter();
   const dispatch = useDispatch();
   const [mediaSelected, setMediaSelected] = useState<number>(0);
   const descriptionPostRef = useRef<any>(null);
   // const [isDirty, setIsDirty] = useState(false);
   const [newTaggings, setNewTaggings] = useState<ProductTaggingImage[]>([]);
   const [hashtag, setHashtag] = useState({
      hashTagValue: "",
      hashTagValueError: "",
   });
   // const schema = yup.object().shape({
   //    [EName.content]: yup
   //       .string()
   //       .trim()
   //       .required("Vui lòng nhập nội dung")
   //       .max(5000, "Nội dung tối đa là 5000 kí tự")
   //       .min(3, "Nội dung tối thiểu là 3 kí tự"),
   // });

   const sliderMediaRef = React.useRef<any>();

   const { state, setState } = useCustomHookState<any>({});

   // const { register, handleSubmit, formState, reset, watch, getValues } = useForm<IFormInputs>({
   //    resolver: yupResolver(schema),
   //    defaultValues: {
   //       [EName.content]: socialFeed?.info?.description ?? "",
   //    },
   // });

   // hastag set init edit
   useEffect(() => {
      if (socialFeed && socialFeed.hashtags) {
         setHashtag((prev) => ({
            ...prev,
            hashTagValue: parseArrayToString(socialFeed.hashtags),
         }));
      }
   }, [socialFeed]);

   useAutosizeTextArea(
      descriptionPostRef.current,
      values?.[EName.content] ?? ""
   );

   useEffect(() => {
      const token = localStorage.getItem(tokenKey);
      const convertStringToBase64 = btoa(router?.pathname);
      if (!auth.user || !token) {
         // router.push(`/?login=true&from=${convertStringToBase64}`);
         store.dispatch(
            toggleModalAuth({ visible: true, type: EAuthType.signin })
         );
      }
      setMedias([
         ...(medias ?? []),
         ...(socialFeed?.__medias?.map((doc) => ({
            media: doc,
            mediasId: doc._id,
            [ETypeKeyword.space]: [],
            [ETypeKeyword.color]: [],
            [ETypeKeyword.interiorStyle]: [],
         })) ?? []),
      ]);
   }, []);

   useEffect(() => {
      return () => {
         setErrorsMesImage(undefined);
         setErrorsMes(undefined);
      };
   }, []);

   // useEffect(() => {
   //    isDirty && validate();
   // }, [values, isDirty]);

   // useEffect(() => {
   //    isDirty && validateImage(medias);
   // }, [medias, isDirty]);

   // useEffect(() => {
   //    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
   //       event.preventDefault();
   //       event.returnValue = true;
   //    };

   //    if (medias?.length || isDirty) {
   //       window.addEventListener("beforeunload", handleBeforeUnload);
   //    }

   //    return () => window.removeEventListener("beforeunload", handleBeforeUnload);
   // }, [medias, isDirty]);

   // useEffect(() => {
   //    if (!draftId && isDirty && !socialFeed) {
   //       (async () => {
   //          const galleryDraft = await SocialFeed.createDraft(type === 0 ? "socialFeed" : "qna");
   //          if (galleryDraft instanceof ModelError) {
   //             return;
   //          }
   //          setDraftId(galleryDraft?._id);
   //       })();
   //    }
   // }, [isDirty]);

   const validateImage = (images: any[]) => {
      if (!images.length) {
         setErrorsMesImage("Vui lòng gửi ít nhất 1 hình ảnh");
         return;
      }
      if (images.length > 10) {
         setErrorsMesImage("Vui lòng gửi tối đa 10 hình ảnh");
         return;
      }
      return true;
   };

   const maxSizeLoadImage = (size: number) => {
      const validSize = size / 1024 / 1024 < 25;
      return validSize;
   };
   const validateSizeImage = (images?: any[] | null) => {
      if (!images || images.length === 0) {
         return false;
      }

      let validImage: any[] = [];
      // validate image
      for (let i = 0; i < images.length; i++) {
         const size = images[i].size;
         const validSize = maxSizeLoadImage(size);
         if (validSize) {
            validImage.push(images[i]);
         }
      }

      // set error but doesn't break the form
      if (validImage.length != images.length) {
         setErrorsMesSizeImage(
            "Chỉ chấp nhận định dạng jpg, jpeg, png, webp files dưới 25mb"
         );
      } else {
         setErrorsMesSizeImage("undefined");
      }

      return validImage.length > 0 ? validImage : false;
   };

   const onChangeImageInput = async (
      e: React.ChangeEvent<HTMLInputElement>
   ) => {
      try {
         e.preventDefault();
         if (!e.target.files) {
            return;
         }
         setErrorsMesImage(undefined);

         const validImage = validateImage(Array.from(e.target.files));
         if (!validImage) {
            return;
         }

         const validImagesSize = validateSizeImage(Array.from(e.target.files));
         if (!validImagesSize) {
            return;
         }

         let socialFeedId: string | undefined = socialFeed?._id ?? draftId;

         // create draft Id
         if (!socialFeedId) {
            const galleryDraft = await SocialFeed.createDraft(
               type === 0 ? "socialFeed" : "qna"
            );
            if (galleryDraft instanceof ModelError) {
               return;
            }
            socialFeedId = galleryDraft._id;
            setDraftId(galleryDraft._id);
         }

         if (!socialFeedId) {
            setErrorsMesImage("Có lỗi xảy ra. Vui lòng thử lại sau!");
            return;
         }

         const tempMedias = [...medias];
         const uploadPromises: Promise<SocialFeedMedia | ModelError>[] = [];

         // upload images
         for (let i = 0; i < e.target.files.length; i++) {
            const size = e.target.files[i].size;
            const isValidSize = maxSizeLoadImage(size);
            if (isValidSize) {
               const image = (await resizeImage(e.target.files[i])) as File;
               uploadPromises.push(SocialFeedMedia.upload(image, socialFeedId));
            }
         }
         e.target.value = "";

         if (uploadPromises.length === 0) {
            return;
         }

         const results = await Promise.all(uploadPromises);
         results.forEach((result) => {
            if (result instanceof ModelError) {
               setErrorsMesImage(result.firstMessageErr);
               return;
            }
            tempMedias.push({
               media: result,
               mediasId: result._id,
               [ETypeKeyword.space]: [],
               [ETypeKeyword.color]: [],
               [ETypeKeyword.interiorStyle]: [],
            });
         });
         setMedias(tempMedias);
         // setLoadingUploadImage(0);
         // validateImage(tempMedias);
      } catch (error) {}
   };

   // const onChange = (e: any) => {
   //    validate();
   //    setErrorsMes(undefined);
   // };

   const validate = () => {
      let errors: {
         [key: string]: {
            ["code"]: string | number;
            ["message"]: string;
         }[];
      } = {};
      if (!values?.content?.trim().length && medias.length === 0) {
         errors.content = [{ message: "Vui lòng nhập nội dung", code: 401 }];
      } else {
         if (
            (values.content.trim().length < 3 && medias.length === 0) ||
            values.content === "</br>"
         ) {
            errors.content = [
               { message: "Nội dung tối thiểu là 2 kí tự", code: 401 },
            ];
         }
         if (values.content.trim().length > 5000) {
            errors.content = [
               { message: "Nội dung tối đa là 5000 kí tự", code: 401 },
            ];
         }
      }
      const errorObj = Object.keys(errors).length
         ? { httpCode: 401, message: "The input value is invalid", errors }
         : undefined;
      return errorObj;
   };

   const postData = async ({
      publishAt,
      ...form
   }: { publishAt?: string } & Partial<IFormInputs>) => {
      if (Boolean(errorsMesImage || errorsMes || hashtag.hashTagValueError))
         return;

      const validateError = validate();
      if (validateError?.message) return;

      const mediaIds = medias.map((item: any) => item?.media?._id) ?? [];

      if (socialFeed) {
         const hashtagsArr = switchHashTagValtoHashTagArr(hashtag.hashTagValue);
         const submission = await SocialFeed.edit({
            id: socialFeed._id,
            code: ESocialFeedPostDeviceType.web,
            content: values.content,
            mediaIds,
            hashtags: hashtagsArr,
         });
         if (submission instanceof ModelError) {
            setErrorsMes(submission);
            return;
         }

         setSuccess("Lưu bài viết thành công!");
         const socialFeedUpdated = await getDetailSocialFeed(submission._id);
         if (!socialFeedUpdated) return;
         onChangeSocialFeed?.(socialFeedUpdated);
         // Disabled update social feed at tagging page
         dispatch(updateSocialFeed(socialFeedUpdated));
      } else {
         let _draftId = draftId;
         if (!_draftId) {
            const galleryDraft = await SocialFeed.createDraft(
               type === 0 ? "socialFeed" : "qna"
            );
            if (galleryDraft instanceof ModelError) {
               return;
            }
            _draftId = galleryDraft?._id;
            setDraftId(_draftId);
         }
         if (_draftId) {
            const hashtagValues: string[] = [
               ...switchHashTagValtoHashTagArr(hashtag.hashTagValue),
            ];
            if (showFirstHashtag) {
               hashtagValues.unshift("hello");
            }

            const submission = await SocialFeed.create(
               {
                  content: values.content,
                  mediaIds,
                  publishAt,
                  hashtags: hashtagValues,
               },
               _draftId
            );
            if (submission instanceof ModelError) {
               setErrorsMes(submission);
               return;
            }

            if (publishAt) {
               setSuccess("Đã lên lịch đăng bài chia sẻ.");
            } else {
               setSuccess(
                  "Bài viết đăng thành công! Xu sẽ tặng vào tài khoản của bạn sau khi nội dung bài được duyệt."
               );
            }

            const newSocialFeed = await getDetailSocialFeed(
               submission._id ?? ""
            );
            if (!newSocialFeed) return;

            // add to fist item if it is hashtag page and match current hashtag
            if (["/feed/hashtag/[hashtag]"].includes(router.pathname)) {
               const hashtag = router.query.hashtag as string;
               if (newSocialFeed.hashtags?.includes(hashtag)) {
                  dispatch(addFirstSocialFeed(newSocialFeed));
                  dispatch(setTotalSocialFeed(total + 1));
                  onAddSocialFeed?.(newSocialFeed);
               }
            } else {
               dispatch(addFirstSocialFeed(newSocialFeed));
               dispatch(setTotalSocialFeed(total + 1));
               onAddSocialFeed?.(newSocialFeed);
            }
            store.dispatch(getProfile());
         }
      }
   };
   // const handleUpdateTaggingSocialFeed = async () => {
   //    try {
   //       if (!socialFeed?._id) return;
   //       const res = await SocialFeed.getSocialFeedById(socialFeed._id);
   //       if (res instanceof ModelError) return;
   //       if (!res.socialFeed) return;
   //       const newSocialFeed = { ...socialFeed, __medias: res.socialFeed.__medias };
   //       onChangeSocialFeed && onChangeSocialFeed(newSocialFeed);
   //       dispatch(updateSocialFeed(newSocialFeed));
   //    } catch (error) {}
   // };

   const getDetailSocialFeed = async (id: string) => {
      try {
         if (!id) return;
         const newSocialFeed = await SocialFeed.paginate({ ids: [id] });
         if (newSocialFeed instanceof ModelError) {
            return;
         }
         return newSocialFeed.docs[0];
      } catch (errror) {}
   };

   const removeImageFromList = (index: number) => {
      medias.splice(index, 1);
      setMedias([...medias]);
      validateImage(medias);
   };
   const removeListImage = () => {
      setMedias([]);
      setErrorsMesImage(undefined);
      setErrorsMesSizeImage(undefined);
   };

   const handleChangeStatus = debounce(
      (file: any, status: any, allFiles: any[]) => {
         handleSubmitImage(file, allFiles);
      },
      500
   );

   const handleSubmitImage = async (files: any, allFiles: any) => {
      setIsTaggingProduct(true);
      const isShowedFirstUploadImage = UserStorages.isShowedWithUserId(
         "f:p:remind",
         user?._id
      );
      if (medias && medias.length <= 0 && !isShowedFirstUploadImage) {
         setFirstUploadImage(true);
         UserStorages.setShowedWithUserId("f:p:remind", user?._id, {
            show: true,
         });
      }
      try {
         setErrorsMesImage(undefined);
         let draftIdGallery: string | undefined = socialFeed?._id ?? draftId;

         // setLoadingUploadImage(allFiles?.length);

         // create draft Id
         if (!draftIdGallery) {
            const galleryDraft = await SocialFeed.createDraft(
               type === 0 ? "socialFeed" : "qna"
            );
            if (galleryDraft instanceof ModelError) {
               return;
            }
            draftIdGallery = galleryDraft._id;
            setDraftId(galleryDraft._id);
         }

         const tempMedias = [...medias];
         const uploadPromises: Promise<SocialFeedMedia | ModelError>[] = [];

         // upload images
         for (let i = 0; i < allFiles?.length; i++) {
            if (draftIdGallery && allFiles[i].meta?.status === "done") {
               const image = (await resizeImage(allFiles[i]?.file)) as File;
               uploadPromises.push(
                  SocialFeedMedia.upload(image, draftIdGallery)
               );
            }
         }
         const results = await Promise.all(uploadPromises);
         results.forEach((result) => {
            if (result instanceof ModelError) {
               for (const [key, value] of Object.entries(result.errors ?? {})) {
                  if (value.length > 0) {
                     setErrorsMesImage(value[0].message);
                  }
               }
               return;
            }
            tempMedias.push({
               media: result,
               mediasId: result._id,
               [ETypeKeyword.space]: [],
               [ETypeKeyword.color]: [],
               [ETypeKeyword.interiorStyle]: [],
            });
            if (result) {
               setTimeout(() => {
                  allFiles.forEach((f: any) => f.remove());
               }, 1000);
            }
         });
         setMedias(tempMedias);
         // setLoadingUploadImage(0);
         validateImage(tempMedias);
         setIsTaggingProduct(true);
      } catch (error) {}
   };

   const toogleDialogTagging = () => {
      setNewTaggings([]);
      if (window && window.innerWidth < gridBreakpoints.md) {
         setIsTaggingProductMb(!isTaggingProductMb);
      } else setIsTaggingProduct(!isTaggingProduct);
      setMediaSelected(0);
   };

   const handleSelectMedia = (
      index: number,
      e: React.MouseEvent<HTMLDivElement, MouseEvent>
   ) => {
      e?.preventDefault();
      setMediaSelected(index);
      sliderMediaRef.current?.slickGoTo(index);
      // const mediaSelectedElm = document.querySelector(`#media-community-post-${index}`);
      // mediaSelectedElm && mediaSelectedElm.scrollIntoView();
   };

   const updateMedia = async ({
      mediaId,
      position,
      description,
   }: {
      mediaId: string;
      position?: number;
      keywordIds?: string[];
      description?: string;
   }) => {
      try {
         if (!mediaId) return;
         const update = await SocialFeedMedia.update({
            position,
            id: mediaId,
            description,
         });
         if (update instanceof ModelError) {
            return update;
         }
         return update;
      } catch (error) {
         return new ModelError(error);
      }
   };

   const handleMoveToAbove = (
      index: number,
      e: React.MouseEvent<HTMLElement, MouseEvent>
   ) => {
      e.stopPropagation();
      const idFeed = socialFeed?._id ?? draftId;
      if (index === 0 || !medias || !idFeed) return;
      const updatePromiseNext = updateMedia({
         mediaId: medias[index - 1].mediasId,
         position: index,
      });
      const updatePromiseCurrent = updateMedia({
         mediaId: medias[index].mediasId,
         position: index - 1,
      });
      Promise.all([updatePromiseNext, updatePromiseCurrent])
         .then((result) => {
            if (
               updatePromiseNext instanceof ModelError ||
               updatePromiseNext instanceof ModelError
            )
               return;
            [medias[index - 1], medias[index]] = [
               medias[index],
               medias[index - 1],
            ];
            if (mediaSelected === index) {
               setMediaSelected(index - 1);
               sliderMediaRef.current?.slickGoTo(index - 1);
            } else if (mediaSelected === index - 1) {
               setMediaSelected(index);
               sliderMediaRef.current?.slickGoTo(index);
            }
            setMedias([...medias]);
         })
         .catch((error) => {});
   };

   const handleMoveToBelow = async (
      index: number,
      e: React.MouseEvent<HTMLElement, MouseEvent>
   ) => {
      e.stopPropagation();
      const idFeed = socialFeed?._id ?? draftId;
      if (index === medias?.length - 1 || !medias || !idFeed) return;
      const updatePromiseNext = updateMedia({
         mediaId: medias[index + 1].mediasId,
         position: index,
      });
      const updatePromiseCurrent = updateMedia({
         mediaId: medias[index].mediasId,
         position: index + 1,
      });
      Promise.all([updatePromiseNext, updatePromiseCurrent])
         .then((result) => {
            if (
               updatePromiseNext instanceof ModelError ||
               updatePromiseNext instanceof ModelError
            )
               return;
            [medias[index], medias[index + 1]] = [
               medias[index + 1],
               medias[index],
            ];
            if (mediaSelected === index) {
               setMediaSelected(index + 1);
               sliderMediaRef.current?.slickGoTo(index + 1);
            } else if (mediaSelected === index + 1) {
               setMediaSelected(index);
               sliderMediaRef.current?.slickGoTo(index);
            }
            setMedias([...medias]);
         })
         .catch((error) => {});
   };

   // Set new tagging to local
   const handleUpdateTaggingMedia = (
      products: ProductTaggingImage[],
      index: number
   ) => {
      medias[index].media = {
         ...medias[index].media,
         __productTagging: products,
      };
      setMedias([...medias]);
   };
   const handleAddNewTaggings = (
      products: ProductTaggingImage[],
      index: number
   ) => {
      setNewTaggings((prev) => [...prev, ...products]);
   };

   // Remove new tagging when user exit
   const handleExitTagging = async () => {
      if (medias && medias.length > 0 && newTaggings.length > 0) {
         // Remove new tagging when exit
         const promises: any = [];
         for (let i = 0; i < newTaggings.length; i++) {
            promises.push(
               ProductTaggingImage.deleteTagging({ id: newTaggings[i]._id })
            );
         }
         await Promise.all(promises);
         // Remove local data
         medias.map((media) => {
            const idsProductTagging = newTaggings.map((tag) => tag._id);
            if (!media?.media?.__productTagging) return;
            media.media.__productTagging =
               media?.media?.__productTagging?.filter(
                  (tag) => !idsProductTagging.includes(tag._id)
               );
            return media;
         });
      }
      setToggleRemindTaggingExit(false);
      toogleDialogTagging();
   };
   const handleUpdateDescriptionMedia = (
      description: string,
      index: number
   ) => {
      medias[index].media.info.description = description;
      setMedias([...medias]);
   };

   const handleDeleteImage = async (media: SocialFeedMedia, index: number) => {
      try {
         const del = await SocialFeedMedia.del(media._id);
         if (del instanceof ModelError) return;
         removeImageFromList(index);
      } catch (error) {}
   };

   const CustomInput = (
      <div className={"image-upload"}>
         <div className="position-relative d-flex flex-column align-items-center">
            <Image
               width={24}
               height={24}
               src="/assets/img/icon/upload-image.svg"
               alt="SpaceT"
            />
            <span className={"title_input"}>Chọn hình ảnh</span>
         </div>
         <span className={"desc"}>hoặc thả hình vào đây</span>
         <span
            className={"desc"}
            style={{ color: errorsMesImage ? "red" : "" }}
         >
            Chấp nhận định dạng jpg, jpeg, png, webp files dưới 25mb
         </span>
      </div>
   );

   const GridMediaV2 = (
      <div className={styles["grid-medias"]}>
         <Slider
            ref={sliderMediaRef as any}
            className="slick-medias"
            infinite={false}
            slidesPerRow={1}
            slidesToScroll={1}
            swipeToSlide
            afterChange={(currentSlider) => {
               setMediaSelected(currentSlider);
            }}
         >
            {medias.map((media, index) => {
               const productsTagging = media.media.__productTagging;
               return (
                  <div key={media.mediasId + index}>
                     <div
                        className={styles["media-item"]}
                        id={`media-community-post-${index}`}
                     >
                        <div className={classNames(styles["media__image"])}>
                           {media.media?.$url && (
                              <img
                                 className={styles["bg-blur"]}
                                 src={media.media?.$url}
                                 alt=""
                              />
                           )}
                           <ImageTagProduct
                              productsTagging={productsTagging}
                              imageSrc={media.media?.$url ?? ""}
                              socialFeed={socialFeed}
                              socialFeedId={socialFeed?._id ?? draftId ?? ""}
                              imageId={media.media._id}
                              onUpdateTagging={(taggings) =>
                                 handleUpdateTaggingMedia(taggings, index)
                              }
                              onAddNewTagging={(taggings) =>
                                 handleAddNewTaggings(taggings, index)
                              }
                              onDelete={() =>
                                 handleDeleteImage(media.media, index)
                              }
                           />
                           <div className={styles["sort-image"]}>
                              <a onClick={(e) => handleMoveToAbove(index, e)}>
                                 <NorthSharpIcon />
                              </a>
                              <a onClick={(e) => handleMoveToBelow(index, e)}>
                                 <SouthSharpIcon />
                              </a>
                           </div>
                        </div>
                        <DescriptionMedia
                           media={media.media}
                           updateMedia={(description) =>
                              updateMedia({
                                 mediaId: media.media._id,
                                 description,
                              })
                           }
                           onChangeText={(des) =>
                              handleUpdateDescriptionMedia(des, index)
                           }
                        />
                     </div>
                     {/* {errorsMedias?.[index] && <p className="text-error">{errorsMedias?.[index]}</p>} */}
                  </div>
               );
            })}
         </Slider>
      </div>
   );

   const getPlaceHolderHyperlinkInput = useMemo(() => {
      if (type == 0) {
         if (user?.__feedCount <= 0 && bannerType === EBannerType.banner1) {
            return "*Ví dụ: Xin chào cả nhà, mình là Nhi, là thành viên mới. Mình yêu thích trang trí, làm đẹp góc phòng, đặc biệt thích phong cách Hàn và Nhật. Hi vọng sẽ tìm được nhiều ý tưởng hay từ mọi người ạ.";
         } else {
            return "Nhập chia sẻ của bạn ở đây...";
         }
      } else {
         return "Nhập câu hỏi của bạn ở đây...";
      }
   }, [type, user?.__feedCount, bannerType]);

   const handleChangeValues = (field: EName, value: string) => {
      setValues({ ...values, [field]: value });
   };
   const handleOnChangeInputHyperlink = (v: string) => {
      handleChangeValues(EName.content, v);
   };

   //#region Parent Submit
   useImperativeHandle(
      refAction,
      () => ({
         submit: (publishStr) => {
            return postData({
               publishAt: publishStr,
            });
         },
      }),
      [values, postData]
   );

   const mediasList = medias.map((media) => media.media) ?? [];
   return (
      <>
         <div
            className={classNames(
               "creation-post position-relative",
               styles["creation-post"]
            )}
         >
            <div className="content" ref={ref}>
               <form>
                  {!socialFeed ? (
                     <>
                        {type == 0 ? (
                           <>
                              <div className="intro block">
                                 <div>
                                    Chia sẻ, giao lưu cùng cộng đồng yêu nhà và
                                    tích lũy 10.000 Xu mua sắm bạn nhé!{" "}
                                 </div>
                              </div>
                           </>
                        ) : (
                           <div className="intro block">
                              <div>
                                 Nếu có thắc mắc, cần góp ý, xin ý tưởng, hãy
                                 hỏi để cộng đồng hỗ trợ bạn nhé!
                              </div>
                           </div>
                        )}
                     </>
                  ) : undefined}

                  <div className="block form row g-0 position-relative">
                     <div
                        className={`col-12 control-item ${
                           errorsMes?.errors?.content ? "error" : ""
                        }`}
                     >
                        <div
                           id="wrap-input-editor"
                           className="wrap-input-editor "
                        >
                           <InputHyperlinkv2
                              className="hyperlink_custom"
                              innerRef={refInputHyperlink}
                              controlRef={refInputHyperlinkControl}
                              html={(values.content ?? "") as any}
                              initialValue={
                                 socialFeed?.info?.description as any
                              }
                              onChange={handleOnChangeInputHyperlink}
                              placeholder={getPlaceHolderHyperlinkInput}
                           />
                        </div>
                        {errorsMes?.errors?.content?.length ? (
                           <p className="text-error">
                              {t(
                                 errorsMes?.errors?.content?.[0]?.message ?? ""
                              )}
                           </p>
                        ) : null}
                     </div>
                     <div className={`col-12 control-item`}>
                        <InputHashtag
                           hashtag={hashtag}
                           firstHashtag={showFirstHashtag ? "hello" : ""}
                           onChange={setHashtag}
                        />
                        {!isMobile && (
                           <div
                              className={styles["feed-emoij-icon"]}
                              onClick={(e) =>
                                 setState({ anchorPopover: e.currentTarget })
                              }
                           >
                              <svg
                                 xmlns="http://www.w3.org/2000/svg"
                                 viewBox="0 0 1024 1024"
                                 version="1.1"
                              >
                                 <path
                                    d="M510.944 960c-247.04 0-448-200.96-448-448s200.992-448 448-448 448 200.96 448 448-200.96 448-448 448z m0-832c-211.744 0-384 172.256-384 384s172.256 384 384 384 384-172.256 384-384-172.256-384-384-384z"
                                    fill=""
                                 />
                                 <path
                                    d="M512 773.344c-89.184 0-171.904-40.32-226.912-110.624-10.88-13.92-8.448-34.016 5.472-44.896 13.888-10.912 34.016-8.48 44.928 5.472 42.784 54.688 107.136 86.048 176.512 86.048 70.112 0 134.88-31.904 177.664-87.552 10.784-14.016 30.848-16.672 44.864-5.888 14.016 10.784 16.672 30.88 5.888 44.864C685.408 732.32 602.144 773.344 512 773.344zM368 515.2c-26.528 0-48-21.472-48-48v-64c0-26.528 21.472-48 48-48s48 21.472 48 48v64c0 26.496-21.504 48-48 48zM656 515.2c-26.496 0-48-21.472-48-48v-64c0-26.528 21.504-48 48-48s48 21.472 48 48v64c0 26.496-21.504 48-48 48z"
                                    fill=""
                                 />
                              </svg>
                           </div>
                        )}
                     </div>
                  </div>
               </form>
               <div className="block d-flex flex-column">
                  {/* Mobile */}
                  {medias?.length === 0 && (
                     <div className="d-none d-md-block w-100">
                        <Dropzone
                           addClassNames={{
                              dropzone:
                                 errorsMesImage || errorsMesSizeImage
                                    ? "error"
                                    : "",
                           }}
                           inputContent={CustomInput}
                           autoUpload={true}
                           accept="image/png, image/jpeg, image/webp, image/jpg"
                           maxSizeBytes={1024 * 1024 * 25}
                           onChangeStatus={(file, status, allFiles) => {
                              if (status === "done") {
                                 setErrorsMesSizeImage("");
                                 handleChangeStatus(file, status, allFiles);
                              } else if (status === "error_file_size") {
                                 file.remove();
                                 setErrorsMesSizeImage(
                                    "Dung lượng vượt mức cho phép 25MB"
                                 );
                              }
                           }}
                           styles={{ dropzone: { height: 220 } }}
                           submitButtonContent={"Uploading..."}
                           inputWithFilesContent={null}
                        />
                     </div>
                  )}
                  {/* Both */}
                  {mediasList && mediasList.length > 0 && (
                     <div className={"block-images"}>
                        <LayoutMedia<any> images={mediasList} />
                        <div
                           className={"delete-icon"}
                           onClick={removeListImage}
                        >
                           <img
                              width={32}
                              height={32}
                              src="/assets/img/icon/close_bg1.svg"
                              alt="Space T"
                           />
                        </div>
                        <div className={styles["wrap-other-action"]}>
                           <button
                              className={classNames(
                                 styles["btn"],
                                 styles["btn-status-tagging"]
                              )}
                              onClick={toogleDialogTagging}
                           >
                              <Image
                                 width={24}
                                 height={24}
                                 src="/assets/img/icon/social-feed_new.svg"
                                 alt="SpaceT"
                              />
                              Viết caption và tag sản phẩm
                           </button>
                           <button
                              className={classNames(
                                 styles["btn"],
                                 styles["btn-add-image"],
                                 "d-none d-md-flex"
                              )}
                           >
                              <input
                                 type="file"
                                 name="imageUpload"
                                 id="file"
                                 multiple
                                 accept="image/png, image/jpeg, image/jpg, image/webp"
                                 onChange={onChangeImageInput}
                              />
                              <svg
                                 width="20"
                                 height="16"
                                 viewBox="0 0 20 16"
                                 fill="none"
                                 xmlns="http://www.w3.org/2000/svg"
                              >
                                 <path
                                    d="M3.07422 14.0615H16.9258C17.2773 14.0615 17.5615 13.9561 17.7783 13.7451C17.9951 13.5342 18.1035 13.2441 18.1035 12.875V11.4072L14.5615 8.12012C14.3682 7.94434 14.1572 7.8125 13.9287 7.72461C13.7061 7.63672 13.4834 7.59277 13.2607 7.59277C12.7803 7.59277 12.3408 7.76562 11.9424 8.11133L8.03125 11.5918L6.45801 10.1768C6.08887 9.84863 5.69629 9.68457 5.28027 9.68457C5.08105 9.68457 4.89062 9.72559 4.70898 9.80762C4.52734 9.88379 4.3457 10.001 4.16406 10.1592L1.89648 12.1543V12.8662C1.89648 13.2412 1.99902 13.5342 2.2041 13.7451C2.41504 13.9561 2.70508 14.0615 3.07422 14.0615ZM6.72168 8.15527C7.09668 8.15527 7.43945 8.06152 7.75 7.87402C8.06641 7.68066 8.31836 7.42871 8.50586 7.11816C8.69336 6.80176 8.78711 6.45312 8.78711 6.07227C8.78711 5.69141 8.69336 5.3457 8.50586 5.03516C8.31836 4.71875 8.06641 4.4668 7.75 4.2793C7.43945 4.08594 7.09668 3.98926 6.72168 3.98926C6.33496 3.98926 5.9834 4.08594 5.66699 4.2793C5.35059 4.4668 5.09863 4.71875 4.91113 5.03516C4.72949 5.3457 4.63867 5.69141 4.63867 6.07227C4.63867 6.45312 4.72949 6.80176 4.91113 7.11816C5.09863 7.42871 5.35059 7.68066 5.66699 7.87402C5.9834 8.06152 6.33496 8.15527 6.72168 8.15527ZM2.90723 15.8105C1.99902 15.8105 1.30762 15.5762 0.833008 15.1074C0.364258 14.6445 0.129883 13.9619 0.129883 13.0596V2.95215C0.129883 2.0498 0.364258 1.36719 0.833008 0.904297C1.30762 0.435547 1.99902 0.201172 2.90723 0.201172H17.084C17.998 0.201172 18.6895 0.435547 19.1582 0.904297C19.627 1.36719 19.8613 2.0498 19.8613 2.95215V13.0596C19.8613 13.9619 19.627 14.6445 19.1582 15.1074C18.6895 15.5762 17.998 15.8105 17.084 15.8105H2.90723Z"
                                    fill="#68BA6B"
                                 />
                              </svg>
                              Thêm ảnh
                           </button>
                        </div>
                     </div>
                  )}
                  {/* Both */}
                  {errorsMesImage || errorsMesSizeImage ? (
                     <p
                        className={classNames(
                           styles["upload-image-error"],
                           "text-error",
                           isMobile && mediasList.length <= 0
                              ? "text-center"
                              : ""
                        )}
                     >
                        {t(errorsMesImage || errorsMesSizeImage || "")}
                     </p>
                  ) : null}
               </div>

               {success ? (
                  <DialogNotification
                     title={success}
                     onClose={() => {
                        onClose && onClose();
                     }}
                     textButton="Xác nhận "
                     open={Boolean(success)}
                  />
               ) : undefined}

               {!isMobile && Boolean(state.anchorPopover) && (
                  <Popover
                     className={styles["popover-emoij"]}
                     onClose={() => setState({ anchorPopover: undefined })}
                     BackdropProps={{
                        className: "backdrop",
                        style: { background: "transparent" },
                     }}
                     anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "center",
                     }}
                     transformOrigin={{
                        vertical: "top",
                        horizontal: "center",
                     }}
                     classes={{ paper: styles["popover-emoij-paper"] }}
                     anchorEl={state.anchorPopover}
                     open={Boolean(state.anchorPopover)}
                  >
                     <EmojiPicker
                        skinTonesDisabled
                        searchDisabled
                        reactionsDefaultOpen
                        onEmojiClick={(e) => {
                           refInputHyperlinkControl.current?.setEmoij(e.emoji);
                        }}
                     />
                  </Popover>
               )}
            </div>

            {/* Only mobile */}
            <div
               className={classNames(
                  "d-md-none d-flex",
                  styles["upload-image-mb"]
               )}
            >
               <div className={styles["option-image"]}>
                  <div className={styles["input"]}>
                     <Image
                        width={22}
                        height={22}
                        src="/assets/img/icon/img-default-color.svg"
                        alt="SpaceT"
                     />
                     <input
                        type="file"
                        name="imageUpload"
                        id="file"
                        accept="image/*"
                        onChange={(e) => {
                           onChangeImageInput(e);
                           const medias = e.target.files;

                           const validImages = validateImage(medias as any);
                           if (!validImages) return;

                           const validSizeImages = validateSizeImage(
                              medias as any
                           );
                           if (!validSizeImages) return;

                           setIsTaggingProductMb(true);
                           const isShowedFirstUploadImage =
                              UserStorages.isShowedWithUserId(
                                 "f:p:remind",
                                 user?._id
                              );
                           if (
                              medias &&
                              medias.length <= 0 &&
                              !isShowedFirstUploadImage
                           ) {
                              setFirstUploadImage(true);
                              UserStorages.setShowedWithUserId(
                                 "f:p:remind",
                                 user?._id,
                                 { show: true }
                              );
                           }
                        }}
                        multiple
                     />
                  </div>
                  <span className={styles["text"]}>Chọn hình</span>
               </div>
               <div className={styles["option-image"]}>
                  <div className={styles["input"]}>
                     <Image
                        width={22}
                        height={22}
                        src="/assets/img/icon/camera.svg"
                        alt="SpaceT"
                     />
                     <input
                        type="file"
                        name="imageUpload"
                        id="file"
                        accept="image/*"
                        onChange={(e) => {
                           onChangeImageInput(e);
                           setIsTaggingProductMb(true);
                           const isShowedFirstUploadImage =
                              UserStorages.isShowedWithUserId(
                                 "f:p:remind",
                                 user?._id
                              );
                           if (
                              medias &&
                              medias.length <= 0 &&
                              !isShowedFirstUploadImage
                           ) {
                              setFirstUploadImage(true);
                              UserStorages.setShowedWithUserId(
                                 "f:p:remind",
                                 user?._id,
                                 { show: true }
                              );
                           }
                        }}
                        multiple
                     />
                  </div>
                  <span className={styles["text"]}>Chụp ảnh</span>
               </div>
            </div>

            {/* Actions desktop */}
            <div className={styles["action"]}>
               {!socialFeed && (
                  <button
                     className={classNames(
                        styles["bt-schedule-post"],
                        Boolean(
                           errorsMesImage ||
                              errorsMes ||
                              hashtag.hashTagValueError ||
                              validate()?.message
                        )
                           ? styles["disable"]
                           : null
                     )}
                     onClick={() => {
                        if (
                           Boolean(
                              errorsMesImage ||
                                 errorsMes ||
                                 hashtag.hashTagValueError ||
                                 validate()?.message
                           )
                        )
                           return;
                        onSchedulePost?.();
                     }}
                     disabled={Boolean(
                        errorsMesImage ||
                           errorsMes ||
                           hashtag.hashTagValueError ||
                           validate()?.message
                     )}
                  >
                     Lên lịch đăng bài
                  </button>
               )}
               <button
                  id="create-post"
                  className={classNames(
                     "text-link",
                     styles["submit"],
                     Boolean(
                        errorsMesImage ||
                           errorsMes ||
                           hashtag.hashTagValueError ||
                           validate()?.message
                     )
                        ? styles["disable"]
                        : null
                  )}
                  type="submit"
                  disabled={Boolean(
                     errorsMesImage ||
                        errorsMes ||
                        hashtag.hashTagValueError ||
                        validate()?.message
                  )}
                  onClick={() => {
                     postData({});
                  }}
               >
                  {socialFeed ? "Lưu" : "Chia sẻ"}
               </button>
            </div>
         </div>

         {/* desktop dialog tagging product */}
         <Dialog
            sx={{
               "& .MuiPaper-root": {
                  borderRadius: "24px",
               },
               "& .slick-arrow": {
                  width: "40px",
                  height: "40px",
                  zIndex: 1,
                  top: "42%",
               },
               "& .slick-prev": {
                  left: "24px",
               },
               "& .slick-next": {
                  right: "24px",
               },
               "& .slick-prev:before, .slick-next:before": {
                  fontSize: "40px",
               },
            }}
            open={isTaggingProduct}
            classes={{
               paper: styles["dialog-tagging-paper"],
            }}
         >
            <>
               <DialogTitle className={styles["title-dialog"]}>
                  <ArrowBackIosNewIcon
                     className={styles["icon-back"]}
                     onClick={toogleDialogTagging}
                  />
                  <span> Viết caption và tag sản phẩm</span>
                  <Button
                     className={styles["btn-done"]}
                     onClick={toogleDialogTagging}
                  >
                     Xong
                  </Button>
               </DialogTitle>
               <DialogContent className={styles["content-dialog"]}>
                  <div className={styles["tagging"]}>
                     {GridMediaV2}
                     <div className={styles["list-thumbnail-media"]}>
                        {medias.map((media, index) => {
                           return (
                              <div
                                 key={media.mediasId + index}
                                 className={classNames(
                                    styles["thumbnail-media-item"],
                                    mediaSelected === index &&
                                       styles["thumbnail-image--selected"]
                                 )}
                                 onClick={(
                                    e: React.MouseEvent<
                                       HTMLDivElement,
                                       MouseEvent
                                    >
                                 ) => handleSelectMedia(index, e)}
                              >
                                 <img
                                    src={media.media.$url}
                                    alt="SpaceT"
                                    className={classNames(
                                       styles["thumbnail-image"]
                                    )}
                                 />
                                 <div className={styles["thumbnail-overlap"]}>
                                    <a
                                       onClick={(e) =>
                                          handleMoveToAbove(index, e)
                                       }
                                    >
                                       <NorthSharpIcon />
                                    </a>
                                    <a
                                       onClick={(e) =>
                                          handleMoveToBelow(index, e)
                                       }
                                    >
                                       <SouthSharpIcon />
                                    </a>
                                 </div>
                              </div>
                           );
                        })}
                        <div className={styles["add-media"]}>
                           <input
                              type="file"
                              name="imageUpload"
                              id="file"
                              multiple
                              accept="image/*"
                              onChange={onChangeImageInput}
                           />
                           <AddSharpIcon />
                        </div>
                     </div>
                  </div>
               </DialogContent>
               {/* <DialogActions className={styles["action-dialog"]}>
                  <div className="d-none d-md-block">
                     <div className={styles["add-media"]}>
                        <input type="file" name="imageUpload" id="file" accept="image/*" onChange={onChangeImageInput} />
                        <AddBoxIcon />
                        <span>Thêm hình</span>
                     </div>
                  </div>
                  <div className={styles["btn-done"]} onClick={toogleDialogTagging}>
                     Xong
                  </div>
               </DialogActions> */}
            </>
         </Dialog>

         {/* mobile drawer tagging product */}
         <Drawer
            open={isTaggingProductMb}
            classes={{
               paper: styles["drawer-tagging-paper"],
            }}
         >
            <div className={styles["drawer-title"]}>
               <ArrowBackIosNewIcon
                  className={styles["icon-back"]}
                  onClick={() => setToggleRemindTaggingExit(true)}
               />
               {/* <span> Viết caption và tag sản phẩm</span> */}
               <button className="btn btn-link" onClick={toogleDialogTagging}>
                  Xong
               </button>
            </div>
            <div className={styles["tagging"]}>{GridMediaV2}</div>
            <div className={styles["list-thumbnail-media"]}>
               {medias.map((media, index) => {
                  return (
                     <div
                        key={media.mediasId + index}
                        className={classNames(
                           styles["thumbnail-media-item"],
                           mediaSelected === index &&
                              styles["thumbnail-image--selected"]
                        )}
                        onClick={(
                           e: React.MouseEvent<HTMLDivElement, MouseEvent>
                        ) => handleSelectMedia(index, e)}
                     >
                        <img
                           src={
                              media.media.$url1ColResizeMax || media.media.$url
                           }
                           alt="SpaceT"
                           className={classNames(styles["thumbnail-image"])}
                        />
                        <div className={styles["thumbnail-overlap"]}>
                           <a onClick={(e) => handleMoveToAbove(index, e)}>
                              <NorthSharpIcon />
                           </a>
                           <a onClick={(e) => handleMoveToBelow(index, e)}>
                              <SouthSharpIcon />
                           </a>
                        </div>
                     </div>
                  );
               })}
               <div className={styles["add-media"]}>
                  <input
                     type="file"
                     name="imageUpload"
                     id="file"
                     multiple
                     accept="image/*"
                     onChange={onChangeImageInput}
                  />
                  <AddSharpIcon />
               </div>
            </div>
            {/* <div className={classNames(styles["upload-image-mb"])}>
               <div className={styles["option-image"]}>
                  <div className={styles["input"]}>
                     <Image width={22} height={22} src="/assets/img/icon/img-default-color.svg" alt="SpaceT" />
                     <input type="file" name="imageUpload" id="file" accept="image/*" onChange={onChangeImageInput} multiple />
                  </div>
                  <span className={styles["text"]}>{medias?.length > 0 ? "Thêm hình" : "Chọn hình"}</span>
               </div>
            </div> */}
         </Drawer>

         {/* Dialog recommend for add tagging mobile */}
         <Dialog
            open={firstUploadImage}
            className="dialog-recommand-add-tagging"
         >
            <div className="dialog-content">
               <div
                  className="close-icon"
                  onClick={() => setFirstUploadImage(false)}
               >
                  <svg
                     width="32"
                     height="32"
                     viewBox="0 0 32 32"
                     fill="none"
                     xmlns="http://www.w3.org/2000/svg"
                  >
                     <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M11.2794 10.2184C10.9865 9.92556 10.5116 9.92556 10.2187 10.2184C9.9258 10.5113 9.9258 10.9862 10.2187 11.2791L14.9397 16.0001L10.2187 20.7211C9.9258 21.014 9.9258 21.4889 10.2187 21.7818C10.5116 22.0747 10.9865 22.0747 11.2794 21.7818L16.0004 17.0608L20.7205 21.781C21.0134 22.0739 21.4883 22.0739 21.7812 21.781C22.0741 21.4881 22.0741 21.0132 21.7812 20.7203L17.061 16.0001L21.7812 11.2799C22.0741 10.987 22.0741 10.5122 21.7812 10.2193C21.4883 9.92637 21.0134 9.92637 20.7205 10.2193L16.0004 14.9395L11.2794 10.2184Z"
                        fill="black"
                     />
                  </svg>
               </div>
               <img
                  className="icon-recommend-tag"
                  src={recommendImageAddTagging.src}
                  alt=""
               />
               <p className="greet-text">Tuyệt vời!</p>
               <p className="des">
                  Từ giờ bạn có thể gắn thẻ sản phẩm và quản lý thẻ nhanh hơn
                  tại đây.
               </p>
               <div className="modal-action">
                  <button onClick={() => setFirstUploadImage(false)}>
                     Đã hiểu
                  </button>
               </div>
            </div>
         </Dialog>

         {/* Drawer Remind Tagging when exit */}
         <DrawerBase
            open={toggleRemindTaggingExit}
            className="remind-tagging-exit"
            onClose={() => setToggleRemindTaggingExit(false)}
         >
            <div className="header">
               <div className="title">Bạn muốn hoàn thành sau ư?</div>
               <div className="subtitle">
                  Nội dung sẽ huỷ khi bạn rời khỏi lúc này
               </div>
            </div>
            <div className="content">
               <div className="btn-el exit-now" onClick={handleExitTagging}>
                  <svg
                     xmlns="http://www.w3.org/2000/svg"
                     width="24"
                     height="24"
                     viewBox="0 0 24 24"
                     fill="none"
                  >
                     <path
                        d="M7.5 19.9995C7.04167 19.9995 6.64931 19.8363 6.32292 19.5099C5.99653 19.1835 5.83333 18.7912 5.83333 18.3328V7.49951H5V5.83285H9.16667V4.99951H14.1667V5.83285H18.3333V7.49951H17.5V18.3328C17.5 18.7912 17.3368 19.1835 17.0104 19.5099C16.684 19.8363 16.2917 19.9995 15.8333 19.9995H7.5ZM15.8333 7.49951H7.5V18.3328H15.8333V7.49951ZM9.16667 16.6662H10.8333V9.16618H9.16667V16.6662ZM12.5 16.6662H14.1667V9.16618H12.5V16.6662Z"
                        fill="#252A2B"
                     />
                  </svg>{" "}
                  Bỏ chỉnh sửa
               </div>
               <div
                  className="btn-el continue-edit"
                  onClick={() => {
                     setToggleRemindTaggingExit(false);
                  }}
               >
                  <svg
                     xmlns="http://www.w3.org/2000/svg"
                     width="24"
                     height="24"
                     viewBox="0 0 24 24"
                     fill="none"
                  >
                     <path
                        d="M9.20948 17.5602C9.04155 17.5676 8.87603 17.5183 8.73948 17.4202L4.24948 13.7102C3.9602 13.4541 3.91665 13.0186 4.14948 12.7102C4.40515 12.4223 4.83734 12.3748 5.14948 12.6002L9.14948 15.8602L19.6495 6.15024C19.96 5.91711 20.3971 5.95953 20.657 6.24802C20.9169 6.5365 20.9136 6.97565 20.6495 7.26024L9.71948 17.3602C9.58026 17.488 9.39843 17.5593 9.20948 17.5602Z"
                        fill="#FF6347"
                     />
                  </svg>{" "}
                  Tiếp tục chỉnh sửa
               </div>
            </div>
         </DrawerBase>
      </>
   );
}

interface IDescriptionProps {
   media: SocialFeedMedia;
   updateMedia: (description: string) => void;
   onChangeText: (value: string) => void;
}

const DescriptionMedia = ({
   media,
   updateMedia,
   onChangeText,
}: IDescriptionProps) => {
   // const [value, setValue] = useState<string>('');
   const descriptionRef = useRef<HTMLTextAreaElement>(null);

   useAutosizeTextArea(descriptionRef.current, media.info.description ?? "");

   return (
      <div className={styles["media__description"]}>
         <textarea
            value={media.info.description}
            ref={descriptionRef}
            // rows={1}
            placeholder="Viết mô tả cho ảnh này..."
            className={styles["block-content"]}
            onBlur={(e) => updateMedia(e.target.value)}
            onChange={(e) => onChangeText(e.target.value)}
         />
      </div>
   );
};
