/* eslint-plugin-disable react */
/*eslint-disable */
/**
 * Variable(s)
 */
import { apiDomain } from "./__variables";

/**
 * Model(s)
 */
import InteriorStyle from "./interiorStyle.model";
import Model, { IModel, ModelError, Paginate, TModelError, TPaginate, TPayload } from "./model";
import Location from "./location.model";

export enum EPartnerService {
   designConstruction = "designConstruction",
   construction = "construction",
   interiorDesignAndConstruction = "interiorDesignAndConstruction",
}

export const TittleService = {
   [EPartnerService.designConstruction]: "Thiết kế xây dựng",
   [EPartnerService.construction]: "Thi công xây dựng",
   [EPartnerService.interiorDesignAndConstruction]: "Thiết kế & thi công nội thất",
};
export enum EWorkScopeMatching {
   furniture = "furniture",
   design = "design",
   fitout = "fitout",
}
export enum ETypeMAtching {
   other = "other",
   commercial = "commercial",
   residential = "residential",
}
export const ETypeMAtchingTV: any = {
   residential: "Nhà ở",
   commercial: "Thương mại",
   other: "Khác",
};
export const EWorkScopeMatchingTV: any = {
   design: "Thiết kế",
   fitout: "Cải tạo",
   furniture: "Nội Thất",
   construction: "Xây dựng",
};

export enum EStatus {
   enable = "enable",
   disable = "disable",
   delete = "delete",
}

export enum EPriceRange {
   high = "high",
   middle = "middle",
   low = "low",
}

export enum EPartnerSortBy {
   createdAt = "createdAt",
   // updatedAt = "updatedAt"
}

export enum EPartnerIsVerified {
   true = "true",
   false = "false",
}

export interface IPartner extends IModel {
   ["info"]?: {
      ["name"]?: string | null;
      ["slug"]?: string | null;
      ["location"]?: string | null;
      ["service"]?: string | null;
      ["services"]?: EPartnerService[] | null;
      ["typeMatching"]?: ETypeMAtching[] | null;
      ["workScopeMatching"]?: EWorkScopeMatching[] | null;
      ["intro"]?: string | null;
      ["priceRange"]?: EPriceRange;
      ["imageFeatured"]?: string | null;
      ["thumbnail"]?: string | null;
      ["maximumValueMatching"]?: number | null;
   };
   ["options"]?: {
      ["status"]?: EStatus;
      ["isVerified"]?: EPartnerIsVerified;
   };
   ["logoId"]?: string | null;
   ["styleIds"]?: string[];

   ["__logo"]?: null;
   ["__styles"]?: InteriorStyle[];
   ["__stylesMatching"]?: InteriorStyle[];
   ["averageRating"]?: number;
   ["__actualWorkCount"]?: number;
   ["__reviewCount"]?: number;
   ["__averageRatingPoint"]?: number;
   ["countRating"]?: number;
   ["averageCost"]?: number;
   ["averageQuality"]?: number;
   ["averageTime"]?: number;
   ["__isVerified"]?: boolean;
   ["__location"]?: Location[];
   ["styleMatchingIds"]?: string[];
}

export class Partner extends Model<IPartner> implements IPartner {
   public ["info"]!: {
      ["name"]?: string | null;
      ["slug"]?: string | null;
      ["location"]?: string | null;
      ["service"]?: string | null;
      ["services"]?: EPartnerService[] | null;
      ["typeMatching"]?: ETypeMAtching[] | null;
      ["workScopeMatching"]?: EWorkScopeMatching[] | null;
      ["intro"]?: string | null;
      ["priceRange"]?: EPriceRange;
      ["imageFeatured"]?: string | null;
      ["thumbnail"]?: string | null;
      ["maximumValueMatching"]?: number | null;
   };
   public ["options"]!: {
      ["status"]?: EStatus;
      ["isVerified"]?: EPartnerIsVerified;
   };
   public ["logoId"]!: string | null;
   public ["styleIds"]!: string[];
   public ["averageRating"]?: number;

   public ["__logo"]!: null;
   public ["__styles"]!: InteriorStyle[];
   public ["__stylesMatching"]?: InteriorStyle[];
   public ["__actualWorkCount"]?: number;
   public ["__reviewCount"]?: number;
   public ["__averageRatingPoint"]?: number;
   public ["countRating"]?: number;
   public ["averageCost"]?: number;
   public ["averageQuality"]?: number;
   public ["averageTime"]?: number;
   public ["__isVerified"]?: boolean;
   public ["__location"]?: Location[];
   public ["styleMatchingIds"]?: string[];

   constructor(
      data: IPartner = {
         ["info"]: {
            ["name"]: null,
            ["slug"]: null,
            ["location"]: null,
            ["service"]: null,
            ["intro"]: null,
            ["priceRange"]: undefined,
            ["imageFeatured"]: null,
            ["thumbnail"]: null,
         },
         ["options"]: {
            status: undefined,
         },
         ["logoId"]: null,
         ["styleIds"]: [] as string[],
      }
   ) {
      super(data);

      Object.assign(this, data);

      if (this.__styles && this.__styles.every((style) => !(style instanceof InteriorStyle))) {
         this.__styles = this.__styles.map((style) => new InteriorStyle(style));
      }

      if (this.__location) {
         this.__location = this.__location.map((location) => new Location(location));
      }
   }

   /**
    * Fetches a paginated list of partners based on provided filters and sorting options.
    * @param {Object} options - The options for pagination and filtering.
    * @param {string} [options.keywords] - Keywords to search for in partner names or descriptions.
    * @param {number} [options.page=1] - The page number to retrieve.
    * @param {number} [options.limit=20] - The number of partners per page.
    * @param {('asc'|'desc')} [options.sort] - The sort order.
    * @param {('createdAt'|'info.name')} [options.sortBy] - The field to sort by.
    * @param {string} [options.locationIds] - Comma-separated list of location IDs.
    * @param {string} [options.services] - Comma-separated list of service types.
    * @param {string} [options.priceRanges] - Comma-separated list of price ranges.
    * @param {string} [options.projectTypes] - Comma-separated list of project types.
    * @param {string} [options.interiorStyles] - Comma-separated list of interior style IDs.
    * @param {string} [options.constructionTypeSlug] - Slug of the construction type.
    * @param {string} [options.locationSlug] - Slug of the location.
    * @param {string} [options.url] - URL for the request.
    * @returns {Promise<Paginate<Partner> | ModelError>} A promise that resolves to a paginated list of partners or a ModelError.
    */
   public static async getPartnerPaginate({
      keywords,
      page = 1,
      limit = 20,
      sort,
      sortBy,
      locationIds,
      services,
      priceRanges,
      projectTypes,
      interiorStyles,
      constructionTypeSlug,
      locationSlug,
      url,
   }: {
      keywords?: string;
      page: number;
      limit: number;
      sort?: "asc" | "desc";
      sortBy?: "createdAt" | "info.name";
      locationIds?: string;
      services?: string;
      priceRanges?: string;
      projectTypes?: string;
      interiorStyles?: string;
      constructionTypeSlug?: string;
      locationSlug?: string;
      url?: string;
   }) {
      try {
         const response = await this.fetch({
            method: "GET",
            endPoint: "partner/paginate",
            headers: {
               page: String(page),
               limit: String(limit),
               ...(sort && { sort }),
               ...(sortBy && { "sort-by": sortBy }),
               ...(locationIds && { "location-slugs": locationIds }),
               ...(services && { services }),
               ...(priceRanges && { "price-ranges": priceRanges }),
               ...(interiorStyles && {
                  "interior-style-slugs": interiorStyles,
               }),
               ...(projectTypes && { "project-types": projectTypes }),
               ...(keywords && { keywords: encodeURI(keywords) }),
               ...(constructionTypeSlug && {
                  "construction-type-slugs": encodeURI(constructionTypeSlug),
               }),
               ...(locationSlug && {
                  "location-slugs": encodeURI(locationSlug),
               }),
               ...(url && { url: encodeURI(url) }),
            },
         });

         if (response instanceof ModelError) {
            return response;
         }

         const payload = response as TPayload<{
            paginator: TPaginate<IPartner>;
         }>;

         return new Paginate(payload?.data?.paginator, Partner);
      } catch (error) {}
   }

   /**
    *
    * @param partnerId
    * @returns
    */
   public static async getPartnerById(slug: string) {
      try {
         const response = await fetch(`${apiDomain}/www/partner`, {
            ["method"]: "GET",
            ["cache"]: "no-store",
            ["headers"]: {
               ["Origin"]: "https://spacet.vn",
               ["X-Requested-With"]: "XMLHttpRequest",
               ["slug"]: slug,
            },
         });

         if (!response.ok) {
            return new ModelError((await response.json()) as TModelError);
         }

         const payload = (await response.json()) as TPayload<{
            ["partner"]: IPartner;
            ["averageRating"]: number;
            ["countRating"]?: number;
            ["averageCost"]?: number;
            ["averageQuality"]?: number;
            ["averageTime"]?: number;
            ["__actualWorkCount"]?: number;
         }>;
         return {
            ["partner"]: new Partner(payload.data.partner),
            ["averageRating"]: payload.data.averageRating,
            ["countRating"]: payload.data.countRating,
            ["averageCost"]: payload.data.averageCost,
            ["averageQuality"]: payload.data.averageQuality,
            ["averageTime"]: payload.data.averageTime,
            ["__actualWorkCount"]: payload.data.__actualWorkCount,
         };
      } catch (error) {}
   }

   // public async create() {
   //     if (this._id) {
   //         return new ModelError({
   //             ["httpCode"]: 409,
   //             ["message"]: "Conflick",
   //             ["errors"]: {
   //                 ["process"]: [
   //                     {
   //                         ["code"]: "client.partner.create.conflict",
   //                         ["message"]: "Partner đã tồn tại."
   //                     }
   //                 ]
   //             }
   //         });
   //     }

   //     if (!token) {
   //         return new ModelError({
   //             ["httpCode"]: 401,
   //             ["message"]: "Unauthorization",
   //             ["errors"]: {
   //                 ["process"]: [
   //                     {
   //                         ["code"]: "process.error.401",
   //                         ["message"]: "Đăng nhập để tiếp tục."
   //                     }
   //                 ]
   //             }
   //         });
   //     }

   //     const response = await fetch(`${apiDomain}/console/partner`, {
   //         ["method"]: "POST",
   //         ["headers"]: {
   //             ["Authorization"]: `Bearer ${token}`,
   //             ["X-Requested-With"]: "XMLHttpRequest",
   //             ["Content-Type"]: "application/json"
   //         },
   //         ["body"]: JSON.stringify(this)
   //     });

   //     if (!response.ok) {
   //         return new ModelError(await response.json() as TModelError);
   //     }

   //     const payload = await response.json() as TPayload<{
   //         ["newPartner"]: IPartner;
   //     }>;

   //     Object.assign(this, payload.data.newPartner);

   //     return this;
   // }

   // /**
   //  *
   //  * @returns
   //  */
   // public async save() {
   //     try {
   //         if (!this._id) {
   //             return new ModelError({
   //                 ["httpCode"]: 409,
   //                 ["message"]: "Conflick",
   //                 ["errors"]: {
   //                     ["process"]: [
   //                         {
   //                             ["code"]: "client.partner.save.conflict",
   //                             ["message"]: "Không tìm thấy Partner ID."
   //                         }
   //                     ]
   //                 }
   //             });
   //         }

   //         if (!token) {
   //             return new ModelError({
   //                 ["httpCode"]: 401,
   //                 ["message"]: "Unauthorization",
   //                 ["errors"]: {
   //                     ["process"]: [
   //                         {
   //                             ["code"]: "process.error.401",
   //                             ["message"]: "Đăng nhập để tiếp tục."
   //                         }
   //                     ]
   //                 }
   //             });
   //         }

   //         const response = await fetch(`${apiDomain}/console/partner`, {
   //             ["method"]: "PATCH",
   //             ["headers"]: {
   //                 ["Authorization"]: `Bearer ${token}`,
   //                 ["X-Requested-With"]: "XMLHttpRequest",
   //                 ["Content-Type"]: "application/json",
   //                 ["id"]: this._id
   //             },
   //             ["body"]: JSON.stringify(this)
   //         });

   //         if (!response.ok) {
   //             return new ModelError(await response.json() as TModelError);
   //         }

   //         const payload = await response.json() as TPayload<{
   //             ["updatedPartner"]: IPartner;
   //         }>;

   //         Object.assign(this, payload.data.updatedPartner);

   //         return this;
   //     }
   //     catch (error: any) {
   //         return new ModelError({
   //             ["httpCode"]: 500,
   //             ["message"]: error.message as string,
   //             ["errors"]: {
   //                 ["process"]: [
   //                     {
   //                         ["code"]: "process.error.5000",
   //                         ["message"]: "Process error on handling."
   //                     }
   //                 ]
   //             }
   //         });
   //     }
   // }
}

export default Partner;
