import SocialFeed from "./socialFeed.model";
import SocialFeedComment from "./socialFeedComment.model";
/**
 * Module(s)
 */

/**
 * Model(s)
 */
import Reaction, {
   EReactionStatus,
   EReactionTypes,
   IReaction,
} from "./reaction.model";
import { apiDomain, tokenKey } from "./__variables";
import Model, {
   IModel,
   ModelError,
   Paginate,
   TModelError,
   TPaginate,
   TPayload,
} from "./model";
import User from "./user.model";

export interface ISocialFeedReaction extends IModel {
   ["target"]: {
      ["doc"]: string;
      ["model"]: string;
      ["sourceDoc"]: string;
      ["sourceModel"]: string;
   };
   ["options"]?: {
      ["type"]?: EReactionTypes | null;
      ["status"]?: EReactionStatus | null;
   };
   ["userId"]?: string | null;
   ["__user"]?: User;
   ["__socialFeed"]?: SocialFeed;
   ["__reactionForComment"]?: SocialFeedComment;
}

export class SocialFeedReaction extends Model implements ISocialFeedReaction {
   public ["target"]!: {
      ["doc"]: string;
      ["model"]: string;
      ["sourceDoc"]: string;
      ["sourceModel"]: string;
   };
   public ["options"]?: {
      ["type"]?: EReactionTypes | null;
      ["status"]?: EReactionStatus | null;
   };
   public ["userId"]?: string | null;
   public ["__user"]?: User;
   public ["__socialFeed"]?: SocialFeed;
   public ["__reactionForComment"]?: SocialFeedComment;

   constructor(data?: ISocialFeedReaction) {
      super(data);
      Object.assign(this, data);
      if (this.__user) {
         this.__user = new User(this.__user);
      }
      if (this.__socialFeed) {
         this.__socialFeed = new SocialFeed(this.__socialFeed);
      }
      if (this.__reactionForComment) {
         this.__reactionForComment = new SocialFeedComment(
            this.__reactionForComment
         );
      }
   }

   /**
    *
    * @param type
    * @param socialFeedId
    * @param socialFeedCommentId
    * @returns
    */
   public static async create(
      type: "like",
      socialFeedId: string,
      socialFeedCommentId: string | null
   ) {
      const token = localStorage.getItem(tokenKey);

      if (!token) {
         return new ModelError({
            httpCode: 401,
            message: "401 Unauthorized error",
            errors: {
               process: [
                  {
                     code: "401",
                     message: "401 Unauthorized error",
                  },
               ],
            },
         });
      }
      try {
         const response = await fetch(`${apiDomain}/www/social-feed/reaction`, {
            method: "POST",
            headers: {
               "X-Requested-With": "XMLHttpRequest",
               Authorization: `Bearer ${token}`,
               "Content-Type": "application/json",
            },
            body: JSON.stringify({
               type: type,
               socialFeedId: socialFeedId,
               socialFeedCommentId: socialFeedCommentId ?? null,
            }),
         });

         if (!response.ok) {
            return new ModelError((await response.json()) as TModelError);
         }

         const payload = (await response.json()) as TPayload<{
            newSocialFeedReaction: ISocialFeedReaction;
         }>;

         return new SocialFeedReaction(payload.data.newSocialFeedReaction);
      } catch (error: any) {
         return new ModelError({
            httpCode: 500,
            message: error.message as string,
            errors: {
               process: [
                  {
                     code: "process.error.5000",
                     message: "Process error on handling.",
                  },
               ],
            },
         });
      }
   }

   /**
    *
    * @param id
    * @returns
    */
   public static async delete(id: string) {
      const token = localStorage.getItem(tokenKey);
      if (!token) {
         return new ModelError({
            httpCode: 401,
            message: "401 Unauthorized error",
            errors: {
               process: [
                  {
                     code: "401",
                     message: "401 Unauthorized error",
                  },
               ],
            },
         });
      }
      try {
         const response = await fetch(`${apiDomain}/www/social-feed/reaction`, {
            method: "DELETE",
            headers: {
               "X-Requested-With": "XMLHttpRequest",
               Authorization: `Bearer ${token}`,
               "Content-Type": "application/json",
               ids: id,
            },
         });

         if (!response.ok) {
            return new ModelError((await response.json()) as TModelError);
         }
         return;
      } catch (error: any) {
         return new ModelError({
            httpCode: 500,
            message: error.message as string,
            errors: {
               process: [
                  {
                     code: "process.error.5000",
                     message: "Process error on handling.",
                  },
               ],
            },
         });
      }
   }

   public static async paginate({
      idSocialFeed,
      page,
      limit,
   }: {
      idSocialFeed: string;
      limit: number;
      page?: number;
   }) {
      try {
         const res = await Model.fetchData({
            method: "GET",
            endPoint: "social-feed/reaction/paginate",
            headers: {
               id: idSocialFeed,
               limit: limit + "",
               sort: "desc",
               ...(page ? { page: page + "" } : undefined),
            },
         });
         if (res instanceof ModelError) {
            return res;
         }

         const payload = (await res.json()) as TPayload<{
            paginator: TPaginate<ISocialFeedReaction>;
         }>;

         const paginate = new Paginate(
            payload?.data?.paginator,
            SocialFeedReaction
         );

         return paginate;
      } catch (error) {
         return new ModelError(error);
      }
   }
}

export default SocialFeedReaction;
