/* eslint-plugin-disable react */
/*eslint-disable */
/**
 * Variable(s)
 */
// import { apiDomain, tokenKey } from "./__variables";

/**
 * Model(s)
 */
import ArticleCategoryMedia from "./articleCategoryMedia.model";
import {
   Model,
   IModel,
   ModelError,
   TPayload,
   TPaginate,
   Paginate,
} from "./model";

export enum EStatus {
   enable = "enable",
   disable = "disable",
   delete = "delete",
}

export interface IArticleCategory extends IModel {
   ["info"]?: {
      ["name"]?: string | null;
      ["slug"]?: string | null;
      ["description"]?: string | null;
   };
   ["options"]?: {
      ["status"]?: EStatus | null;
   };
   ["__media"]: ArticleCategoryMedia | null;
}

export class ArticleCategory
   extends Model<IArticleCategory>
   implements IArticleCategory
{
   public ["info"]!: {
      ["name"]?: string | null;
      ["slug"]?: string | null;
      ["description"]?: string | null;
   };
   public ["options"]!: {
      ["status"]?: EStatus | null;
   };
   public ["__media"]!: ArticleCategoryMedia | null;

   constructor(data?: IArticleCategory) {
      super(data);
      Object.assign(this, data);
      if (this.__media) {
         this.__media = new ArticleCategoryMedia(this.__media);
      }
   }

   /**
    *
    * @param articleCategorySlug
    * @returns
    */
   public static async getArticleCategoryBySlug(articleCategorySlug: string) {
      const response = await Model.fetchGet(
         null,
         {
            slug: articleCategorySlug,
         },
         "article-category"
      );
      if (response instanceof ModelError) {
         return response;
      }

      const payload = (await response.json()) as TPayload<{
         ["articleCategory"]: IArticleCategory;
      }>;

      return {
         ["articleCategory"]: new ArticleCategory(payload.data.articleCategory),
      };
   }

   /**
    *
    * @param keywords
    * @param categories
    * @param page
    * @param limit
    * @param sort
    * @param sortBy
    * @returns
    */
   public static async paginateArticleCategory({
      keywords,
      page,
      limit,
      sort,
      sortBy,
      categories,
      categorySlugs,
   }: {
      keywords?: string;
      categories?: string;
      categorySlugs?: string;
      page?: number;
      limit?: number;
      sort?: "desc" | "asc";
      sortBy?: "createdAt" | "info.title" | "info.position";
   }) {
      try {
         const response = await Model.fetchData({
            method: "GET",
            endPoint: "article-category/paginate",
            headers: {
               page: String(page || 1),
               limit: String(limit || 20),
               sort: sort ?? "desc",
               "sort-by": sortBy ?? "createdAt",
               ...(keywords && { keywords: encodeURI(keywords) }),
               ...(categories && { categories: encodeURI(categories) }),
               ...(categorySlugs && {
                  "category-slugs": encodeURI(categorySlugs),
               }),
            },
         });

         if (response instanceof ModelError) {
            return response;
         }

         const payload = (await (response as any).json()) as TPayload<{
            ["paginator"]: TPaginate<ArticleCategory>;
         }>;

         const paginate = new Paginate(
            payload?.data?.paginator,
            ArticleCategory
         );

         return paginate;
      } catch (error) {}
   }
}

export default ArticleCategory;
