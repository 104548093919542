import { Dialog, Drawer } from "@mui/material";
import Slider from "react-slick";
import React, { ReactNode, useEffect, useRef, useState } from "react";
import { useRouter } from "next/router";
import { useGoogleLogin } from "react-google-login";

import store, { RootState } from "../../../state";
import User, { EOTPType, IUser } from "../../../models/user.model";
import { ModelError } from "../../../models/model";
import {
   getProfile,
   signin,
   toggleModalAuth,
   toggleModalWelcome,
   verify,
} from "../../../state/action/auth";
import Register from "./register/Register";
import SignupWithEmailForm from "./register/RegisterWithEmailForm";
import { EAuthType, ESigninType, EStep } from "./const";
import RegisterSuccess from "./register/RegisterSuccess";
import SignupWithPhoneForm from "./register/RegisterWithPhoneForm";
import OtpInput from "./OtpInput";
import FormNewPassword from "./register/FormNewPassword";
import SigninForm from "./signin/SigninForm";
import ForgotPasswordStep1 from "./forgot/Step1";
import ForgotPasswordStep2 from "./forgot/Step2";
import ForgotPasswordStep3 from "./forgot/Step3";
import { useSelector } from "react-redux";
import SigninStep1 from "./signin/Step1";
import ForgotPasswordStep4 from "./forgot/Step4";
import ModalRegisterRewardCoin from "../ModalRegisterRewardCoin";
import ModalReceiveCoin from "../ModalReceiveCoin";
import { isIOS, isMobile } from "react-device-detect";
import { stringify } from "querystring";
import { appDomain } from "@Models/__variables";

interface IProps {
   isRegister: boolean;
}
interface IReduxProps {
   visible: boolean;
   step: EStep;
   type: EAuthType;
   dataStep?: any | null;
   redirect: string;
   cbs?: (arg1: any, arg?: IUser) => void;
}

export const ModalAuth = () => {
   const router = useRouter();
   const {
      visible,
      step,
      type,
      dataStep,
      cbs: onAuthCBSuccess,
   } = useSelector<RootState>((state) => state.auth.modalAuth) as IReduxProps;

   const [state, setCurrState] = useState({
      dialogReceiveCoin: false,
      showModalRegisterRewardCoin: false,
   });
   function setState(newState) {
      setCurrState((prev) => ({
         ...prev,
         ...newState,
      }));
   }
   const [signinType, setSigninType] = useState(ESigninType.email);

   const [sliderData] = useState([
      {
         imgPc: "/assets/img/auth/art-board-01.webp",
         imgMb: "/assets/img/auth/art-board-01.webp",
         title: "Tích luỹ xu mua sắm",
         url: "/",
      },
      {
         imgPc: "/assets/img/auth/art-board-02.webp",
         imgMb: "/assets/img/auth/art-board-02.webp",
         title: "Trải nhiệm nhiều tính năng mới",
         url: "/quy-trinh-ket-noi?internal=home-banner-2-v2",
      },
      {
         imgPc: "/assets/img/auth/art-board-03.webp",
         imgMb: "/assets/img/auth/art-board-03.webp",
         title: "Mạng xã hội của cộng đồng yêu nhà",
         url: "/bao-gia?internal=home-banner-3-v2",
      },
   ]);

   const [previousURL, setPreviousURL] = useState("");

   const onSinginGoogleSuccess = async (res: any) => {
      if ("accessToken" in res && typeof res.accessToken === "string") {
         try {
            const signInWithGoogle = await User.signinWithGoogle(
               res?.accessToken,
               previousURL
            );
            if (signInWithGoogle instanceof ModelError) {
               throw Error(signInWithGoogle.message);
            } else {
               store.dispatch(getProfile());
               handleSuccess();
               onModalClose();
            }
         } catch (error) {}
      }
   };
   const onSinginGoogleFailure = (res: any) => {};
   const { signIn: googleSignIn } = useGoogleLogin({
      onSuccess: onSinginGoogleSuccess,
      onFailure: onSinginGoogleFailure,
      accessType: "offline",
      clientId: `${process.env.REACT_APP_GOOGLE_CLIENT_ID}.apps.googleusercontent.com`,
   });

   const FBLogin = async () => {
      try {
         if (!window.FB) {
            return;
         }
         if (isMobile && isIOS) {
            const params = {
               auth_type: "",
               client_id: "711063823782715",
               response_type: "token",
               return_scopes: false,
               scope: "email,public_profile",
               state: "facebookdirect",
            };
            const redirect_uri = appDomain;
            window.location.href = `https://www.facebook.com/dialog/oauth?${stringify(
               params
            )}&redirect_uri=${redirect_uri}`;
         } else {
            window.FB.login(
               function (res) {
                  if (!res?.authResponse?.accessToken) {
                     throw Error(
                        "Login facebook does't availabled res.authResponse.accessToken"
                     );
                  }
                  User.signinWithFaceBook(
                     res?.authResponse?.accessToken,
                     previousURL
                  ).then((signinWithFacebook) => {
                     if (signinWithFacebook instanceof ModelError) {
                        throw Error(signinWithFacebook.firstMessageErr);
                     }
                     store.dispatch(getProfile());
                     handleSuccess();
                     onModalClose();
                  });
               },
               { scope: "public_profile,email" }
            );
         }
      } catch (error) {}
   };

   const onModalClose = () => {
      store.dispatch(
         toggleModalAuth({
            visible: false,
            step: EStep.signup,
            type: EAuthType.signup,
         })
      );
      // show modal welcome when close modal success
      if (step === EStep.signupSuccess) {
         store.dispatch(toggleModalWelcome({ visible: true }));
      } else {
         store.dispatch(toggleModalWelcome({ showGreetingPost: false }));
      }
   };

   const handleSetStep = ({
      step,
      dataStep,
   }: {
      step: EStep;
      dataStep?: any | null;
   }) => {
      store.dispatch(toggleModalAuth({ step, dataStep }));
   };

   const handleSuccess = () => {
      onAuthCBSuccess?.({ visible, step, type, dataStep }, dataStep?.userInfo);
      if (router.asPath.includes("/shop")) {
         if (type === EAuthType.signup) {
            setState({
               showModalRegisterRewardCoin: true,
            });
         } else {
            setState({
               dialogReceiveCoin: true,
            });
         }
      }
   };

   const handleSigninSuccess = () => {
      handleSuccess();
      onModalClose();
   };

   const handleRegisterSuccess = () => {
      onModalClose();
      handleSuccess();
   };

   const fullHeight = ![
      EStep.signup,
      EStep.signupSuccess,
      EStep.signinStep1,
      EStep.forgotPasswordStep4,
   ].includes(step);

   const settingsSlider = {
      arrows: false,
      dots: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 4000,
   };

   const HeaderMobileTitle = {
      [EStep.signup]: "",
      [EStep.signupWithPhone]: "",
      [EStep.signupWithEmail]: "",
      [EStep.otpInput]: "OTP",
      [EStep.signupWithPhoneAndPassword]: "Mật khẩu",
      [EStep.signupSuccess]: "",
      [EStep.signinStep2]: "",
      [EStep.forgotPasswordStep1]: "",
      [EStep.forgotPasswordStep2]: "",
      [EStep.forgotPasswordStep3]: "",
   };

   const BackLinkOnMobile = {
      [EStep.signup]: EStep.signup,
      [EStep.signupWithPhone]: EStep.signup,
      [EStep.signupWithEmail]: EStep.signup,
      [EStep.otpInput]: EStep.signupWithPhone,
      [EStep.signupWithPhoneAndPassword]: EStep.otpInput,
      [EStep.signupSuccess]: EStep.signup,
      [EStep.signinStep2]: EStep.signinStep1,
      [EStep.forgotPasswordStep1]: EStep.signinStep2,
      [EStep.forgotPasswordStep2]: EStep.forgotPasswordStep1,
      [EStep.otpInputForgot]: EStep.forgotPasswordStep2,
      [EStep.forgotPasswordStep3]: EStep.forgotPasswordStep2,
   };

   return (
      <>
         <Layout
            visible={visible}
            fullHeight={fullHeight}
            HeaderMobileTitle={HeaderMobileTitle}
            BackLinkOnMobile={BackLinkOnMobile}
            step={step}
            setStep={handleSetStep}
            onClose={() => {
               onModalClose();
            }}
         >
            <div className="row gx-0">
               <div className="col-0 d-none d-md-block col-md-5_7">
                  <div className="modal-content-left">
                     <Slider {...settingsSlider}>
                        {sliderData.map((banner, i) => {
                           return (
                              <div className="slider-item" key={i}>
                                 {/* <Link title="Banner" href={banner.url ?? ""} className="cover" onClick={() => this.setPreviousURL(banner)}></Link> */}
                                 <picture>
                                    <source
                                       media="(min-width: 768px)"
                                       srcSet={banner.imgPc ?? ""}
                                    />
                                    <source
                                       media="(max-width: 767px)"
                                       srcSet={banner.imgMb ?? ""}
                                    />
                                    <img
                                       height={"100%"}
                                       width="100%"
                                       title={banner.title ?? ""}
                                       alt={banner.title ?? ""}
                                       src={banner.imgPc ?? ""}
                                    />
                                 </picture>
                              </div>
                           );
                        })}
                     </Slider>
                  </div>
               </div>
               <div className="col-12 d-md col-md-6_3">
                  <div className="right">
                     {(() => {
                        switch (step) {
                           case EStep.signup:
                              return (
                                 <Register
                                    registerPhone={() =>
                                       handleSetStep({
                                          step: EStep.signupWithPhone,
                                       })
                                    }
                                    registerEmail={() =>
                                       handleSetStep({
                                          step: EStep.signupWithEmail,
                                       })
                                    }
                                    registerFacebook={FBLogin}
                                    registerGoogle={googleSignIn}
                                    // registerApple={AppleLogin}
                                    onLogin={() => {
                                       handleSetStep({
                                          step: EStep.signinStep1,
                                       });
                                       store.dispatch(
                                          toggleModalAuth({
                                             type: EAuthType.signin,
                                          })
                                       );
                                    }}
                                 />
                              );
                           // signup with email
                           case EStep.signupWithEmail:
                              return (
                                 <SignupWithEmailForm
                                    previousURL={previousURL}
                                    setStep={handleSetStep}
                                    onLogin={() =>
                                       handleSetStep({
                                          step: EStep.signinStep2,
                                       })
                                    }
                                    onBack={() =>
                                       handleSetStep({ step: EStep.signup })
                                    }
                                 />
                              );
                           // signup with phone
                           case EStep.signupWithPhone:
                              return (
                                 <SignupWithPhoneForm
                                    form={dataStep}
                                    setStep={handleSetStep}
                                    onLogin={() =>
                                       handleSetStep({
                                          step: EStep.signinStep2,
                                       })
                                    }
                                    onBack={() =>
                                       handleSetStep({ step: EStep.signup })
                                    }
                                 />
                              );
                           case EStep.otpInput:
                              return (
                                 <OtpInput
                                    form={dataStep}
                                    authType={type}
                                    setStep={handleSetStep}
                                    onLogin={() =>
                                       handleSetStep({
                                          step: EStep.signinStep2,
                                       })
                                    }
                                    onBack={() =>
                                       handleSetStep({
                                          step: EStep.signupWithPhone,
                                       })
                                    }
                                 />
                              );

                           case EStep.signupWithPhoneAndPassword:
                              return (
                                 <FormNewPassword
                                    formValue={dataStep}
                                    setStep={handleSetStep}
                                    onLogin={() =>
                                       handleSetStep({
                                          step: EStep.signinStep2,
                                       })
                                    }
                                    onBack={() =>
                                       handleSetStep({
                                          step: EStep.signupWithPhone,
                                       })
                                    }
                                 />
                              );
                           // register success
                           case EStep.signupSuccess:
                              return (
                                 <RegisterSuccess
                                    onRegisterSuccess={handleRegisterSuccess}
                                 />
                              );
                           // signin
                           case EStep.signinStep1:
                              return (
                                 <SigninStep1
                                    loginPhone={() => {
                                       handleSetStep({
                                          step: EStep.signinStep2,
                                       });
                                       setSigninType(ESigninType.phone);
                                    }}
                                    loginEmail={() => {
                                       handleSetStep({
                                          step: EStep.signinStep2,
                                       });
                                       setSigninType(ESigninType.email);
                                    }}
                                    loginFacebook={FBLogin}
                                    loginGoogle={googleSignIn}
                                    // loginApple={AppleLogin}
                                    onRegister={() => {
                                       handleSetStep({ step: EStep.signup });
                                       store.dispatch(
                                          toggleModalAuth({
                                             step: EStep.signup,
                                             type: EAuthType.signup,
                                          })
                                       );
                                    }}
                                 />
                              );
                           case EStep.signinStep2:
                              return (
                                 <SigninForm
                                    signinType={signinType}
                                    previousURL={previousURL}
                                    setStep={handleSetStep}
                                    onForgot={() => {
                                       handleSetStep({
                                          step: EStep.forgotPasswordStep1,
                                       });
                                       store.dispatch(
                                          toggleModalAuth({
                                             step: EStep.forgotPasswordStep1,
                                             type: EAuthType.forgot,
                                          })
                                       );
                                    }}
                                    onSigninSuccess={handleSigninSuccess}
                                 />
                              );
                           // forgot
                           case EStep.forgotPasswordStep1:
                              return (
                                 <ForgotPasswordStep1 setStep={handleSetStep} />
                              );
                           case EStep.forgotPasswordStep2:
                              return (
                                 <ForgotPasswordStep2
                                    form={dataStep}
                                    setStep={handleSetStep}
                                 />
                              );
                           case EStep.otpInputForgot:
                              return (
                                 <OtpInput
                                    form={dataStep}
                                    authType={type}
                                    setStep={handleSetStep}
                                    onLogin={() =>
                                       handleSetStep({
                                          step: EStep.signinStep2,
                                       })
                                    }
                                    onBack={() =>
                                       handleSetStep({
                                          step: EStep.forgotPasswordStep2,
                                       })
                                    }
                                 />
                              );
                           case EStep.forgotPasswordStep3:
                              return (
                                 <ForgotPasswordStep3
                                    form={dataStep}
                                    setStep={handleSetStep}
                                 />
                              );
                           case EStep.forgotPasswordStep4:
                              return (
                                 <ForgotPasswordStep4 setStep={handleSetStep} />
                              );
                        }
                     })()}
                  </div>
               </div>
            </div>
         </Layout>
         {router.asPath.includes("/shop") && (
            <>
               <ModalReceiveCoin
                  visible={state.dialogReceiveCoin}
                  onClose={async () => {
                     setState({ dialogReceiveCoin: false });
                  }}
               />
               <ModalRegisterRewardCoin
                  visible={state.showModalRegisterRewardCoin}
                  onClose={() => {
                     setState({
                        showModalRegisterRewardCoin: false,
                     });
                  }}
                  onSubmit={() => {
                     setState({
                        showModalRegisterRewardCoin: false,
                        dialogReceiveCoin: true,
                     });
                  }}
               />
            </>
         )}
      </>
   );
};

function Layout({
   visible,
   fullHeight,
   HeaderMobileTitle,
   BackLinkOnMobile,
   step,
   setStep,
   onClose,
   children,
   ...props
}: {
   visible: boolean;
   fullHeight: boolean;
   HeaderMobileTitle: any;
   BackLinkOnMobile: any;
   step: EStep;
   setStep: ({ step }: { step: EStep }) => void;
   onClose: () => void;
   children: ReactNode;
}) {
   if (typeof window !== "undefined" && window?.innerWidth > 768) {
      return (
         <Dialog
            open={Boolean(visible)}
            onClose={onClose}
            className={`modal-auth`}
            {...props}
         >
            <div
               className={`img-close d-none d-md-flex`}
               style={{ cursor: "pointer" }}
               onClick={onClose}
            >
               <img src="/assets/img/icon/close.svg" alt="Space T" />
            </div>
            {children}
         </Dialog>
      );
   }
   return (
      <Drawer
         className={`modal-auth ${fullHeight ? "full-height" : ""}`}
         anchor="bottom"
         open={Boolean(visible)}
         onClose={onClose}
         {...props}
      >
         <>
            {fullHeight ? (
               <div
                  className="back-step-mobile-custom"
                  onClick={() =>
                     setStep({ step: BackLinkOnMobile[step] || EStep.signup })
                  }
               >
                  <img
                     className="img-back"
                     src="/assets/img/icon/arrow-left-icon.svg"
                     alt=""
                  />
                  {HeaderMobileTitle[step] && (
                     <div className="step-name">{HeaderMobileTitle[step]}</div>
                  )}
                  <div></div>
               </div>
            ) : (
               <div
                  className={`img-close`}
                  style={{ cursor: "pointer" }}
                  onClick={onClose}
               >
                  <img src="/assets/img/icon/close.svg" alt="Space T" />
               </div>
            )}
         </>
         {children}
      </Drawer>
   );
}
