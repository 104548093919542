/* eslint-plugin-disable react */
/*eslint-disable */
/**
 * Variable(s)
 */
import { apiDomain, tokenKey } from "./__variables";

/**
 * Core(s)
 */
import { ModelError, TModelError, TPayload } from "./model";
import Media from "./modelMedia.model";
import { QnA } from "./qna.model";

export enum EStatus {
   enable = "enable",
   disable = "disable",
   delete = "delete",
}

export enum EStorageProvider {
   Amazon = "amazon",
   Microsoft = "microsoft",
   Others = "others",
}

export interface IQnAImage extends Media {
   // ["info"]?: {
   //     ["container"]?: string | null;
   //     ["domain"]?: string | null;
   //     ["originalName"]?: string | null;
   //     ["protocol"]?: string | null;
   //     ["size"]?: number | null;
   //     ["extension"]?: string | null;
   //     ["fileType"]?: string | null;
   // };
   // ["options"]?: {
   //     ["status"]?: EStatus;
   //     ["storageProvider"]?: EStorageProvider;
   // };
   ["qnaId"]?: string | null;
   ["userId"]?: string | null;

   ["__qna"]?: QnA | null;
   // ["$url"]?: any | null;
}

export class QnAImage extends Media<IQnAImage> implements IQnAImage {
   // public ["info"]!: {
   //     ["container"]?: string | null;
   //     ["domain"]?: string | null;
   //     ["originalName"]?: string | null;
   //     ["protocol"]?: string | null;
   //     ["size"]?: number | null;
   //     ["extension"]?: string | null;
   //     ["fileType"]?: string | null;
   // };
   // public ["options"]!: {
   //     ["status"]?: EStatus;
   //     ["storageProvider"]?: EStorageProvider;
   // };
   public ["qnaId"]!: string | null;
   public ["userId"]!: string | null;
   public ["__qna"]?: QnA | null;
   // public ["$url"]?: any | null;

   constructor(data?: IQnAImage) {
      super(data);

      Object.assign(this, data);
      // if (this.__qna) {
      //     this.__qna = new QnA(this.__qna);
      // }
      // this.$url = `${this?.info?.protocol}://${this?.info?.domain}/${this?.info?.container}/${this._id}${this?.info?.extension}`;
   }

   public static async createImage(file: File) {
      try {
         const token = localStorage.getItem(tokenKey);

         if (!token) {
            return new ModelError({
               ["httpCode"]: 401,
               ["message"]: "You don't have permission to access this API.",
               ["errors"]: {
                  ["process"]: [
                     {
                        ["code"]: "process.error.401",
                        ["message"]: "Đăng xuất không thành công.",
                     },
                  ],
               },
            });
         }

         const formData = new FormData();
         formData.append("file", file, file.name);

         const response = await fetch(`${apiDomain}/www/qna/image`, {
            ["method"]: "POST",
            ["headers"]: {
               ["X-Requested-With"]: "XMLHttpRequest",
               ["Authorization"]: `Bearer ${token}`,
            },
            ["body"]: formData,
         });

         if (!response.ok) {
            return new ModelError((await response.json()) as TModelError);
         }

         const payload = (await response.json()) as TPayload<{
            ["newQnAImage"]: IQnAImage;
         }>;

         return new QnAImage(payload.data.newQnAImage);
      } catch (error: any) {}
   }

   /**
    * Get full image URL
    */
   // get $url() {
   //     return `${this?.info?.protocol}://${this?.info?.domain}/${this?.info?.container}/${this._id}${this?.info?.extension}`;
   // }
}

export default QnAImage;
